import React from 'react';
import './components.css';
import xPalBusinessLogo from '../assets/xPalBusinessLogo.png'
import logoFooter from '../assets/logoFooter.svg'
import patentIcon from '../assets/patentIcon.svg'
import Facebook from '../assets/Facebook.svg';
import Instagram from '../assets/Instagram.svg';
import Youtube from '../assets/Youtube.svg';
import Twitter from '../assets/Twitter.svg';
import appstore from '../assets/appstore.svg';
import playstore from '../assets/playstore.svg';
import mobileappStore from '../assets/mobileappStore.png';
import patentv2 from '../assets/patentv2.png';

import xPalBusinessWhiteLogo from '../assets/xPal-Biz-logo-white.png';
function Footer(){

    return(
        <>
        <section className='footerSec businessFooter'>
            <footer className='container'>
                <div className='row'>
                    <div className='col-6'>
                        <a href='/'>
                            <img src="https://dz5tyoxl8h44g.cloudfront.net/xbiz-logo-white.png" className='footerLogo' alt='footer logo'></img>
                        </a>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='partText col-md-5 col-6 mb-2 mt-5'>
                        <h3>COMPANY</h3>
                        <div className='flex-md-row spaceBetween'>
                            <ul className='mr-3'>
                                <li><a href='https://xpal.com/Privacy?p=termsconditions' target="_blank" rel="noreferrer">Terms and Conditions</a></li>
                                <li><a href='https://xpal.com/Privacy?p=privacy' target="_blank" rel="noreferrer">Privacy Policy</a></li>
                                <li><a href='https://xpal.com/mission-statement' target="_blank" rel="noreferrer">Mission Statement and Purpose</a></li>
                                <li><a href='https://xpal.com/Privacy?p=eula' target='_blank' rel="noreferrer">End User Lisence Agreement</a></li>
                                <li><a href='https://xpal.com/Privacy?p=eula' target='_blank' rel="noreferrer">Download APK</a></li>
                            </ul>
                            <ul className='mr-3'>
                            <li><a href='https://xpal.com/about'>About Us</a></li>
                                <li><a href='https://xpal.com/press-release' target='_blank' rel="noreferrer">Press Release</a></li>
                                <li><a href='https://xpal.com/contact'>Contact Us</a></li>
                            </ul>


                        </div>
                    </div>

                    <div className='partPatent col-md-7 col-6'>
                        <img className='patentIcon' src="https://dz5tyoxl8h44g.cloudfront.net/patentv2.png" alt='patent icon'>
                        </img>
                    </div>
                </div>
                <div className="row d-md-none">
                   <div className="col my-4">
                      <img className="appstore-icon" src={mobileappStore} alt="appstore"/>
                  </div>
                </div>
                <div className='footerIconsLinks row mb-5 mt-4'>
                    <div className='footerIcons col-md-6 col-12'>
                        <a href='https://www.facebook.com/xPalMessenger'>
                            <img src="https://dz5tyoxl8h44g.cloudfront.net/Facebook.svg" alt='facebook'></img>
                        </a>
                        <a href='https://www.instagram.com/xpalmessenger/'>
                            <img src="https://dz5tyoxl8h44g.cloudfront.net/Instagram.svg" alt='instagram'></img>
                        </a>

                        <a href='https://twitter.com/xpal'>
                            <img src="https://dz5tyoxl8h44g.cloudfront.net/Twitter.svg" alt='twitter'></img>
                        </a>
                    </div>
                    <div className='footerLinks col-md-6 col-sm-12'>
                        <a href='https://apps.apple.com/app/xpal/id1536908440' target="_blank" rel="noreferrer">
                                <img src="https://dz5tyoxl8h44g.cloudfront.net/appstore-icon.svg" alt='appstore'></img>
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.mess.engerx' target='_blank' rel="noreferrer">
                                <img src="https://dz5tyoxl8h44g.cloudfront.net/playstore-icon.svg" alt='playstore'></img>
                            </a>
                    </div>
                </div>
                <div className='row'>
                    <div className='footerText col-md-6 col-6'>
                        <p>© 2024 xPal.com, Inc.</p>
                    </div>
                </div>
                </footer>
        </section>
        </>
    )
}

export default Footer;
