import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import { stripePayment, bitpayPayment } from '../services/user.service';
import crypto from 'crypto';
import cryptoIcon from '../assets/cryptoIcon.png'
import Header from './Header';
import Footer from './Footer';

import './components.css';
import './landingpage.css';
import './purchaseV2.css';
import './checkout.css';

import {coinbaseConfig, stripeConfig,coinbasePurchaseType} from "../services/constants.service";
import { apiXpal} from "../services/constants.service";
import { cbdemo} from "../services/user.service";
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import {CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, CardElement,} from "@stripe/react-stripe-js"; 
import PaymentForm from './PaymentForm';

function Purchase2({value, recursionValue, sequenceValue, amount, costPerUser, onPaymentStatusChange, setBitpayInvoice, setBitpayUrl }) {
    const selectedSequenceLink = localStorage.getItem('selectedSequenceLink');
    const selectedRecursionLink = localStorage.getItem('selectedRecursionLink');
    const updated_xID_count = localStorage.getItem('value');
    const total_Amount = localStorage.getItem('totalAmount');
    const costPerxID = localStorage.getItem('costPerUser');
    const stripeObject = loadStripe(apiXpal.stripeKey);
    const stripePromise = loadStripe(apiXpal.stripeKey);

    const xID = sessionStorage.getItem('xID');
    const addSpacesToXID = (xid) => {
      return xid.replace(/\d{3}(?=\d)/g, '$& ');
    }

    const [checkoutId, setCheckoutId] = useState('');
    const [perMonth, setPerMonth]=useState(true);
    const [redirecting, setIsRedirecting]=useState(false);
    const [showWaitMessage, setShowWaitMessage]=useState(false);
    const [ProdScheme, setProdScheme]=useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [stripeProductID, setStripeProductId]=useState("");
    const [productID, setProductID]=useState("");
    const [SchemeType, setSchemeType]=useState("xIDListSubscription:purchased");
    const [hideStripeBtn, setHideStripeBtn]=useState(false);
    const [perxIDCost, setPerXidCost]=useState('');

    const [paymentStatus, setPaymentStatus] = useState("");
    const [bitPayLoader, setBitPayLoader] = useState(false);
    const [bitPayInvoiceId, setBitPayInvoiceId] = useState("");

    const handlePaymentStatusChange = (status) => {
      setPaymentStatus(status);
      onPaymentStatusChange(status); // Pass the status up to the parent (Checkout)
    };
  
    const MonthlyProductId= coinbaseConfig.MonthlyProductId;
    const YearlyProductId= coinbaseConfig.YearlyProductId;
    const MonthlyStripe = stripeConfig.Monthly;
    const YearlyStripe = stripeConfig.Yearly;
    useEffect(() => {
        
        if(selectedRecursionLink==='Yearly'){
            setCheckoutId(coinbaseConfig.yearlyCheckoutId);
            setPerMonth(false);


        }
        else if(selectedRecursionLink==='Monthly'){
            setCheckoutId(coinbaseConfig.monthlyCheckoutId);
            setPerMonth(true);
        } 
        
    }, []);

    useEffect(() => {
        
        if(selectedRecursionLink==='Yearly'){
            const ProdScheme=`xpal_prod_${YearlyStripe}`;
            const hashedProdScheme = crypto.createHash('sha256').update(ProdScheme).digest('hex');
            setProdScheme(hashedProdScheme);
            setStripeProductId(stripeConfig.Yearly);
            setProductID(coinbaseConfig.yearlyCheckoutId);
            
        }
        else if(selectedRecursionLink==='Monthly'){
          const ProdScheme=`xpal_prod_${MonthlyStripe}`;
          const hashedProdScheme = crypto.createHash('sha256').update(ProdScheme).digest('hex');
          setProdScheme(hashedProdScheme);
          setStripeProductId(stripeConfig.Monthly);
          setProductID(coinbaseConfig.monthlyCheckoutId);
        }
        else{
          const ProdScheme=`xpal_prod_${YearlyProductId}`;
          const hashedProdScheme = crypto.createHash('sha256').update(ProdScheme).digest('hex');
          setProdScheme(hashedProdScheme);
          setProductID(coinbaseConfig.yearlyCheckoutId);
        } 
        
    }, []);

    const navigate= useNavigate();
    const goToHome = () => {
        navigate('/');
      };


      const handleTempCB = () => {
        setIsRedirecting(true);
        let userObj = {
            xIDSequence: selectedSequenceLink,
            xIDCount: updated_xID_count,
            ProductId: productID
        };

        // Call the update API
        cbdemo(userObj)
            .then((res) => {
                if (res.data.status === 'ok') {
                    navigate('/dashboard');
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    //stripe
    const payMoney = async () => {     
          setHideStripeBtn(true); 
          setShowWaitMessage(true);
          let userObj = {
            Sequence: selectedSequenceLink,
            Count: updated_xID_count,
            xKey: stripeProductID,
            Scheme: ProdScheme,
            /* SchemeType: SchemeType, */
  
        };
            //console.log(selectedSequenceLink, updated_xID_count, productID, ProdScheme)
        stripePayment(userObj)
            .then((res) => {
              if (res) {
                if (res.data.status === 'ok') {
                  setClientSecret(res.data.data);
                }
                else{
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }

    const appearance = {
        theme: 'stripe',
      };

    const options = {
        clientSecret,
        appearance
       
      }; 


  const bitpay = async()=>{
    setBitPayLoader(true);
    let userObj = {
      Sequence: selectedSequenceLink,
      Count: updated_xID_count,
      xKey: stripeProductID,
      Scheme: ProdScheme,
      /* SchemeType: SchemeType, */

  };
  bitpayPayment(userObj)
    .then((res) => {
      if (res) {
        if (res.data.status === 'ok') {
          
          //setClientSecret(res.data.data);
          if(res.data.data.url){
            setBitPayLoader(false);
            window.open(res.data.data.url, '_blank');
          }
         const invoiceId= res.data.data.invoiceId;
         localStorage.setItem('invoiceId', invoiceId);
         setBitPayInvoiceId(invoiceId);
         setBitpayInvoice(invoiceId);
         setBitpayUrl(res.data.data.url);
        }
        else{
          setBitPayLoader(false);
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });

  }    

  return (
    <>
    
     <Header/>
     <div className='d-flex justify-content-between align-items-center container purchaseContainerDiv'>
        <div className='col-md-6 ltr-animation purchaseBox '>
            <div className="grid-container mt-5">
           
                <p className='grid-item xtralarge'>Checkout</p>
                <p className='grid-item'><span style={{visibility:'hidden'}}>Checkout</span></p>
                <p className='grid-item'>Master xID:</p>
                <p className='grid-item large'>{addSpacesToXID(xID)}</p>
                <p className='grid-item'>Total Number of xIDs:</p>
                <p className='grid-item large'>{updated_xID_count}</p>
                <p className='grid-item'>Cost per user: </p>
                <p className='grid-item large'>{costPerxID}</p>
                <p className='grid-item'>xID Format:</p>
                <p className='grid-item large'>{selectedSequenceLink}</p>
                <p className='grid-item'>Payment Schedule:</p>
                <p className='grid-item large'>{selectedRecursionLink}</p>
                
            </div>
           <div className='totalAmountContainer d-flex justify-content-between align-items-center mt-3 mb-4'>
            <p>Total: </p>
            <p className='large'>${total_Amount}</p>
            
            {perMonth ? (
                <p className='per'>PER MONTH</p>
            ):
            (
                <p className='per'>PER YEAR</p>
            )}
            </div> 
        </div>
        <div className='col-md-6 d-flex justify-content-center align-items-center flex-column borderSolid'>
        {!clientSecret &&(
            <div className='d-flex justify-content-start' style={{width: '100%', paddingLeft: '27px'}}>
            <img src="https://dz5tyoxl8h44g.cloudfront.net/biz-visa.png" className='mt-5 mb-4 mr-1 cardsImg'/>
            <img src="https://dz5tyoxl8h44g.cloudfront.net/biz-mcd.png" className='mt-5 mb-4 cardsImg'/>
            </div>
        )}

{/*             {hideStripeBtn && (
              <div className="form-loader-container">
                        <span class="formloader"></span>
               </div>
            )} */}
            {!clientSecret &&(
            <div className='pay' onClick={() => payMoney()}>Buy with Card {showWaitMessage && (<span class="stripeBtnLoader" id="stripeBtnLoader"></span>)}</div>  
            
            )}

{/*                 {clientSecret && ( <Elements stripe={stripeObject}>
                <PaymentForm textColor="black" value={value} recursionValue={recursionValue} sequenceValue={sequenceValue} amount={amount} costPerUser={costPerUser}/>
                </Elements>  */}

                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                    <PaymentForm onPaymentStatusChange={onPaymentStatusChange}/>
                    </Elements>
                )} 
            
            {(() => {            
                const metadata = {
                /* "Name": null,
                "Email": null, */
                "number": xID,
                "xIDCount": value || 10,
                "recursion": recursionValue || 'Yearly',
                "xIDSequence": sequenceValue || 'Random',
                "purchaseType":coinbasePurchaseType.xIDListSubscription, 
                /* "checkoutId":{checkoutId} || coinbaseConfig.monthlyCheckoutId,  */
                "productId": checkoutId || coinbaseConfig.monthlyCheckoutId,

            }; 

            let stringifyMetaData = JSON.stringify(metadata);
          //  console.log("metadata",stringifyMetaData);


            return (
                <>
                
                    <div className='topBorder'>
                    {/* <span className="separator">or</span> */}

    {/*                 <CoinbaseCommerceButton 
                    checkoutId={checkoutId || coinbaseConfig.monthlyCheckoutId} 
                    customMetadata={stringifyMetaData}  

                    wrapperStyle={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop:'40px'
                        }}
                        style={{
                            pointerEvents: 'auto',
                            fontSize: '20px',
                            fontWeight: '600',
                            padding: '13px 30px',
                            borderRadius: '30px',
                            backgroundColor: '#F4F6FE',
                            color: '#263274',
                            border: '2px solid #263274',
                            outline:'0',
                            marginBottom:'3rem',
                            }}>
                                                                    
                    <div className='mainCryptoBtn' id='crypoBtn'><img src={cryptoIcon} style={{width:'16px'}} className='mr-2'/>Buy With Crypto
                    </div>
                    </CoinbaseCommerceButton>  */}

                    <div onClick={() => handleTempCB()} className='buyWithCrypto'><img src={cryptoIcon} style={{width:'16px'}} className='mr-2'/> Buy with Crypto </div>
                    {redirecting && (<span class="stripeBtnLoader" id="stripeBtnLoader" style={{right: '30%', bottom: '44%', transform: 'translateY(-2px)'}}></span>)}
                    </div>

                    <div className='pay' onClick={() => bitpay()}>Pay with BitPay {bitPayLoader && (<span class="stripeBtnLoader" id="stripeBtnLoader"></span>)}</div> 
                     
                <div className='cancel' onClick={() => goToHome()}>Cancel</div>
                </>
            );
        })()}
            
        </div>
        
     </div>
    </>
  )
}

export default Purchase2
