import React,{useState,useRef, useEffect} from 'react';
import { bitpayStatus } from '../services/user.service';
import './components.css';
import './landingpage.css';
import './purchaseV2.css';
import './checkout.css';
function Confirmation({bitPayInvoiceId, bitpayUrl}) {
    const [pageLoader, setPageLoader]=useState(true);
    const [paid, setPaid] = useState(false);
    const [newStatus, setNewStatus] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [complete, setComplete] = useState(false);
    const [expired, setExpired] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [paidPartial, setPaidPartial] = useState(false);
    const [paidOver, setPaidOver] = useState(false);
    const [unsuccessful, setUnsuccessful] = useState(false);

    useEffect(() => {
        const invoiceId = localStorage.getItem('invoiceId');
     
            bitpayStatus(invoiceId).then(res => {
                if (res.data.status === 'ok') {
                    setPageLoader(false);
                    if(res.data.data.status==='paid'){
                        setPaid(true)
                    }
                    else if (res.data.data.status==='new'){
                        setNewStatus(true);
                    }
                    else if (res.data.data.status==='confirmed'){
                        setConfirmed(true);
                    }
                    else if (res.data.data.status==='complete'){
                        setComplete(true);
                    }
                    else if (res.data.data.status==='expired'){
                        setExpired(true);
                    }
                    else if (res.data.data.status==='invalid'){
                        setInvalid(true);
                    }
                    else if (res.data.data.status==='paidPartial'){
                        setPaidPartial(true);
                    }
                    else if (res.data.data.status==='paidOver'){
                        setPaidOver(true);
                    }
                    else{
                        setUnsuccessful(true)
                    }
                }
            }).catch(error => {
                setPageLoader(false);
                console.error('Error fetching data:', error);
                setUnsuccessful(true)
            });
        


    }, []);

    const goToBitpay = () => {
        
        window.location.href = bitpayUrl;
      };
    return (
        <>
                <div className='status-wrapper'>
                <div className="xpal-invoice h-100 d-flex align-items-center justify-content-center bitpay-confirmation">

                    <div className="container col-md-5">
                        <div className="d-flex justify-content-center align-items-center success-wrapper">

                        {pageLoader && (<span class="stripeBtnLoader bitpayLoader" id="stripeBtnLoader"></span>)}

                        {paid  && (<div className="status-div">
                                <h3>Payment Successful!</h3>
                                <p>Your payment has been successfully done. </p>
                                <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Continue</a>
                                    </div>
                                </div>
                                 </div>
                        )}


                        {newStatus && (<div className="status-div">
                                <h3>Invoice Created!</h3>
                                <p>Your Invoice has been created. Please complete the payment within the next 15 minutes.</p>
                                <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Continue</a>
                                    </div>
                                </div>
                            </div>
                        )}


                        {confirmed && (
                            <div className="status-div">
                                <h3>Your payment is confirmed!</h3>
                                <p>Your payment has been confirmed but is not yet complete.</p>
                                <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Back</a>
                                    </div>
                                </div>
                            </div>
                        )}

                        {complete && (
                            <div className="status-div">
                                <h3>Payment Complete!</h3>
                                <p>Your payment has been completed successfully.</p>
                                <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Continue</a>
                                    </div>
                                </div>
                            </div>
                        )}    

                        {expired && (
                            <div className="status-div">
                                <h3>Payment Expired</h3>
                                <p>Your payment has expired. Please try again.</p>
                                <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Back</a>
                                    </div>
                                </div>
                            </div>
                        )}


                        {invalid && (
                            <div className="status-div">
                            <h3>Payment Invalid</h3>
                            <p>Your payment was not confirmed within the required time. Please try again.</p>
                            <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Back</a>
                                    </div>
                                </div>
                            </div>
                        )}

                        {paidPartial &&(
                            <div className="status-div">
                            <h3>Payment Partially Paid</h3>
                            <p>Your payment was partially paid and automatically refunded. Please try again.</p>
                            <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Back</a>
                                    </div>
                                </div>
                            </div>
                        )}

                        {paidOver && (
                            <div className="status-div">
                            <h3>Payment Overpaid</h3>
                            <p>Your payment was overpaid and automatically refunded. Please try again.</p>
                            <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Back</a>
                                    </div>
                                </div>
                            </div>
                        )}
 
                        {unsuccessful &&(
                            <div className="status-div">
                            <h3>Payment Unsuccessful</h3>
                            <p>Your payment was not successful, please try again.</p>
                            <div class="d-flex justify-content-center align-items-center mt-2 buttons-div">
                                    <div class="d-flex align-items-center">
                                        <a id="backBtn" class="continueBtn" href='/purchase'>Back</a>
                                    </div>
                                </div>
                            </div>
                        )}


                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}

export default Confirmation