import React, { useState, useEffect } from 'react';
import './components.css';
import { Button, Modal } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import ManageXIDs from './ManageXIDs';
import Assign from '../../assets/Assign.png';
import Edit from '../../assets/Edit.png';

import {createLink } from "../../services/user.service";

interface Props {
  APIData: any[];
  handleEditCallBack: (refxID:string, linkStatus:string, pinStatus:string, linkExpired:string, nlinkExpired:string) => void;
  page: number;
  size: number;
  setPage: (page: number) => void;
}

const XIDsTable: React.FC<Props> = ({ APIData, handleEditCallBack, page, size, setPage }) => {

/*   const selectedRecursionLink = localStorage.getItem('selectedRecursionLink');
  console.log('selected', selectedRecursionLink); */

  const [showModal, setShowModal] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [clickedXid, setClickedXid] = useState('');
  const [clickedUserName, setClickedUserName] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(size - 1);
  const [searchQuery, setSearchQuery] = useState('');
  const [clearSearch, setClearSearch]=useState(false);

  useEffect(() => {
    setStartIndex((page - 1) * size);
    setEndIndex(Math.min(page * size - 1, APIData.length - 1));
  }, [page, size, APIData]);

  const goToPage = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(APIData.length / size)) {
      setPage(newPage);
    }
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(APIData.length / size);
    const pageNumbers: JSX.Element[] = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Button key={i} onClick={() => goToPage(i)} className={`mr-2 ${i === page ? 'active' : ''}`} disabled={i === page}>
          {i}
        </Button>
      );
    }
    return pageNumbers;
  };

  const copyInputValue = () => {
    navigator.clipboard.writeText(inviteLink);
  };

  const handleShareButtonClick = (xid, link, pset ,refXidAdded, linkCreated) => {
    const refxID = xid;
    const linkStatus = link ? 'true':'false';
    const pinStatus = pset ? 'true' : 'false';
    const linkExpired = isExpired(refXidAdded) ? 'true' : 'false';
    let nlinkExpired;
    if (newLinkIsExpired(linkCreated) === true) {
        nlinkExpired = 'true';  
    } else if (newLinkIsExpired(linkCreated) === false) {
        nlinkExpired = 'false'; 
    } /* else {
        nlinkExpired = null;    
    } */

        
    if(link){
      setShowModal(true);
      setInviteLink(link);
    }
    else{
      handleEditCallBack(refxID,linkStatus, pinStatus, linkExpired, nlinkExpired);
    }

  };

  const handleEditButtonClick = (xid, link, userName, pset, refXidAdded, linkCreated) => {
    setClickedXid(xid);
    localStorage.setItem('clickedXid', xid);
    const refxID = xid;
    setClickedUserName(userName);
    localStorage.setItem('clickedUserName', userName);
    const linkStatus = link ? 'true':'false';
    const pinStatus = pset ? 'true' : 'false';
    const linkExpired = isExpired(refXidAdded) ? 'true' : 'false';

    let nlinkExpired;
    if (newLinkIsExpired(linkCreated) === true) {
        nlinkExpired = 'true';  
    } else if (newLinkIsExpired(linkCreated) === false) {
        nlinkExpired = 'false'; 
    } /* else {
        nlinkExpired = null;    
    } */
    
    handleEditCallBack(refxID,linkStatus, pinStatus, linkExpired, nlinkExpired);
    console.log("link status for"+xid+" on xids page is"+linkStatus);

    if(!pset){
      //console.log('link is null for'+ xid);
    }
    else{
      //console.log('link is not null for'+ xid);
    }
  };

  const addSpacesToXID = (xid: any): string => xid.replace(/\d{3}(?=\d)/g, '$& ');

  const hideModal = () => setShowModal(false);

  // Function to handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setClearSearch(false);
/*     if(searchQuery.length<=1){
      setClearSearch(false);
    }*/
    if(searchQuery.length>=1){
      setClearSearch(true);
    } 
    else if (searchQuery==='') {
      setClearSearch(false);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setClearSearch(false);
  }

  const generateLink=()=>{
    const clickedXid = localStorage.getItem('clickedXid');
    let userObj = {
        xID: clickedXid,
    };

    createLink(userObj)
        .then((res) => {
            if (res && res.data.status === 'ok') {
            } else {
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

  // Function to filter APIData based on the search query
  const filteredData = APIData.filter(item => {
    const query = searchQuery.toLowerCase();

    // Safely handle null or undefined values and ensure they are strings
    const xid = item.xid ? item.xid.toLowerCase() : '';
    const userName = item.userName ? item.userName.toLowerCase() : '';
    const status = item.isActive ? 'Active' : 'Inactive';
    const registered = item.isRegister ? 'Assigned' : 'Unassigned';

    return (
      xid.includes(query) ||
      userName.includes(query) ||
      status.toLowerCase().includes(query) ||
      registered.toLowerCase().includes(query)
    );
  });

  // Function to determine if the XID has expired
  const isExpired = (refXidAdded: string): boolean => {
    const refXidDate = new Date(refXidAdded);
    const now = new Date();
    const expireDate = new Date(refXidDate.getTime() + 715 * 60 * 1000);
    //const expireDate = new Date(refXidDate.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours
    return now > expireDate;
};

const newLinkIsExpired = (linkCreated: string): boolean => {
  
  // Check if linkCreated is null or undefined
  if (!linkCreated) {
    return true; 
  }
  else{
    const linkCreatedDate = new Date(linkCreated);
    const now = new Date();
    const newExpireDate = new Date(linkCreatedDate.getTime() + 715 * 60 * 1000); 
  
    return now > newExpireDate; 
  }

  
};

  return (
    <>
      <div className='tableContainer' style={{ maxWidth: 'max-content', position: 'relative' }}>
        {/* Search Bar */}
        <div className='search-bar mb-3'>
        {searchQuery === '' && (<div className='searchIconContainer'>Search <i className="fa fa-search" aria-hidden="true"></i></div>)}

          <input
            type='text'
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ width: '100%' }}
          />
          
          {clearSearch && (
            <i className="fa fa-times" aria-hidden="true" onClick={() => handleClearSearch()}></i>
          )}
          


        </div>

        <div className='tableContainer' style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto', maxWidth: 'max-content' }}>
          <table>
            <thead>
              <tr>
                <th className='tableHead firstColH'>xIDs</th>
                <th className='tableHead secColH'>Username</th>
                <th className='tableHead fifthColH'>Status</th>
                <th className='tableHead fifthColH'>Registered</th>
                <th className='tableHead'>Action</th>

                <th className='tableHead lastColH'>Subscription End Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.slice(startIndex, endIndex + 1).map((item, index) => {
                 const linkExpired = isExpired(item.refXidAdded); 
                 const newLinkExpired =newLinkIsExpired(item.linkCreated);

                 const linkStyle: React.CSSProperties = {
                 opacity: (linkExpired && (newLinkExpired === true || newLinkExpired === null)) ? 0.5 : (linkExpired && newLinkExpired === false) ? 1 : 1,
                 pointerEvents: (linkExpired && (newLinkExpired === true || newLinkExpired === null))? 'none': (linkExpired && newLinkExpired === false) ? 'auto' : 'auto'
                };
                return (
                  <tr key={startIndex + index}>
                    <td className='tableRow'>{addSpacesToXID(item.xid)}</td>
                    <td className='tableRow'>{item.userName}</td>
                    <td className='tableRow'>{item.isActive ? 'Active' : 'Inactive'}</td>
                    <td className='tableRow'>
                    {
                      (linkExpired && (newLinkExpired === true || newLinkExpired === null)) ? (
                        <span style={{ color: '#e9647b' }}>Link Expired</span>
                      ) : (
                        item.isRegister ? 'Assigned' : 'Unassigned'
                      )
                    }
                  </td>
                    <td className='tableRow d-flex align-items-center'>
                    <span  onClick={() => {item.pset ? handleShareButtonClick(item.xid, item.link, item.pset, item.refXidAdded, newLinkExpired) : handleEditButtonClick(item.xid, item.link, item.userName, item.pset, item.refXidAdded, newLinkExpired) }}  className='d-flex align-items-center mr-2' style={linkStyle} /* style={{opacity: linkExpired? '0.5':'1', pointerEvents: linkExpired? 'none':'auto'}} */>
                    <img src="https://dz5tyoxl8h44g.cloudfront.net/biz-assign.png" style={{ width: '18px', height: '18px' }} className='mr-1' alt='assign' />Assign
                    </span>
                    <span onClick={() => handleEditButtonClick(item.xid, item.link, item.userName, item.pset, item.refXidAdded, newLinkExpired)} className='d-flex align-items-center edit'>
                      <img src="https://dz5tyoxl8h44g.cloudfront.net/biz-edit.png" style={{ width: '18px' }} className='mr-1' alt='edit' />Edit
                    </span>

                     {/*  {showBlinkingDot && <div className="blinkingDot"></div>} */}
                    </td>

                    <td className='tableRow'>{item.expiryDate.split('T')[0]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className='pagination-controls d-flex justify-content-center mt-4' style={{ paddingRight: '12px', position: 'absolute', width: '100%' }}>
          <Button onClick={() => goToPage(page - 1)} disabled={page === 1} className='mr-2'>
            {'<'}
          </Button>
          {renderPageNumbers()}
          <Button onClick={() => goToPage(page + 1)} disabled={page === Math.ceil(filteredData.length / size)}>
            {'>'}
          </Button>
        </div>
      </div>

      <Modal show={showModal} onHide={hideModal} className='manmagexids_modal'>
      <Modal.Header>
                    <div className='d-flex align-items-center justify-content-between header'>
                            <p className='closebtn' onClick={()=> setShowModal(false)} style={{visibility:'hidden'}}><i className="fa-solid fa-x"></i></p>
                            <p className='ps'>Share Link</p>
                            <p className='closebtn' onClick={()=> setShowModal(false)}><i className="fa-solid fa-x"></i></p>
                            {/* <p className='save' onClick={()=> setShowConfirmPasswordModal(false)}>Save</p> */}
                    </div>
                    
      </Modal.Header>
        <Modal.Body>
          <p className='inviteTxt mb-2'>Copy the link and share it</p>
          <div className='d-flex justify-content-between align-items-center inviteLink'>
            <div style={{ width: '100%' }}>
              <input className="inviteLinkInput" type="text" value={inviteLink} readOnly />
            </div>
            <div>
              <p className='inviteLinkText'><i className="fa-regular fa-copy" onClick={copyInputValue}></i></p>
            </div>
          </div>
          {/* <p className="mt-4 inviteTxt d-flex justify-content-center align-items-center">The link will expire in 24 hours.</p> */}
          <div className='d-flex justify-content-center align-items-center'>
{/*           <div style={{color: 'white',backgroundColor: '#0489fe',padding: '10px 25px',borderRadius: '8px',cursor: 'pointer'}} onClick={() => generateLink()} >Generate Link</div> */}
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default XIDsTable;
