import firebase from 'firebase/compat/app'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { removeSecureState } from "./securels.service";
const swal = withReactContent(Swal);

const notify = function (title: any, text: any, icon: any, footer: any) {
  swal
    .fire({
      icon: "success",
      title: "Success",
      text: "Successfully performed!",
      // footer: footer
      // title: "Hello World",
      // footer: 'Copyright 2018',
      didOpen: () => {
        // `MySwal` is a subclass of `Swal`
        //   with all the same instance & static methods
        swal.clickConfirm();
      },
    })
    .then(() => {
      swal.fire({
        icon: icon,
        title: title,
        text: text,
        footer: footer,
      });
    });
};

const claimNotify = function (title: any, html: any, icon: any, footer: any) {
  swal
    .fire({
      icon: "success",
      title: "Success",
      text: "Successfully performed!",
      // footer: footer
      // title: "Hello World",
      // footer: 'Copyright 2018',
      didOpen: () => {
        // `MySwal` is a subclass of `Swal`
        //   with all the same instance & static methods
        swal.clickConfirm();
      },
    })
    .then(() => {
      removeSecureState("isAnonymoususer");
      swal.fire({
        icon: icon,
        title: title,
        html: html,
        footer: footer,
      });
    });
};

const notifyTrigger = function (
  title: any,
  text: any,
  icon: any,
  footer: any,
  trigger: any
) {
  swal
    .fire({
      icon: "success",
      title: "Success",
      text: "Successfully performed!",
      // footer: footer
      // title: "Hello World",
      // footer: 'Copyright 2018',
      didOpen: () => {
        // `MySwal` is a subclass of `Swal`
        //   with all the same instance & static methods
        swal.clickConfirm();
      },
    })
    .then(() => {
      swal
        .fire({
          icon: icon,
          title: title,
          text: text,
          footer: footer,
        })
        .then((result) => {
          trigger();
        });
    });
};

const notificationPermission = function () {
  return new Promise((resolve, reject) => {
    let granted;
    if (Notification.permission === "granted") {
      granted = true;
      try {
        getDeviceToken().then((token) => {
          resolve(token);
        });
      } catch {
        reject("");
      }
    } else if (Notification.permission === "default") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification

        if (Notification.permission === "granted") {
          granted = true;

          try {
            getDeviceToken().then((token) => {
              resolve(token);
            });
          } catch {
            reject("");
          }
        }
      });
    } else {
      resolve("");
    }
  });
};

const webNotifications = function (title, body) {
  // create and show the notification
  let isVisible;
  let hidden;
  let visibilityChange;
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  }
  // else if (typeof document.msHidden !== "undefined") {
  //   hidden = "msHidden";
  //   visibilityChange = "msvisibilitychange";
  // } else if (typeof document.webkitHidden !== "undefined") {
  //   hidden = "webkitHidden";
  //   visibilityChange = "webkitvisibilitychange";
  // }
  // create and show the notification
  const showNotification = () => {
    // create a new notification
    if (document[hidden]) {
      const notification = new Notification(title, {
        body: body,
        icon: "https://devxpal.s3.amazonaws.com/media/logo-black.png",
      });
      // close the notification after 10 seconds
      setTimeout(() => {
        notification.close();
      }, 10 * 1000);
      // navigate to a URL when clicked
      notification.addEventListener("click", () => {
        window.focus();
        //     window.open("https://web.xpal.com", "_blank");
      });
    } else {
    }
  };

  // show an error message
  const showError = () => {
    console.log("You blocked the notifications");
  };

  // check notification permission
  let granted = false;

  if (Notification.permission === "granted") {
    granted = true;
  } else if (Notification.permission === "denied") {
    // Notification.requestPermission().then(function (permission) {
    //   // If the user accepts, let's create a notification
    //   if (permission === "granted") {
    //     granted = permission === "granted" ? true : false;
    //   }
    // });
  } else if (Notification.permission === "default") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        granted = permission === "granted" ? true : false;
      }
    });
  }
  if (document[hidden]) {
    granted ? showNotification() : showError();
  } else {
    
  }
  // show notification or error
  // granted ? showNotification() : showError();
  // granted ? document.addEventListener(visibilityChange, showNotification, false) :document.addEventListener(visibilityChange, showError, false);
};

const getDeviceToken = function () {
  console.log("get device token");

  return new Promise((resolve, reject) => {
    if (firebase.apps.length == 0 || !firebase.apps) {
      // let firebaseConfig = {
      //   apiKey: "AIzaSyD6A72jOvj8uTmmFBgM_zD6rtg9rCugyDU",
      //   authDomain: "xpaldev.firebaseapp.com",
      //   databaseURL: "https://xpaldev-default-rtdb.firebaseio.com",
      //   projectId: "xpaldev",
      //   storageBucket: "xpaldev.appspot.com",
      //   messagingSenderId: "253355480241",
      //   appId: "1:253355480241:web:8a3219f3e8e41e54683e90",
      //   measurementId: "G-Z41J2WZT63",
      // };

      let firebaseConfig = {
        apiKey: "AIzaSyD6A72jOvj8uTmmFBgM_zD6rtg9rCugyDU",
        authDomain: "xpaldev.firebaseapp.com",
        databaseURL: "https://xpaldev-default-rtdb.firebaseio.com",
        projectId: "xpaldev",
        storageBucket: "xpaldev.appspot.com",
        messagingSenderId: "253355480241",
        appId: "1:253355480241:web:8a3219f3e8e41e54683e90",
        measurementId: "G-Z41J2WZT63",
      };

      firebase.initializeApp(firebaseConfig);
    }

    const messaging = firebase.messaging();

    
    Notification.requestPermission()
      .then(function () {
        // get the token in the form of promise
        return messaging.getToken();
      })
      .then(function (token) {
        // console.log(token);

        resolve(token);
      })
      .catch(function (err) {
        reject("");
      });
  });
};

const updateFavicon = function (type: any) {
  let iconUrl;
  if (type == "show") {
    iconUrl = "/favicon-badge.ico";
  } else {
    iconUrl = "/favicon.ico";
  }

  let link: any = document.querySelector("link[rel~='icon']");
  let link2: any = document.querySelector("link[rel~='shortcut icon']");
  let link3: any = document.querySelector("link[rel~='apple-touch-icon']");

  if (!link) {
    // link = document.createElement('link');
    // link.rel = 'icon';
    //document.getElementsByTagName('head')[0].appendChild(link);
    document.getElementsByTagName("link")[0].href = iconUrl;
  }
  // link.href = "";
  // link.href = iconUrl;
  // link.type = 'image/x-icon';

  if (!link2) {
    // link2 = document.createElement('link');
    // link2.rel = 'icon';
    // document.getElementsByTagName('head')[0].appendChild(link2);
    document.getElementsByTagName("link")[0].href = iconUrl;
  }
  // link2.href = iconUrl;
  // link2.type = 'image/x-icon';

  if (!link3) {
    // link3 = document.createElement('link');
    // link3.rel = 'icon';
    // document.getElementsByTagName('head')[0].appendChild(link3);
    document.getElementsByTagName("link")[0].href = iconUrl;
  }
  // link3.href = iconUrl;
  // link3.type = 'image/x-icon';
};
const allowNotification = function () {
  return new Promise((resolve, reject) => {
    let granted;

    console.log("permission:", Notification.permission);
    Notification.requestPermission().then(function (permission) {
      console.log("permission:", permission);
      if (Notification.permission === "granted") {
        granted = true;
        console.log("permision: ", Notification.permission);
        try {
          getDeviceToken().then((token) => {
            resolve(token);
          });
        } catch {
          reject("");
        }
      } else {
        resolve("");
      }
    });
  });
};

export {
  claimNotify,
  notify,
  webNotifications,
  notificationPermission,
  allowNotification,
  getDeviceToken,
  updateFavicon,
  notifyTrigger,
};
