import { getSecureState, setSecureState } from "./securels.service";
import { authenticate, createAuthToken, userLogin } from "./user.service";
import moment from "moment";
/* import { generateKeys } from "./encryption"; */
import { getDeviceToken } from "./notify.service";
import getBrowserFingerprint from "get-browser-fingerprint";
/* import { authToken } from "./socketAuth.service"; */
import crypto from 'crypto';

let setauthToken = (uaid: any, setIsAuthenticated?: any) => {


  return new Promise((resolve, reject) => {
    if (!!setIsAuthenticated) {
      setIsAuthenticated(true);
    }
try{
  authenticate(uaid).then((res: any) => {

    if (!!res.data) {
      if (res.data.status == "ok") {


        if (!!res.data.data) {
          setSecureState("uaid", res.data.data);
          setSecureState("udt", moment().unix());

          const loginObj: any = {};
          const username = getSecureState("username").value;
          const p_token = getSecureState("p_token").value;
          loginObj.UserNumber = username;
          loginObj.Password = p_token;

          if (!!username && !!p_token) {
            let userObj = {
              "UserNumber": username,
              "Password": p_token,
              "DeviceModel": "Web Browser",
              "DeviceToken": "",
              "AppVersion": "1.1.1",
              "AppBuildVersion": "2.2.1",
              "DeviceChannel": "web",
              "OsVersion": "",
              "DeviceUdid": getSecureState("uxid").value,
              "IsConfirm": false
          }

          if (!!setIsAuthenticated) {
                    setIsAuthenticated(false);
                  }
          } else {
            if (!!setIsAuthenticated) {
              setIsAuthenticated(false);
            }
            resolve({
              status: "ok",
            });
          }
        }
      }
    }
  });
}
    catch{
      setIsAuthenticated(false);
    }
  });
};

let authenticateUser = (setIsAuthenticated?: any) => {
   let fingerprint = getBrowserFingerprint();
  let uaid = "";
  return new Promise((resolve, reject) => {

    uaid = `xpal_${fingerprint}_web`;
    uaid = crypto.createHash('sha256').update(uaid).digest('hex');
    if (!!!getSecureState("uaid")) {

      setSecureState("uxid", uaid);
      setauthToken(uaid, setIsAuthenticated).then((res: any) => {
        if (res.status == "ok") {
          resolve({
            status: "ok",
          });
        }
      });
    } else {

      let udtValue = parseInt(getSecureState("udt").value);
      const formattedtimestamp = moment.unix(udtValue).format("yyyy-MM-DD");
      let today = moment().unix();
      let formattedTodayDate = moment.unix(today).format("yyyy-MM-DD");
      let isBefore = moment(formattedtimestamp).isBefore(formattedTodayDate);
      if (isBefore) {
        setSecureState("uxid", uaid);
        setauthToken(uaid, setIsAuthenticated).then((res: any) => {
          if (res.status == "ok") {
            resolve({
              status: "ok",
            });
          }
        });
      } else {
        resolve({
          status: "ok",
        });
      }
    }
  });
};

//get device token firebase
let getUserDeviceToken = async () => {
  return new Promise((resolve, reject) => {
    getDeviceToken()
      .then((token) => {
        resolve(token);
      })
      .catch((err) => {
        resolve("");
      });
  });
};

export { authenticateUser, getUserDeviceToken };
