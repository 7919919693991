import React,{useState,useEffect} from 'react';
import { refXIDs } from "../../services/user.service";
import { Circle } from 'better-react-spinkit';
import './components.css';
import Logo from '../../assets/Logo.png';
import XIDsTable from './XIDsTable';
import ManageXIDs from './ManageXIDs';
function XIDs(){
  const [APIData, setAPIData] = useState([]);
  const [refActive, setRefActive]=useState<string | undefined>();
  const [selectedXIDIndex, setSelectedXIDIndex] = useState(null);

  const [refxID, setrefxID]=useState<string>('');
  const [linkStatus, setLinkStatus]=useState<string>('');
  const [pinStatus, setPinStatus] = useState<string>('');
  const [linkExpired, setLinkExpired]=useState<string>('');
  const [newLinkExpired, setNewLinkExpired]=useState<string>('');

  const [page, setPage]=useState(1);
  const [size, setSize]=useState(50);
  useEffect(() => {
    refXIDs(page, size).then(res => {
      if (res.data.status=='ok'){
        setLoading(false);
        //console.log(res.data.data[0]);
        setAPIData(res.data.data);
      }
    })
    
    }, [page, size]);  
    const [loading, setLoading] = useState(true);
    const [hideXIDs, setHideXIDs] = useState(false);
    
/*     const handleModal = (action) => {
        if (action === 'Edit') {
          setHideXIDs(true);

        } 
      }; */
      const handleEditCallBack = (refxID, linkStatus, pinStatus, linkExpired, linkCreated ) => {
        console.log('link status on xids page is'+ linkStatus+ 'link expiry status on xids page is'+ linkExpired+'new link expiry status is' + linkCreated);
        setHideXIDs(true);
        setrefxID(refxID);
        setPinStatus(pinStatus);
        setLinkStatus(linkStatus);
        setLinkExpired(linkExpired);
        setNewLinkExpired(linkCreated);
      };
      const onBack = () => {
        setHideXIDs(false);
        setLoading(true);
        refXIDs(page, size).then(res => {
          if (res.data.status=='ok'){
            setLoading(false);
            //console.log(res.data.data[0]);
            setAPIData(res.data.data);
          }
        })

      };

    return(
        
        <>
        {!hideXIDs && (
                <div className='col-md-10 mangeXIDs secondCol'>
                <div className='xidsTitle'>
                    <i className="fa-solid fa-arrow-right mr-2"></i>xIDs
                </div>
                <div className='manageText mt-4 mb-4'>
                    Manage xIDs
                </div>
                {loading ? (
                    <div className="loading-spinner" style={{
                      width: '1100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '65vh'}}>
                        <Circle size={100} color="#1E90FF" />
                    </div>
                ) : (
                    <XIDsTable APIData={APIData} handleEditCallBack={handleEditCallBack} page={page} size={size} setPage={setPage}/>
                )}

                </div>
            )}

          {hideXIDs && (
              <ManageXIDs onBack={onBack} refxID={refxID} pinStatus={pinStatus} linkStatus={linkStatus} linkExpired={linkExpired} newLinkExpired={newLinkExpired}/>
          )}



        </>
    )
}
export default XIDs;