import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import './components.css';
import Logo from '../../assets/xPalBusinessLogo.png';
import signOut from '../../assets/signOut.png';
import listIcon from '../../assets/listIcon.png';
import dashboardIcon from '../../assets/dashboardIcon.png';
import Login from './Login';
import XIDsTable from './XIDsTable';
import XIDs from './XIDs';
import Dashboard from './Dashboard';
import Calculator from '../Calculator';
import { authenticateUser } from "../../services/token-authentication.service";
import { logout } from '../../services/user.service';
import { setSecureState, getSecureState} from '../../services/securels.service';
import Purchase2 from '../Purchase2';
import PurchaseDashboard from './PurchaseDashboard';
import Teams from './Teams';
import xPalBusinessWhiteLogo from '../../assets/xPal-Biz-logo-white.png';
function Main({onInputValueChange}){
    
    /*aythenticate*/
    useEffect(() => {
        authenticateUser().then(res => {
            //console.log("authenticateUser")
        })
        }, []); 

        const handleInputChange = (newValue) => {
            onInputValueChange(newValue);
            //console.log('response from landingpage',newValue);
          };


    const navigate= useNavigate();
    const [newValue, setNewValue] = useState("");
    const [newTotalCost, setNewTotalCost]=useState("");

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeComponent, setActiveComponent] = useState('');

    const [total, setTotal] = useState(0);
    const xID = sessionStorage.getItem('xID');
    const xid_businessmember= sessionStorage.getItem('xid_businessmember');
   /*  const name1= sessionStorage.getItem('name_businessmember'); */
    const NU_Name=sessionStorage.getItem('name_newuser');

    const BM_Name=sessionStorage.getItem('name_businessmember');

    const formattedxID = xID?.replace(/.{3}/g, '$& ');
    const formattedxid_businessmember = xid_businessmember?.replace(/.{3}/g, '$& ');
    //console.log(formattedxID);
    //console.log(formattedxid_businessmember);
    useEffect(() => {
        // Check if required data exists in Session Storage
        const xID = sessionStorage.getItem('xID');
        const pin = sessionStorage.getItem('pin');
        const pin_businessmember= sessionStorage.getItem('pin_businessmember');
        if (pin || pin_businessmember) {
            setIsLoggedIn(true);
            //console.log(xID);
            //console.log(pin_businessmember);
            //setActiveComponent('XIDs');
            setActiveComponent('Dashboard');
        }
    }, []);

        const clearSession = () => {
            sessionStorage.removeItem('xid_businessmember');
            sessionStorage.removeItem('pin_businessmember');
/*          sessionStorage.removeItem('name_businessmember'); */
            sessionStorage.removeItem('xID');
            sessionStorage.removeItem('pin');
            sessionStorage.removeItem('name_newuser');
            sessionStorage.removeItem('name_businessmember');

            let userObj = {
                UserNumber: getSecureState("loggedInUserNumber").value || getSecureState("registeredUserNumber").value,
                Password: getSecureState("loggedInPassword").value || getSecureState("registeredPassword").value,
                pin: getSecureState("loggedInpin").value || getSecureState("registeredpin").value,
                DeviceModel: 'Web Browser',
                DeviceToken: '',
                AppVersion: '',
                AppBuildVersion: '',
                DeviceChannel: 'web',
                OsVersion: '',
                DeviceUdid: getSecureState("loggedInDeviceUdid").value || getSecureState("registeredDeviceUdid").value,
                Udid: getSecureState("loggedInUdid").value || getSecureState("registeredUdid").value,
                IsConfirm: false,
            };

            logout(userObj)
                .then((res) => {
                  if (res) {
                    if (res.data.status === 'ok') {

                    }
                    else{

                      
                    }
                  }
                })
                .catch((err) => {

                  console.log(err);
                });
            setIsLoggedIn(false);
            navigate('/');
            
        };

    const handleLoginSuccess = () => {
            setIsLoggedIn(true);
            setActiveComponent('Dashboard')    
    };

    const handleButtonClick = (componentName) => {
        setActiveComponent(componentName);
    };
    const handleCloseClick = () => {
        navigate('/');
       // console.log('wait');  
    };
    const handleGoToPurchaseSec = () => {
        setActiveComponent('PurchaseDashboard');
    };
    const backToDahboard =()=>{
        setActiveComponent('Dashboard')
    }


return(
    <>
        {isLoggedIn ? (
            <div className='d-flex XIDs'>
            <div className='col-md-3 imgLogo firstCol'>
                <div className='d-flex flex-column justify-content-between firstColPartsContainer'>
                    <div className='partOne mt-4'>
                        <a href="/"><img src="https://dz5tyoxl8h44g.cloudfront.net/xbiz-logo-white.png" alt='xPal Business'/></a>
                        <hr/>
                        <div className='adminName'>
                            <p>{NU_Name || BM_Name}</p>
                            
                            {/* <p>{nu_name}</p> */}
                        </div>
                        <div className='title'>
                            <p>xID:</p>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='id'>{formattedxID || formattedxid_businessmember}</div>
                                {/* <div className='id'>{xID}</div> */}
                                <div className='admin'>Admin</div>
                            </div>
                        </div>
                        <hr/>
                        <div className='list'>
                            <div className={`d-flex justify-content-start align-items-center listDiv mb-3 ${activeComponent === 'Dashboard' ? 'activeButton' : ''}`} onClick={() => handleButtonClick('Dashboard')}>
                                <div>
                                    <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_dashboardIcon.png" alt='dashboard'/>
                                </div>
                                <div className={`d-flex justify-content-between align-items-center col-md-11 listDivCol2`}>
                                    <div><p>Dashboard</p></div><div><i className="fa-solid fa-arrow-right"></i></div>
                                </div>

                            </div>

                            <div className={`d-flex justify-content-start align-items-center listDiv mb-3 ${activeComponent === 'XIDs' ? 'activeButton' : ''} ${total === 0 ? 'disabled' : ''}`} onClick={() => handleButtonClick('XIDs')}>
                                <div>
                                    <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_listIcon.png" alt='XIDs'/>
                                </div>
                                <div className={`d-flex justify-content-between align-items-center col-md-11 listDivCol2`} >
                                    <div><p>xIDs</p></div><div>{total === 0 && (<i className="fa-solid fa-lock mr-3" style={{color:'white'}}></i>)}<i className="fa-solid fa-arrow-right"></i></div>
                                </div>
                            </div>
                            <div className={`d-flex justify-content-start align-items-center listDiv mb-3 ${activeComponent === 'Team' ? 'activeButton' : ''}  ${total === 0 ? 'disabled' : ''}`} onClick={() => handleButtonClick('Team')}>
                                <div>
                                    <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_listIcon.png" alt='XIDs'/>
                                </div>
                                <div className={`d-flex justify-content-between align-items-center col-md-11 listDivCol2`} >
                                    <div><p>Team</p></div><div>{total === 0 && (<i className="fa-solid fa-lock mr-3" style={{color:'white'}}></i>)}<i className="fa-solid fa-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='partTwo'>
                        <div className='buyMore d-flex flex-column justify-content-between align-items-center'>
    {/*                             <div className='text'>
                            Buy More xIDs
                        </div> */}
                        <div className={`btn1 ${activeComponent === 'BuyMore' ? 'activeButton' : ''}`} onClick={() => handleButtonClick('BuyMore')}>
                            <a>Buy Business xIDs</a>
                        </div>
                    </div>
                    <hr/>
                    <div className='logOut mb-4'>
                        <div className='d-flex justify-content-start align-items-center' onClick={() => clearSession()}>
                            <div>
                                <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_signout.png" alt='sign out'/>
                            </div>
                        <div className='d-flex justify-content-between align-items-center col-md-11' >
                            <div><p>Sign out</p></div><div><i className="fa-solid fa-arrow-right"></i></div>
                        </div>
                        </div>

                    </div>
                    </div>
                </div>
                
            </div>

            {activeComponent === 'Dashboard' && <Dashboard onTotalChange={setTotal}/>}
            {activeComponent === 'XIDs' && <XIDs/>}
            {activeComponent === 'Team' && <Teams/>}
            {activeComponent==='BuyMore' && 
                <div className='d-flex justify-content-start col-md-8'>

            <Calculator onInputChange={handleInputChange} goToPurchaseSec={handleGoToPurchaseSec} newValue={newValue} setNewValue={setNewValue}
            setNewTotalCost={setNewTotalCost}
            />
            </div>}
            {activeComponent === 'PurchaseDashboard' && <PurchaseDashboard newValue={newValue} newTotalCost ={newTotalCost} backToDahboard={backToDahboard}/> }
            
            
            
        </div>
        ):(

        <Login onLoginSuccess={handleLoginSuccess} handleCloseClick={handleCloseClick}/>
        )}
    </>
)
}
export default Main

