import React, { useState, useEffect, useRef } from 'react';
import './components.css';
import { Button, Modal } from 'react-bootstrap';
import { update, release, createLink } from "../../services/user.service";
import { Circle } from 'better-react-spinkit';
import crypto from 'crypto';
function ManageXIDs({ onBack, refxID, pinStatus, linkStatus, linkExpired, newLinkExpired }) {


    const [isChecked, setIsChecked] = useState(true);
    const [initialValues, setInitialValues] = useState({
        username: localStorage.getItem('clickedUserName') || '',
    });

    const [username, setUsername] = useState(initialValues.username);
    const [showUserNameLoader, setUserNameLoader]=useState(false);
    const [showUserNameSavedMessage, setShowUserNameSavedMessage]=useState(false);
    const [pin, setPin] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm]=useState('');

    const [showModal, setShowModal] = useState(false);
    const [showGeneralModal, setShowGeneralModal]=useState(false);

    const [showPinModal, setShowPinModal]=useState(false);
    const [showPasswordModal, setShowPasswordModal]=useState(false);

    const [showConfirmPinModal, setShowConfirmPinModal]=useState(false);
    const [showConfirmPasswordModal, setShowConfirmPasswordModal]=useState(false);

    const [pinErrorMessage, setPinErrorMessage]=useState(false);
    const [confirmPinErrorMessage, setConfirmPinErrorMessage]=useState(false);
    const [savePinErrorMessage, setSavePinErrorMessage]=useState(false);
    const [savePasswordErrorMessage, setSavePasswordErrorMessage]=useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage]=useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage]=useState(false);

    const [isUserNameChanging, setIsUserNameChanging]=useState(false);

    const [PinNotSetMessage, setPinNotSetMessage]=useState(false);
    const [generateLinkButton, setGenerateLinkButton]=useState(false);
    const [linkGenerated, setLinkGenerated]=useState(false);
    const [generateLinkLoader, setGenerateLinkLoader]=useState(false);
    
    /* console.log(clickedXid, clickedXidWithoutSpaces); */


    useEffect(() => {
       console.log('link status on manage page is'+ linkStatus+ 'link expiry status on xids page is'+ linkExpired + 'for xid' + refxID);
        if (pinStatus==='false' && newLinkExpired==='null'){
            setPinNotSetMessage(true);
            setGenerateLinkButton(true);
            setShowPinModal(true);
            //console.log('false is executing');
        }

        else if (pinStatus==='true' && linkStatus==='false' && linkExpired==='false' && newLinkExpired==='null'){
            //console.log('pin is set but link need to be created');
            setGenerateLinkButton(true);
        }
        
        else if (pinStatus==='true' && linkStatus==='true' && linkExpired==='false' && newLinkExpired==='null'){
            setLinkGenerated(true);
        } 
 
        else if(pinStatus==='true' && linkStatus==='true' && linkExpired==='true' && newLinkExpired==='null'){
            setLinkGenerated(false);
        }
        else if (pinStatus==='true' && linkStatus==='true' && linkExpired==='true' && newLinkExpired==='false'){
            setLinkGenerated(true);
        }
        
        //console.log(linkStatus + 'from manage xids');
      }, [pinStatus, linkExpired]);

    const [pin1, setPin1] = useState('');
    const [pin2, setPin2] = useState('');
    const [pin3, setPin3] = useState('');
    const [pin4, setPin4] = useState('');


    const handlePinChange = (e, pinIndex) => {
        setSavePinErrorMessage(false)
        const formattedPinValue = e.target.value.replace(/[^0-9]/g, ''); //prevent non numeric characters
        //focus all input fields one by one  

        switch (pinIndex) {
          case 1:
            setPin1(formattedPinValue);
            if (formattedPinValue !== '' && pin2Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin2Ref.current.focus();
            }
            break;
          case 2:
            setPin2(formattedPinValue);
            if (formattedPinValue !== '' && pin3Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin3Ref.current.focus();
            }
            break;
          case 3:
            setPin3(formattedPinValue);
            if (formattedPinValue !== '' && pin4Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin4Ref.current.focus();
            }
            break;
          case 4:
            setPin4(formattedPinValue);
            break;
          default:
            break;
        }

        
      };
      const pin1Ref = useRef(null);
      const pin2Ref = useRef(null);
      const pin3Ref = useRef(null);
      const pin4Ref = useRef(null);

      const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, pinIndex: number) => {
        if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 4) {
          setPin4(''); // Clear the value of pin4
          // @ts-ignore: Object is possibly 'null'.
          pin3Ref.current?.focus(); // Shift focus to pin3
        } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 3) {
          setPin3(''); // Clear the value of pin3
          // @ts-ignore: Object is possibly 'null'.
          pin2Ref.current?.focus(); // Shift focus to pin2
        } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 2) {
          setPin2(''); // Clear the value of pin2
          // @ts-ignore: Object is possibly 'null'.
          pin1Ref.current?.focus(); // Shift focus to pin1
        }
      };
      const Pin = `${pin1}${pin2}${pin3}${pin4}`;

      useEffect(() => {
       
        if (Pin.length === 4) {
           setShowPinModal(false);
           setShowConfirmPinModal(true);
        }
      }, [pin1, pin2, pin3, pin4]);

      const [pin5, setPin5] = useState('');
      const [pin6, setPin6] = useState('');
      const [pin7, setPin7] = useState('');
      const [pin8, setPin8] = useState('');
  
  
      const handleConfirmPinChange = (e, pinIndex) => {
        setPinErrorMessage(false);
        setConfirmPinErrorMessage(false);
        const formattedPinValue = e.target.value.replace(/[^0-9]/g, ''); //prevent non numeric characters
        //focus all input fields one by one  

        switch (pinIndex) {
          case 1:
            setPin5(formattedPinValue);
            if (formattedPinValue !== '' && pin6Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin6Ref.current.focus();
            }
            break;
          case 2:
            setPin6(formattedPinValue);
            if (formattedPinValue !== '' && pin7Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin7Ref.current.focus();
            }
            break;
          case 3:
            setPin7(formattedPinValue);
            if (formattedPinValue !== '' && pin8Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin8Ref.current.focus();
            }
            break;
          case 4:
            setPin8(formattedPinValue);
            break;
          default:
            break;
        }

        
      };
      const pin5Ref = useRef(null);
      const pin6Ref = useRef(null);
      const pin7Ref = useRef(null);
      const pin8Ref = useRef(null);
  
        const handleConfirmBackspace = (e: React.KeyboardEvent<HTMLInputElement>, pinIndex: number) => {
            if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 4) {
              setPin8(''); 
              // @ts-ignore: Object is possibly 'null'.
              pin7Ref.current?.focus(); // Shift focus to pin3
            } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 3) {
              setPin7(''); 
              // @ts-ignore: Object is possibly 'null'.
              pin6Ref.current?.focus(); // Shift focus to pin2
            } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 2) {
              setPin6(''); 
              // @ts-ignore: Object is possibly 'null'.
              pin5Ref.current?.focus(); 
            }
          };
          const confirmPin = `${pin5}${pin6}${pin7}${pin8}`;

      
    const handleUsernameChange = (e) => {
        setIsUserNameChanging(true);
        setUsername(e.target.value);
        
        //console.log('username is' ,username);
        const newUsername = e.target.value;
        setUsername(newUsername);
        
       // console.log('username is', newUsername);
        setInitialValues(prevValues => ({
            ...prevValues,
            username: newUsername
        }));
        setButtonStyles({
            opacity: 1,
            pointerEvents: 'auto'
        }); 
        
    };

 
    const handlePrivacySettingsClick = () => {
           
       setShowModal(true); 
    };

    const handleGeneralSettingsClick = () => {
           
        setShowGeneralModal(true); 
     };

    const handlePinResetClick = () => {
        setPin1('');
        setPin2('');
        setPin3('');
        setPin4('');
        setPin5('');
        setPin6('');
        setPin7('');
        setPin8('');
        setShowPinModal(true);  
        setConfirmPinErrorMessage(false);
        setPinErrorMessage(false);
        setSavePinErrorMessage(false);
    };
    const handlePinResetModal=()=>{
        if (Pin.length!==4){
            setSavePinErrorMessage(true);
        }
    }
    const handleConfirmPinResetClick = () => {
        if(confirmPin && confirmPin.length===4){
            if (Pin===confirmPin){
                setShowConfirmPinModal(false);
                setPinNotSetMessage(false);
                const pinHash = crypto.createHash('sha256').update(confirmPin).digest('hex');
                const reversePin = confirmPin.split("").reverse().join("");
                const reversePinHash = crypto.createHash("sha256").update(reversePin).digest("hex");

                const xIDPin = `${refxID}_xpal_${confirmPin}`;
                const xIDPinHash = crypto.createHash('sha256').update(xIDPin).digest('hex');

                const password=  `${refxID}_xpal_${confirmPin}`;
                const passwordHash = crypto.createHash('sha256').update(password).digest('hex');

                
                let userObj = {
                    UserPin: JSON.stringify({
                        PIN: pinHash,
                        RPIN: reversePinHash,
                      }),
                      //PIN: xIDPinHash,
                      UserPassword: passwordHash,
                    RxID: refxID,
                };
        
                update(userObj)
                    .then((res) => {
                        if (res && res.data.status === 'ok') {
                        } else {
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                    console.log(userObj + "for" + refxID) ;
            }

            else{
                setConfirmPinErrorMessage(true);
                setPinErrorMessage(false);
            }

        }
        else{
            setPinErrorMessage(true);
        }
    };

    const handlePasswordResetClick = () => {
        setPassword('');
        setPasswordConfirm('');
        setShowPasswordModal(true);  
        setPasswordErrorMessage(false);
        setConfirmPasswordErrorMessage(false);
        setSavePasswordErrorMessage(false);
    };

    const generateLink=()=>{
        setGenerateLinkLoader(true);
        let userObj = {
            xID: refxID,
        };

        createLink(userObj)
            .then((res) => {
                if (res && res.data.status === 'ok') {
                    setGenerateLinkLoader(false);
                    setLinkGenerated(true);
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
 

    const handlePasswordSetModal = () => {
        if(password.length>=4){
            setShowPasswordModal(false);
            setShowConfirmPasswordModal(true);
        }
        else{
            setSavePasswordErrorMessage(true);
        }
    };
    const handleConfirmPasswordModal=()=>{
        if(passwordConfirm.length>0){
            if (password===passwordConfirm){
                setShowConfirmPasswordModal(false); 
                const pwdString = `${refxID}_xpal_${passwordConfirm}`;
                const pwdHash = crypto.createHash("sha256").update(pwdString).digest('hex'); 
                let userObj = {

                    UserPassword: pwdHash,
                    RxID: refxID,
                };
        
                update(userObj)
                    .then((res) => {
                        if (res && res.data.status === 'ok') {
                        } else {
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            else{
                setPasswordErrorMessage(true);
            }
        }
        else{
            setConfirmPasswordErrorMessage(true);
            setPasswordErrorMessage(false);
        }

    }


    const [selectedTextMessageOption, setSelectedTextMessageOption] = useState('business contact only'); 
    const handleSelectedTextMessageOption = (TextMessageOption) => {
        setSelectedTextMessageOption(TextMessageOption);
       // console.log('TextMessageOption',TextMessageOption);
    };

    const [selectedAudioCallOption, setSelectedAudioCallOption] = useState('business contact only'); 
    const handleSelectedAudioCallOption = (AudioCallOption) => {
        setSelectedAudioCallOption(AudioCallOption);
        console.log('AudioCallOption',AudioCallOption);
    };

    const [selectedVideoCallOption, setSelectedVideoCallOption] = useState('business contact only'); 
    const handleSelectedVideoCallOption = (VideoCallOption) => {
        setSelectedVideoCallOption(VideoCallOption);
        console.log('VideoCallOption',VideoCallOption);
    };

    const handleSaveButtonClick=()=>{
        setUserNameLoader(true);
        let userObj = {
            UserPublicName: username,
            RxID: refxID,
        };

        update(userObj)
            .then((res) => {
                if (res && res.data.status === 'ok') {
                    setUserNameLoader(false);
                    setButtonStyles({
                        opacity: 0.5,
                        pointerEvents: 'none'
                    }); 
/*                     setShowUserNameSavedMessage(true);
                    setTimeout(() => {
                        setShowUserNameSavedMessage(false);
                    }, 1000); */
                } else {    
                }
            })
            .catch((err) => {
                console.log(err);
                setUserNameLoader(false);
            });
    }

    const handlePrivacySaveButtonClick=()=>{
        setShowModal(false);
        
        let userObj = {
            RxID: refxID,
            allowVideoCalls: selectedVideoCallOption === 'anyone' ? 'ALLOW_EVERYONE' : 'FRIENDS',
            allowCalls: selectedAudioCallOption === 'anyone' ? 'ALLOW_EVERYONE' : 'FRIENDS',
            allowMessages: selectedTextMessageOption === 'anyone' ? 'ALLOW_EVERYONE' : 'FRIENDS',
        };

        update(userObj)
            .then((res) => {
                if (res && res.data.status === 'ok') {
                    setSelectedTextMessageOption('anyone');
                    setSelectedAudioCallOption('anyone');
                    setSelectedVideoCallOption('anyone');
                } else {    
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handleReleaseUserClick = () => {
        let userObj = {
            UserPublicName: username,
            RxID: refxID,
        };

        release(userObj)
            .then((res) => {
                if (res.data.status === 'ok') {
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const [buttonStyles, setButtonStyles] = useState<React.CSSProperties>({
        opacity: 0.5,
        pointerEvents: 'none'
    });
    const [saveButtonStyles, setSaveButtonStyles] = useState<React.CSSProperties>({
        opacity: 0.5,
        pointerEvents: 'none'
    });
    useEffect(() => {

        if (isUserNameChanging) {
            setButtonStyles({
                opacity: 1,
                pointerEvents: 'auto'
            });
        } 

            else {

            setButtonStyles({
                opacity: 0.5,
                pointerEvents: 'none'
            });
        }
    }, [isUserNameChanging]);
/*     useEffect(() => {

        if (Pin.length===0) {
            setButtonStyles({
                opacity: 1,
                pointerEvents: 'auto'
            });
        } else {

            setButtonStyles({
                opacity: 0.5,
                pointerEvents: 'none'
            });
        }
    }, [Pin]); */
    const [createGroups, setCreateGroups] = useState(0);
    const [videoCalls, setVideoCalls] = useState(1);
    const [audioCalls, setAudioCalls] = useState(1);
    const handleToggleChange = (e, type) => {
        const isChecked = e.target.checked ? 1 : 0;
        switch (type) {
            case 'createGroups':
                setCreateGroups(isChecked);
                break;
            case 'videoCalls':
                setVideoCalls(isChecked);
                break;
            case 'audioCalls':
                setAudioCalls(isChecked);
                break;
            default:
                break;
        }
    };

    //console.log('Create Groups:', createGroups);
    //console.log('Video Calls:', videoCalls);
    //console.log('Audio Calls:', audioCalls);

    const handleGeneralSaveButtonClick=()=>{
        setShowGeneralModal(false);
        
        let userObj = {
            RxID: refxID,
            AllowCallAccess: audioCalls ? 'true' : 'false',
            AllowVideoCallsAccess: videoCalls ? 'true' : 'false',
        };

        update(userObj)
            .then((res) => {
                if (res && res.data.status === 'ok') {

                } else {    
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <>
            <div className='col-md-11 mangeXIDs secondCol manageComponent'>
                <div className='xidsTitle'>
                    <i className="fa-solid fa-arrow-right mr-2"></i>xIDs <i className="fa-solid fa-arrow-right mr-1 ml-3"></i> Manage xIDs
                </div>

                <div className='manageContainer col-md-9'>
                    <div className='BacktoXIDs' onClick={onBack}>
                    <i className="fa-solid fa-arrow-left mr-2"></i>Back
                    </div>
                    <div className='mainContentWrapper d-flex justify-content-between align-items-center'>
                       
                        <div className='col1 col-md-5' style={{position:'relative'}}>

                            <div className='topSection mb-4'>
                                <h3>xID:</h3>
                                <h2>{refxID.replace(/\s/g, '')?.replace(/(.{3})/g, "$1 ")}</h2>
                            </div>

                            <div className='form'>
                                <div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label>Displayname</label>
                                        
                                    </div>
                                    <input className="inputField text-center" type="text" value={username} autoFocus onChange={handleUsernameChange}/>
                                </div>

                                <div className='d-flex justify-content-start align-items-center'>
                                    <div className='releaseUser reset mb-3 saveSettings d-flex justify-content-center' style={buttonStyles} onClick={() => handleSaveButtonClick()}>Save {showUserNameLoader && (<Circle size={25} color="white" className="ml-2" />)}</div>
                                    
                                </div>
                                {showUserNameSavedMessage && (<div className="usernameSaved">Username Saved</div>)}
                            </div>
                        </div>
                        <div className="vertical-divider"></div>;
                        <div className='col2 col-md-5'>
                        <div className='settings'>
                                    <div className='settingsMain d-flex justify-content-between align-items-center' onClick={() => handlePrivacySettingsClick()}><div>Privacy Settings</div><div><i className="fa-solid fa-arrow-right mr-1 ml-3"></i></div></div>
                                    <div className='settingsMain d-flex justify-content-between align-items-center' onClick={() => handleGeneralSettingsClick()}><div>General Settings</div><div><i className="fa-solid fa-arrow-right mr-1 ml-3"></i></div></div>
                                    <div>
                                    <div className="releaseUser mb-3 reset" onClick={() => handlePinResetClick()}>Reset PIN</div>
                                    </div>
                                    <div>
                                    <div className="releaseUser reset" onClick={() => handlePasswordResetClick()} style={{
                                                pointerEvents: PinNotSetMessage ? 'none' : 'auto', 
                                                opacity: PinNotSetMessage ? '0.5' : '1'
                                            }}>Reset Password</div>
                                        
                                            <div className="releaseUser reset mt-3" onClick={() => generateLink()} style={{
                                                pointerEvents: PinNotSetMessage || linkGenerated ? 'none' : 'auto', 
                                                opacity: PinNotSetMessage || linkGenerated ? '0.5' : '1'
                                            }}>{linkGenerated? 'Link Generated':"Generate Link"} {(generateLinkLoader && <span className="stripeBtnLoader" id="stripeBtnLoader" style={{right:"25%", bottom:"41%"}}></span>)} </div>
                                                
                                    </div>
                                    
                                    {/* <div className='settingsMain d-flex justify-content-between align-items-center'><div>Create Groups</div><div><input type="checkbox" id="toggle3" /><label htmlFor="toggle3" id="toggleLabel3"></label></div></div>
                                    <div className='settingsMain d-flex justify-content-between align-items-center'><div>Video Calls</div><div><input type="checkbox" id="toggle" /><label htmlFor="toggle" id="toggleLabel"></label></div></div>
                                    <div className='settingsMain d-flex justify-content-between align-items-center mb-2'><div>Audio Calls</div><div><input type="checkbox" id="toggle2" /><label htmlFor="toggle2" id="toggleLabel2"></label></div></div> */}
                                </div>

                                <div className='realease'>
                                
                                
                                <div className='releaseUser mt-4' onClick={() => handleReleaseUserClick()}>Release User</div>
                                
                                </div>
                        </div>
                    </div>
{/*                     <div className='d-flex justify-content-center align-items-center mt-5'>
                    <div className="releaseUser reset saveSettings" onClick={() => handleSaveButtonClick()} style={buttonStyles}>Save Settings</div>
                     </div> */}
                </div>



            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} className='manmagexids_modal'>
                <Modal.Header>
                    <div className='d-flex align-items-center justify-content-between header'>
                            <p className='save' onClick={()=> setShowModal(false)} style={{visibility:'hidden'}}>Save</p>
                            <p className='ps'>Privacy Settings</p>
                            <p className='closebtn' onClick={()=> setShowModal(false)}><i className="fa-solid fa-x"></i></p>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <div className='textMessages'>
                        <h3 className='tm'>Text Messages</h3>
                        <div className='d-flex flex-column align-items-start optionsDiv'>
                        <div className='fullWidth borderBottom d-flex justify-content-between align-items-center' onClick={() => handleSelectedTextMessageOption('anyone')}>
                                <p className='pb-2 pt-2'>Anyone on xPal</p>
                                
                                {selectedTextMessageOption === 'anyone' ? (
                                    <div className='selectedOptionDiv'><i className="fa-solid fa-check"></i></div>
                                ) : (
                                    <div className='circleDiv'><i className="far fa-circle"></i></div>
                                )}
                            </div>
                            <div className='fullWidth d-flex justify-content-between align-items-center' onClick={() => handleSelectedTextMessageOption('business contact only')}>
                                <div>
                                <p className='pt-2'>Business Contacts Only</p>
                                <p className='pb-2 small'>Only able to contact within the circle of your organization</p>
                                </div>
                                {selectedTextMessageOption === 'business contact only' ? (
                                    <div className='selectedOptionDiv'><i className="fa-solid fa-check"></i></div>
                                ) : (
                                    <div className='circleDiv'><i className="far fa-circle"></i></div>
                                )}
                                
                            </div>
                            
                        </div>
                    </div>

                    <div className='audioCalls'>
                        <h3>Audio Call</h3>
                        <div className='d-flex flex-column align-items-start optionsDiv'>
                            <div className='fullWidth borderBottom d-flex justify-content-between align-items-center' onClick={() => handleSelectedAudioCallOption('anyone')}>
                                <p className='pb-2 pt-2'>Anyone on xPal</p>
                                {selectedAudioCallOption === 'anyone' ? (
                                    <div className='selectedOptionDiv'><i className="fa-solid fa-check"></i></div>
                                ) : (
                                    <div className='circleDiv'><i className="far fa-circle"></i></div>
                                )}
                            </div>
                            <div className='fullWidth d-flex justify-content-between align-items-center' onClick={() => handleSelectedAudioCallOption('business contact only')}>
                                <div>
                                <p className='pt-2'>Business Contacts Only</p>
                                <p className='pb-2 small'>Only able to Audio Call within the circle of your organization</p>
                                </div>
                                {selectedAudioCallOption === 'business contact only' ? (
                                    <div className='selectedOptionDiv'><i className="fa-solid fa-check"></i></div>
                                ) : (
                                    <div className='circleDiv'><i className="far fa-circle"></i></div>
                                )}
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className='videoCalls'>
                        <h3>Video Call</h3>
                        <div className='d-flex flex-column align-items-start optionsDiv'>
                        <div className='fullWidth borderBottom d-flex justify-content-between align-items-center' onClick={() => handleSelectedVideoCallOption('anyone')}>
                                <p className='pb-2 pt-2'>Anyone on xPal</p>
                                {selectedVideoCallOption === 'anyone' ? (
                                    <div className='selectedOptionDiv'><i className="fa-solid fa-check"></i></div>
                                ) : (
                                    <div className='circleDiv'><i className="far fa-circle"></i></div>
                                )}
                            </div>
                            <div className='fullWidth d-flex justify-content-between align-items-center' onClick={() => handleSelectedVideoCallOption('business contact only')}>
                                <div>
                                <p className='pt-2'>Business Contacts Only</p>
                                <p className='pb-2 small'>Only able to Video Call within the circle of your organization.</p>
                                </div>
                                {selectedVideoCallOption === 'business contact only' ? (
                                    <div className='selectedOptionDiv'><i className="fa-solid fa-check"></i></div>
                                ) : (
                                    <div className='circleDiv'><i className="far fa-circle"></i></div>
                                )}
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className='d-flex justify-content-start align-items-center'>
                    
                    <div className='saveBtnModal' onClick={()=> handlePrivacySaveButtonClick()}>Save</div> 
                    </div>
                </Modal.Body>

            </Modal>

            <Modal show={showGeneralModal} onHide={() => setShowGeneralModal(false)} className='manmagexids_modal'>
                <Modal.Header>
                    <div className='d-flex align-items-center justify-content-between header'>
                            <p className='closebtn' onClick={()=> setShowGeneralModal(false)} style={{visibility:'hidden'}}><i className="fa-solid fa-x"></i></p>
                            <p className='ps'>General Settings</p>
                            <p className='closebtn' onClick={()=> setShowGeneralModal(false)}><i className="fa-solid fa-x"></i></p>
                    </div>
                </Modal.Header>
                <Modal.Body>

                <div className='settingsMain d-flex justify-content-between align-items-center'><div>Create Groups</div><div><input type="checkbox" id="toggle3"  onChange={(e) => handleToggleChange(e, 'createGroups')}/><label htmlFor="toggle3" id="toggleLabel3"></label></div></div>
                <div className='settingsMain d-flex justify-content-between align-items-center'><div>Video Calls</div><div><input type="checkbox" id="toggle" onChange={(e) => handleToggleChange(e, 'videoCalls')}/><label htmlFor="toggle" id="toggleLabel"></label></div></div>

                <div className='settingsMain d-flex justify-content-between align-items-center'><div>Audio Calls</div><div><input type="checkbox" id="toggle2" onChange={(e) => handleToggleChange(e, 'audioCalls')}/><label htmlFor="toggle2" id="toggleLabel2"></label></div></div>
                <div className='d-flex justify-content-start align-items-center'>
                
                <div className='saveBtnModal' onClick={()=> handleGeneralSaveButtonClick()}>Save</div> 
                </div>
               
                </Modal.Body>
            </Modal>               

            <Modal show={showPinModal} onHide={()=>setShowPinModal(false)} className='manmagexids_modal pinResetModal'>
            <Modal.Header>
                    <div className='d-flex align-items-center justify-content-between header'>
                            <p className='closebtn' onClick={()=> setShowPinModal(false)} style={{visibility:'hidden'}}><i className="fa-solid fa-x"></i></p>
                            <p className='ps'>Reset PIN</p>
                            <p className='closebtn' onClick={()=> setShowPinModal(false)}><i className="fa-solid fa-x"></i></p>
                    </div>
                    
            </Modal.Header>
            <Modal.Body>
            <div className='loginInput pincode-input-container d-flex align-items-center justify-content-center'>
                    <form method="post" action="/form" autoComplete="new-password">

                    <div className="pincode-input-container">
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*"  autoFocus value={pin1} onChange={(e) => handlePinChange(e, 1)} onKeyDown={(e) => handleBackspace(e, 1)} ref={pin1Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin2} onChange={(e) => handlePinChange(e, 2)} onKeyDown={(e) => handleBackspace(e, 2)} ref={pin2Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin3} onChange={(e) => handlePinChange(e, 3)} onKeyDown={(e) => handleBackspace(e, 3)} ref={pin3Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin4} onChange={(e) => handlePinChange(e, 4)} onKeyDown={(e) => handleBackspace(e, 4)} ref={pin4Ref}/>
                        </div>
                        {PinNotSetMessage && (
                        <p style={{color: 'red',position: 'absolute',top: '0',transform: 'translateY(132px)',fontSize: '1.1rem', left: '0',right: '0',maxWidth: '300px',fontWeight: 500,lineHeight: '22px',
                            margin: 'auto'}}>Please set a PIN first to generate a shareable link.
                        </p>
                        )}
                     {/*    {savePinErrorMessage && (<p className='error'>Enter 4 digit PIN</p>)}     */}
                    </form>
                          
            </div>
            <div className='mt-4 d-flex justify-content-center align-items-center'>
                <div className="releaseUser saveBtn mt-2" /* style={saveButtonStyles} */ onClick={()=> handlePinResetModal()}>Save</div>    
            </div>
            </Modal.Body>
            </Modal>

            <Modal show={showConfirmPinModal} onHide={()=>setShowConfirmPinModal(false)} className='manmagexids_modal pinResetModal'>
            <Modal.Header>
                    <div className='d-flex align-items-center justify-content-between header'>
                            <p className='closebtn' onClick={()=> setShowConfirmPinModal(false)} style={{visibility:'hidden'}}><i className="fa-solid fa-x"></i></p>
                            <p className='ps' >Confirm PIN</p>
                            <p className='closebtn' onClick={()=> setShowConfirmPinModal(false)}><i className="fa-solid fa-x"></i></p>
                    </div>
                    
            </Modal.Header>
            <Modal.Body>
            <div className='loginInput pincode-input-container d-flex align-items-center justify-content-center'>
                    <form method="post" action="/form" autoComplete="new-password">

                    <div className="pincode-input-container">
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*"  autoFocus value={pin5} onChange={(e) => handleConfirmPinChange(e, 1)} onKeyDown={(e) => handleConfirmBackspace(e, 1)} ref={pin5Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin6} onChange={(e) => handleConfirmPinChange(e, 2)} onKeyDown={(e) => handleConfirmBackspace(e, 2)} ref={pin6Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin7} onChange={(e) => handleConfirmPinChange(e, 3)} onKeyDown={(e) => handleConfirmBackspace(e, 3)} ref={pin7Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin8} onChange={(e) => handleConfirmPinChange(e, 4)} onKeyDown={(e) => handleConfirmBackspace(e, 4)} ref={pin8Ref}/>
                        </div>
{/*                         {pinErrorMessage && (<p className='error'>Confirm your new PIN</p>)}
                        {confirmPinErrorMessage && (<p className='error'>Both PINs don't match</p>)} */}
                        {PinNotSetMessage && (
                        <p style={{color: 'red',position: 'absolute',top: '0',transform: 'translateY(132px)',fontSize: '1.1rem', left: '0',right: '0',maxWidth: '300px',fontWeight: 500,lineHeight: '22px',
                            margin: 'auto'}}>Please set a PIN first to generate a shareable link.
                        </p>
                        )}
                    </form>
                      
            </div>
            <div className='mt-4 d-flex justify-content-center align-items-center'>
                <div className="releaseUser saveBtn mt-2" onClick={()=> handleConfirmPinResetClick()}>Save</div>    
            </div>
            
            </Modal.Body>
            </Modal>

            <Modal show={showPasswordModal} onHide={()=>setShowPasswordModal(false)} className='manmagexids_modal pinResetModal'>
            <Modal.Header>
                    <div className='d-flex align-items-center justify-content-between header'>
                            <p className='closebtn' onClick={()=> setShowPasswordModal(false)} style={{visibility:'hidden'}}><i className="fa-solid fa-x"></i></p>
                            <p className='ps'>Set Password</p>
                            <p className='closebtn' onClick={()=> setShowPasswordModal(false)}><i className="fa-solid fa-x"></i></p>
                    </div>
                    
            </Modal.Header>
            <Modal.Body>
            <div className='loginInput pincode-input-container d-flex align-items-center justify-content-center'>
            <input type="text" className="inputField" autoFocus value={password} onChange={(e) => { 
        setPassword(e.target.value); 
        setSavePasswordErrorMessage(false); 
        }}  placeholder='Enter new password'/>
                          
            </div>
            {savePasswordErrorMessage && 
                    <p className="error pwd">Password must be at least 4 characters long</p>
            }
            <div className='mt-4 d-flex justify-content-center align-items-center'>
                <div className="releaseUser saveBtn mt-2" onClick={()=> handlePasswordSetModal()}>Save</div>  

            </div>
            </Modal.Body>
            </Modal>

            <Modal show={showConfirmPasswordModal} onHide={()=>setShowConfirmPasswordModal(false)} className='manmagexids_modal pinResetModal'>
            <Modal.Header>
                    <div className='d-flex align-items-center justify-content-between header'>
                            <p className='closebtn' onClick={()=> setShowConfirmPasswordModal(false)} style={{visibility:'hidden'}}><i className="fa-solid fa-x"></i></p>
                            <p className='ps'>Confirm Password</p>
                            <p className='closebtn' onClick={()=> setShowConfirmPasswordModal(false)}><i className="fa-solid fa-x"></i></p>
                            {/* <p className='save' onClick={()=> setShowConfirmPasswordModal(false)}>Save</p> */}
                    </div>
                    
            </Modal.Header>
            <Modal.Body>
            <div className='loginInput pincode-input-container d-flex align-items-center justify-content-center'>
                    <input type="text" className="inputField" autoFocus value={passwordConfirm} 
                    onChange={(e) => { 
                        setPasswordConfirm(e.target.value); 
                        setPasswordErrorMessage(false); 
                        setConfirmPasswordErrorMessage(false);
                        }}
                         placeholder='Confirm password'/>
                          
            </div>
            {passwordErrorMessage && 
                    <p className="error pwd">Passwords do not match</p>
                }  
                {confirmPasswordErrorMessage &&
                <p className="error pwd">Re-enter password to confirm</p>
                }
            <div className='mt-4 d-flex justify-content-center align-items-center'>
                <div className="releaseUser saveBtn mt-2" onClick={()=> handleConfirmPasswordModal()}>Save</div>  

            </div>
            </Modal.Body>
            </Modal>
            
        </>
    )
}

export default ManageXIDs;
