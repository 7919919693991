import React,{useState,useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import crypto from 'crypto';
import { getSecureState, setSecureState} from '../../services/securels.service';
import {userAllocate, userRegister} from '../../services/user.service';

import './register.css';
function Register({goToMainLogin, onLoginSuccess}) {
    const [showNameField, setShowNameField]=useState(true);
    const [showPinField, setShowPinField]=useState(false);
    const [showConfirmPinField, setShowConfirmPinField]=useState(false);
    const [showSuccessField, setShowSuccessField]=useState(false);

    const [secretValue, setSecretValue]=useState('');
    const [userNumber, setUserNumber]=useState('');

    const [finalName, setFinalName]=useState('')

    const [nameErrorMessage, setNameErrorMessage]=useState(false);
    const [limitExceedMessage, setLimitExceedMessage]=useState(false);
    const [limitExceeded, setLimitExceeded]=useState(false);
    const [pinErrorMessage, setPinErrorMessage]=useState(false);
    const [pinsDontMatch, setPinsDontMatch]=useState(false);
    const [confirmPinErrorMessage, setConfirmPinErrorMessage]=useState(false);
    const [copiedXidMessage, setCopiedXidMessage]=useState(false);

    const handleNameChange=(e:any)=>{
        const nameInputValue = e.target.value;
        setFinalName(nameInputValue);
        sessionStorage.setItem('name_businessmember', nameInputValue);
        //console.log(nameInputValue);
    }

    const goToPinUI=()=>{
        if(finalName){
            let uxid = getSecureState('uxid').value;
            let userObj = {
                Udid: uxid,
            };
            userAllocate(userObj)
            .then((res) => {
              if (res) {
                if (res.data.status === 'ok') {
                  const secret=res.data.data.secret;
                  setSecretValue(secret);
                  const uNumber=res.data.data.userNumber;
                  setUserNumber(uNumber);
                  sessionStorage.setItem('xid_businessmember', uNumber);
                  sessionStorage.setItem('xID', uNumber);
                  //console.log(uNumber);
                  
                }
                else if(res.data.data.statusCode==='limit_exceeded'){
                  //console.log('limit exceeded');
                  /* setLimitExceedMessage(true); */
                  setLimitExceeded(true);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
            setShowNameField(false);
            setShowPinField(true);
        }
        else{
            setNameErrorMessage(true);
        }
    }

    const [pin1, setPin1] = useState('');
    const [pin2, setPin2] = useState('');
    const [pin3, setPin3] = useState('');
    const [pin4, setPin4] = useState('');

    const [pin5, setPin5] = useState('');
    const [pin6, setPin6] = useState('');
    const [pin7, setPin7] = useState('');
    const [pin8, setPin8] = useState('');


    const handlePinChange = (e, pinIndex) => {
      setPinErrorMessage(false);
        const formattedPinValue = e.target.value.replace(/[^0-9]/g, ''); //prevent non numeric characters
        //focus all input fields one by one  

        switch (pinIndex) {
          case 1:
            setPin1(formattedPinValue);
            if (formattedPinValue !== '' && pin2Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin2Ref.current.focus();
            }
            break;
          case 2:
            setPin2(formattedPinValue);
            if (formattedPinValue !== '' && pin3Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin3Ref.current.focus();
            }
            break;
          case 3:
            setPin3(formattedPinValue);
            if (formattedPinValue !== '' && pin4Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin4Ref.current.focus();
            }
            break;
          case 4:
            setPin4(formattedPinValue);
            break;
          default:
            break;
        }

        
      };

      const handleConfirmPinChange = (e, pinIndex) => {
        setConfirmPinErrorMessage(false);
        setPinsDontMatch(false);
        const formattedPinValue = e.target.value.replace(/[^0-9]/g, ''); //prevent non numeric characters
        //focus all input fields one by one  

        switch (pinIndex) {
          case 1:
            setPin5(formattedPinValue);
            if (formattedPinValue !== '' && pin6Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin6Ref.current.focus();
            }
            break;
          case 2:
            setPin6(formattedPinValue);
            if (formattedPinValue !== '' && pin7Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin7Ref.current.focus();
            }
            break;
          case 3:
            setPin7(formattedPinValue);
            if (formattedPinValue !== '' && pin8Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin8Ref.current.focus();
            }
            break;
          case 4:
            setPin8(formattedPinValue);
            break;
          default:
            break;
        }

        
      };

      const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, pinIndex: number) => {
        if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 4) {
          setPin4(''); 
          // @ts-ignore: Object is possibly 'null'.
          pin3Ref.current?.focus(); // Shift focus to pin3
        } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 3) {
          setPin3(''); 
          // @ts-ignore: Object is possibly 'null'.
          pin2Ref.current?.focus(); // Shift focus to pin2
        } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 2) {
          setPin2(''); 
          // @ts-ignore: Object is possibly 'null'.
          pin1Ref.current?.focus(); 
        }
      };

      const handleConfirmBackspace = (e: React.KeyboardEvent<HTMLInputElement>, pinIndex: number) => {
        if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 4) {
          setPin8(''); 
          // @ts-ignore: Object is possibly 'null'.
          pin7Ref.current?.focus(); // Shift focus to pin3
        } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 3) {
          setPin7(''); 
          // @ts-ignore: Object is possibly 'null'.
          pin6Ref.current?.focus(); // Shift focus to pin2
        } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 2) {
          setPin6(''); 
          // @ts-ignore: Object is possibly 'null'.
          pin5Ref.current?.focus(); 
        }
      };
      const pin = `${pin1}${pin2}${pin3}${pin4}`;
      const confirmPin = `${pin5}${pin6}${pin7}${pin8}`;

      const pin1Ref = useRef(null);
      const pin2Ref = useRef(null);
      const pin3Ref = useRef(null);
      const pin4Ref = useRef(null);

      const pin5Ref = useRef(null);
      const pin6Ref = useRef(null);
      const pin7Ref = useRef(null);
      const pin8Ref = useRef(null);
      const navigate= useNavigate();
      const HandleConfirmPinClick=()=>{
        if(pin.length===4){
          setShowConfirmPinField(true);
          setShowPinField(false);
        }
        else{
          setPinErrorMessage(true);
        }
        
      }
      useEffect(() => {

        if(pin.length===4){
          setShowConfirmPinField(true);
          setShowPinField(false);
          setPinErrorMessage(false);
        }
    }, [pin]);
      const HandleRegisterClick=()=>{
          if (confirmPin){
            if (pin===confirmPin){
              if (!limitExceeded){
                setLimitExceedMessage(false);
                let uxid = getSecureState('uxid').value;
                const pinHash = crypto.createHash('sha256').update(pin).digest('hex');
        
                const xidPin = `${userNumber}_xpal_${pin}`;
                const xidPinHash = crypto.createHash('sha256').update(xidPin).digest('hex');
                sessionStorage.setItem('pin_businessmember', pinHash);
  
                setSecureState("registeredUserNumber", userNumber);
                setSecureState("registeredPassword", xidPinHash);
                setSecureState("registeredpin", pinHash);
                setSecureState("registeredDeviceUdid", uxid);
                setSecureState("registeredUdid", uxid);
  
                let userObj = {
                    Udid:uxid,
                    Pin:pinHash,
                    Password:xidPinHash ,
                    PublicKey:"",
                    OsVersion:"",
                    DeviceToken :"",
                    DeviceChannel:"",
                    DeviceModel :"",
                    BuildVersion:"",
                    UserName : finalName,
                    AllowCalls :"",
                    AllowMessages :"",
                    MessageNotificationSound :"",
                    CallNotificationSound :"",
                    Secret :secretValue,
                    xID:userNumber,
                };
                userRegister(userObj)
                .then((res) => {
                  if (res) {
                    if (res.data.status === 'ok') {
                      setShowSuccessField(true);
                      setShowConfirmPinField(false);
                    }
                    else{
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
              }
              else{
                setLimitExceedMessage(true);
                setPinErrorMessage(false);
              }
  
            }
            else{
                //setPinErrorMessage(true);
                setLimitExceedMessage(false);
                setPinsDontMatch(true);
                setConfirmPinErrorMessage(false);
            }
          }
          else{
            setConfirmPinErrorMessage(true);
            
          }

      }

      const HandleSuccessRegisterClick=()=>{
          const buttonPressed = sessionStorage.getItem('buttonPressed');
          if (buttonPressed === 'BMBtn') {
              navigate('/dashboard');
              //console.log('Navigate to dashboard');
              onLoginSuccess();
              sessionStorage.removeItem('buttonPressed');
          }
          else{
            navigate('/purchase');
          }
      }

      const handleCopyToClipboard = () => {
        setCopiedXidMessage(true);
        const formattedNumber = userNumber.replace(/\s/g, '').replace(/(\d{3})(?=\d)/g, '$1 ');
        navigator.clipboard.writeText(formattedNumber)
            .then(() => {
                //console.log('xID copied to clipboard');
            })
            .catch((error) => {
                console.error('xID could not be copied: ', error);
            });
      };

      const HandleNameBackClicked=()=>{
          goToMainLogin();
      }

      const HandlePinBackClick=()=>{
          setShowNameField(true);
          setShowPinField(false);
      }
      const HandleConfirmPinBackClick=()=>{
        
        setShowPinField(true);
        setShowConfirmPinField(false);
      }


    return (
        <>
            {showNameField && !showPinField && !showConfirmPinField && !showSuccessField &&( 
                <>
                <div className='col2 container ltr-animation'>
                    <p className='closeBtn' onClick={()=>HandleNameBackClicked()}><span className='mr-2'>&lt;</span>Back</p>
                    <div className="loginHeading mt-4">Choose Your Name</div>
                    <div className="loginText mt-2">This is the Username your friends will see.<br/>
                        You may choose your real name or any nickname you wish.<br/>
                        Can be changed later in Settings.</div>
                    <input className="inputField registerComponent text-center mt-5 mb-5" type="text" placeholder="Enter your name" autoComplete="off" onChange={(e) => handleNameChange(e)}
                    value={finalName}/>
                    <div className='loginButton mb-4 mt-5'>
                        <button className="mt-5" onClick={()=>goToPinUI()}>Next</button>
                    </div>
                    <div style={{ position: 'relative' }}>
                    {nameErrorMessage && (
                        <div className="danger" style={{ position: 'absolute', color: 'red', left: '0',right:'0', fontWeight:'500', transform: 'translateY(-5px)', marginLeft:'auto',marginRight:'auto',width:'200px' }} >
                        Enter Name to proceed
                        </div>
                    )}

                    </div>
                    <div className='mt-5 registerDiv'>
                    <p className='register mt-1' onClick={()=>HandleNameBackClicked()}>Login</p></div>
                </div>
                </>
            )}

            {!showNameField && showPinField && !showConfirmPinField && !showSuccessField &&(
                <>
                <div className='col2 container ltr-animation'>
                    <p className='closeBtn' onClick={()=>HandlePinBackClick()}><span className='mr-2'>&lt;</span>Back</p>
                    <div className="loginHeading mt-4">4-Digit PIN</div>
                    <div className="loginText mt-2">Enter your 4-Digit PIN you want to set for this account<br/>
                        </div>
                        <div className='loginInput pincode-input-container d-flex align-items-center justify-content-center'>
                    <form method="post" action="/form" autoComplete="new-password">
                      <div className="pincode-input-container">
                          <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*"  autoFocus value={pin1} onChange={(e) => handlePinChange(e, 1)} onKeyDown={(e) => handleBackspace(e, 1)} ref={pin1Ref}/>
                          <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin2} onChange={(e) => handlePinChange(e, 2)} onKeyDown={(e) => handleBackspace(e, 2)} ref={pin2Ref}/>
                          <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin3} onChange={(e) => handlePinChange(e, 3)} onKeyDown={(e) => handleBackspace(e, 3)} ref={pin3Ref}/>
                          <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin4} onChange={(e) => handlePinChange(e, 4)} onKeyDown={(e) => handleBackspace(e, 4)} ref={pin4Ref}/>
                      </div>
                    </form>
                          
                    </div>
                    <div className='loginButton mb-4 mt-5'>
                        <button className="mt-5" onClick={()=>HandleConfirmPinClick()}>Next</button>
                    </div>
                    {pinErrorMessage && (
                        <div className="danger" style={{ position: 'absolute', color: 'red', left: '0',right:'0', fontWeight:'500', transform: 'translateY(-5px)', marginLeft:'auto',marginRight:'auto',width:'200px' }} >
                        Enter PIN to register
                        </div>
                    )}
                    {limitExceedMessage && (
                    <div className="danger" style={{ position: 'absolute', color: 'red', left: '0',right:'0', fontWeight:'500', transform: 'translateY(-5px)', marginLeft:'auto',marginRight:'auto'}} >
                    You can have up to 2 free xIDs on one device
                    </div>
                    )}
                    <div className='mt-5 registerDiv'>
                    <p className='register mt-1' onClick={()=>HandleNameBackClicked()}>Login</p></div>
                </div>
                </>
            )}  

            {!showNameField && !showPinField && showConfirmPinField && !showSuccessField &&(
                <>
                <div className='col2 container ltr-animation'>
                    <p className='closeBtn' onClick={()=>HandleConfirmPinBackClick()}><span className='mr-2'>&lt;</span>Back</p>
                    <div className="loginHeading mt-4">Confirm PIN</div>
                    <div className="loginText mt-2">Re-enter your PIN to set for this account<br/>
                        </div>
                        <div className='loginInput pincode-input-container d-flex align-items-center justify-content-center'>
                    <form method="post" action="/form" autoComplete="new-password">
                      <div className="pincode-input-container">
                          <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*"  autoFocus value={pin5} onChange={(e) => handleConfirmPinChange(e, 1)} onKeyDown={(e) => handleConfirmBackspace(e, 1)} ref={pin5Ref}/>
                          <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin6} onChange={(e) => handleConfirmPinChange(e, 2)} onKeyDown={(e) => handleConfirmBackspace(e, 2)} ref={pin6Ref}/>
                          <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin7} onChange={(e) => handleConfirmPinChange(e, 3)} onKeyDown={(e) => handleConfirmBackspace(e, 3)} ref={pin7Ref}/>
                          <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin8} onChange={(e) => handleConfirmPinChange(e, 4)} onKeyDown={(e) => handleConfirmBackspace(e, 4)} ref={pin8Ref}/>
                      </div>
                    </form>
                          
                    </div>
                    <div className='loginButton mb-4 mt-5'>
                        <button className="mt-5" onClick={()=>HandleRegisterClick()}>Register</button>
                    </div>
                    {pinErrorMessage && (
                        <div className="danger" style={{ position: 'absolute', color: 'red', left: '0',right:'0', fontWeight:'500', transform: 'translateY(-5px)', marginLeft:'auto',marginRight:'auto',width:'200px' }} >
                        Enter PIN to register
                        </div>
                    )}
                    {limitExceedMessage && (
                    <div className="danger" style={{ position: 'absolute', color: 'red', left: '0',right:'0', fontWeight:'500', transform: 'translateY(-5px)', marginLeft:'auto',marginRight:'auto'}} >
                    You can have up to 2 free xIDs on one device
                    </div>
                    )}
                    {pinsDontMatch && (
                    <div className="danger" style={{ position: 'absolute', color: 'red', left: '0',right:'0', fontWeight:'500', transform: 'translateY(-5px)', marginLeft:'auto',marginRight:'auto'}} >
                    Both PINs dont match
                    </div>
                    )}
                    {confirmPinErrorMessage && (
                        <div className="danger" style={{ position: 'absolute', color: 'red', left: '0',right:'0', fontWeight:'500', transform: 'translateY(-5px)', marginLeft:'auto',marginRight:'auto',width:'200px' }} >
                        Enter PIN to confirm
                        </div>
                    )}
                    <div className='mt-5 registerDiv'>
                    <p className='register mt-1' onClick={()=>HandleNameBackClicked()}>Login</p></div>
                </div>
                </>
            )} 

            {!showNameField && !showPinField && !showConfirmPinField && showSuccessField &&( 
                <>
                <div className='col2 container ltr-animation'>
                    <p className='closeBtn' onClick={()=>HandleNameBackClicked()}><span className='mr-2'>&lt;</span>Back</p>
                    <div className="loginHeading mt-4">Successfully Registered</div>
                    <div className="loginText mt-2">Use this xID, this will be the Master xID <br/>
                      to manage other xIDs.<br/>
                    </div>

                    <div style={{position: 'relative'}} className='d-flex justify-content-center align-items-center'>
                    <input className="inputField text-center mt-5 mb-5" type="text" placeholder="Enter your xID" maxLength={11} autoComplete="off" value={userNumber.replace(/\s/g, '').replace(/(\d{3})(?=\d)/g, '$1 ')} style={{width: '45%'}} readOnly/>
                      <div style={{position: 'relative'}} onClick={()=>handleCopyToClipboard()}><i className="fa-regular fa-copy register"></i></div>
                    </div>
                    {copiedXidMessage && (
                        <div className="danger" style={{ position: 'absolute', color: '#28a745', left: '0',right:'0', fontWeight:'500', marginLeft:'auto',marginRight:'auto',width:'200px' }} >
                        xID copied
                        </div>
                    )}
                    <div className='loginButton mb-4 mt-5'>
                        <button className="mt-5" onClick={()=>HandleSuccessRegisterClick()}>Next</button>
                    </div>
                    <div className='mt-5 registerDiv'>
                    <p className='register mt-1' onClick={()=>HandleNameBackClicked()}>Login With Another Account</p></div>
                </div>
                </>
            )}
        </>
        
    )
}
export default Register