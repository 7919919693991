import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import Cards from '../../assets/Cards.png';
import cryptoIcon from '../../assets/cryptoIcon.png'
import Header from '../Header';
import Footer from '../Footer';

import Visa from '../../assets/visaImg.png';
import MasterCard from '../../assets/masterCard.png';

import '../../Components/components.css';
import '../../Components/landingpage.css';
import '../../Components/purchaseV2.css';

import { stripePayment, bitpayPayment } from '../../services/user.service';
import {coinbaseConfig, stripeConfig,coinbasePurchaseType} from "../../services/constants.service";

import { apiXpal } from "../../services/constants.service";
import { loadStripe } from "@stripe/stripe-js";
import {CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, Elements, CardElement,
  } from "@stripe/react-stripe-js";

  import PaymentFormDashboard from './PaymentFormDashboard';
  import crypto from 'crypto';
  import { cbdemo} from "../../services/user.service";
  
function PurchaseDashboard({newValue, newTotalCost, backToDahboard}) {

    const stripePromise = loadStripe(apiXpal.stripeKey);
    const xIDFormat= sessionStorage.getItem('xIDFormat');
    const billlingCycle = sessionStorage.getItem('billingCycle');
    //const costPerUser = sessionStorage.getItem('costPerUser');
    const costPerUser = localStorage.getItem('costPerUser');
    
    //const stripeObject = loadStripe(apiXpal.stripeKey);
    const stripeObject = loadStripe("pk_test_51LFx4JF1SIVBSMvIiQmcLaJqQkmWpPi0EPxrVdMyslzvPbh9Hu77WZTnbBp9p46zXlJa2MRt8HpZsT6jVNraOmjw00ZirV6oRo");

    const xID = sessionStorage.getItem('xID');

    const addSpacesToXID = (xid) => {
        return xid.replace(/\d{3}(?=\d)/g, '$& ');
      }

    const [checkoutId, setCheckoutId] = useState('');
    const [perMonth, setPerMonth]=useState(true);

    const [waitMessage, setWaitMessage]=useState(false);
    const [paymentProcessingLoader, setPaymentProcessingLoader]=useState(false);

    const [productID, setProductID]=useState("");
    const [stripeProductID, setStripeProductId]=useState("");
    const [ProdScheme, setProdScheme]=useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [SchemeType, setSchemeType]=useState("xIDListSubscription:purchased");

    const [bitPayLoader, setBitPayLoader] = useState(false);
    const [bitPayInvoiceId, setBitPayInvoiceId] = useState("");

    const MonthlyStripe = stripeConfig.Monthly;
    const YearlyStripe = stripeConfig.Yearly;

    useEffect(() => {
        //console.log('billing cycle', billlingCycle);
        if(billlingCycle==='Yearly'){
            setPerMonth(false); 
            //console.log('yearly selected')
            const ProdScheme=`xpal_prod_${YearlyStripe}`;
            const hashedProdScheme = crypto.createHash('sha256').update(ProdScheme).digest('hex');
            setProdScheme(hashedProdScheme);
            setStripeProductId(stripeConfig.Yearly);
            setProductID(coinbaseConfig.yearlyCheckoutId);
        }
        else if(billlingCycle==='Monthly'){
            setPerMonth(true);     

            const ProdScheme=`xpal_prod_${MonthlyStripe}`;
            const hashedProdScheme = crypto.createHash('sha256').update(ProdScheme).digest('hex');
            setProdScheme(hashedProdScheme);
            setStripeProductId(stripeConfig.Monthly);
            setProductID(coinbaseConfig.monthlyCheckoutId);
        } 
    }, [billlingCycle, setPerMonth]);


    const navigate= useNavigate();

    const handleTempCB = () => {
        setWaitMessage(true);
        let userObj = {
            xIDSequence: 'random',
            xIDCount: newValue || 10,
            ProductId: "8e84fb37-3c4b-4c75-b57c-7f56a047f470"
        };
        
        // Call the temp coinbase API
        cbdemo(userObj)
            .then((res) => {
                if (res.data.status === 'ok') {
                    backToDahboard();
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const payMoney = async () => {     
/*         setHideStripeBtn(true); 
        setShowWaitMessage(true); */
        setPaymentProcessingLoader(true);
        let userObj = {
          Sequence: xIDFormat,
          Count: newValue,
          xKey: stripeProductID,
          Scheme: ProdScheme,
          SchemeType: SchemeType,

      };
      console.log(xIDFormat, newValue, stripeProductID, ProdScheme)
      stripePayment(userObj)
          .then((res) => {
            if (res) {
              if (res.data.status === 'ok') {
                setClientSecret(res.data.data);
              }
              else{
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      const bitpay = async()=>{
        setBitPayLoader(true);
        let userObj = {
          Sequence: xIDFormat,
          Count: newValue,
          xKey: stripeProductID,
          Scheme: ProdScheme,
          /* SchemeType: SchemeType, */
    
      };
      bitpayPayment(userObj)
        .then((res) => {
          if (res) {
            if (res.data.status === 'ok') {
              
              //setClientSecret(res.data.data);
              if(res.data.data.url){
                setBitPayLoader(false);
                window.open(res.data.data.url, '_blank');
              }
             const invoiceId= res.data.data.invoiceId;
             localStorage.setItem('invoiceId', invoiceId);
             setBitPayInvoiceId(invoiceId);
            // setBitpayInvoice(invoiceId);
            // setBitpayUrl(res.data.data.url);
            }
            else{
              setBitPayLoader(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    
      }      

      const appearance = {
        theme: 'night',
      };

    const options = {
    clientSecret,
    appearance
    }; 

  return (
    <>
     
     <div className='d-flex justify-content-between align-items-center container purchaseContainerDiv'>
        <div className='col-md-6 ltr-animation'>

            <div className="grid-container mt-5 dashPurchase">
                <p className='grid-item xtralarge dashPurchase'>Checkout</p>
                <p className='grid-item'><span style={{visibility:'hidden'}}>Checkout</span></p>
                <p className='grid-item'>Master xID:</p>
                <p className='grid-item large'>{addSpacesToXID(xID)}</p>
                <p className='grid-item white'>Total Number of xIDs:</p>
                <p className='grid-item large white'>{newValue || 10}</p>
                <p className='grid-item white'>Cost per user: </p>
                <p className='grid-item large white'>${costPerUser}</p>
                <p className='grid-item white'>xID Format:</p>
                <p className='grid-item large white'>{xIDFormat || 'Randomly'}</p>
                <p className='grid-item white'>Payment Schedule:</p>
                <p className='grid-item large white'>{billlingCycle || 'Monthly'}</p>
            </div>
           <div className='totalAmountContainer d-flex justify-content-between align-items-center mt-3 dashPurchase'>
            <p>Total: </p>
            <p className='large'>${newTotalCost}</p>
            {perMonth ? (
                <p className='per'>PER MONTH</p>
            ):
            (
                <p className='per'>PER YEAR</p>
            )}
            </div> 
        </div>
        <div className='col-md-6 d-flex justify-content-center align-items-center flex-column borderSolid dashPurchase'>
{/*         <div className='d-flex justify-content-start' style={{width: '100%', paddingLeft: '27px'}}>
            <img src={Visa} className='mb-4 mr-1 cardsImg'/>
            <img src={MasterCard} className='mb-4 cardsImg'/>
            </div> */}
 
            {(() => {            
                const metadata = {
                /* "Name": null,
                "Email": null, */
                "number": xID,
/*                 "xIDCount": value || 10,
                "recursion": recursionValue || 'Monthly',
                "xIDSequence": sequenceValue || 'Random', */
                "purchaseType":coinbasePurchaseType.xIDListSubscription, 
                /* "checkoutId":{checkoutId} || coinbaseConfig.monthlyCheckoutId,  */
                "productId": checkoutId || coinbaseConfig.monthlyCheckoutId,

            }; 

            let stringifyMetaData = JSON.stringify(metadata);
           // console.log("metadata",stringifyMetaData);


            return (
                <>
                <div className='topBorder dashPurchase'>

                {!clientSecret &&<div className='pay dashboard' onClick={() => payMoney()}>Buy with card{paymentProcessingLoader && (<span class="stripeBtnLoader" id="stripeBtnLoader"></span>)}</div>}

                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                    <PaymentFormDashboard/>
                    </Elements>
                )} 

                {/* <span className="separator">or</span> */}
{/*                 <CoinbaseCommerceButton 
                checkoutId={checkoutId || coinbaseConfig.monthlyCheckoutId} 
                customMetadata={stringifyMetaData}  
                wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop:'40px'
                    }}
                    style={{
                        pointerEvents: 'auto',
                        fontSize: '20px',
                        fontWeight: '600',
                        padding: '13px 30px',
                        borderRadius: '30px',
                        backgroundColor: '#F4F6FE',
                        color: '#263274',
                        border: '2px solid #263274',
                        outline:'0',
                        marginBottom:'3rem',
                        }}>
                                                                
                <div className='mainCryptoBtn' id='crypoBtn'><img src={cryptoIcon} style={{width:'16px'}} className='mr-2'/>Buy With Crypto
                </div>
                </CoinbaseCommerceButton>  */}
                    <div onClick={() => handleTempCB()} className='buyWithCrypto dashboard mt-4'><img src={cryptoIcon} style={{width:'16px'}} className='mr-2'/>Buy with crypto</div>
                    </div>
                    {waitMessage && (<p style={{position:'absolute',color:'cyan',bottom:'0',transform:'translateY(10px)',fontSize:'14px', right: '10%'}}>Please Wait..</p>)}
                    <div className='pay' onClick={() => bitpay()}>Pay with BitPay {bitPayLoader && (<span class="stripeBtnLoader" id="stripeBtnLoader"></span>)}</div> 
                    
                </>
            );
        })()}
            
        </div>
        
     </div>
    </>
  )
}

export default PurchaseDashboard
