import React, { useState, useEffect } from 'react';
import { refXIDs, createTeam, getTeam, updateTeam } from "../../services/user.service";
import { Modal } from 'react-bootstrap';
import { Circle } from 'better-react-spinkit';
import "./teams.css";

interface Item {
    xid: string;
    userName: string;
    pset: boolean;
}

interface Team {
    teamId: string;
    teamName: string;
    members: string;
    createDate: string;
}

function Teams() {
    const [teamsModal, setTeamsModal] = useState(false);
    const [teamsCreateModal, setTeamsCreateModal] = useState(false);
    const [page, setPage] = useState();
    const [size, setSize] = useState();
    const [APIData, setAPIData] = useState<Item[]>([]);
    const [selectedXIDs, setSelectedXIDs] = useState<string[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [teamName, setTeamName] = useState('');
    const [teamsUI, setTeamsUI] = useState(false);
    const [defaultTeamsUI, setDefaultTeamsUI] = useState(false);
    const [teamExists, setTeamExists] = useState(false);
    const [loading, setLoading] = useState(true);
    const [teamsEditModal, setTeamsEditModal]=useState(false);
    const [toBeRemovedXIDs, setToBeRemovedXIDs] = useState<string[]>([]); 
    const [currentTeamId, setCurrentTeamId] = useState<string | null>(null);
    const [loadingTeamId, setLoadingTeamId] = useState<string | null>(null);

    const [NoPinSet, setNoPinSet]=useState(false);


    useEffect(() => {
        refXIDs(page, size).then(res => {
            if (res.data.status === 'ok') {
                const allPsetFalse = res.data.data.every(item => item.pset === false);
                if(!allPsetFalse)
                {
                    setNoPinSet(false);
                    setAPIData(res.data.data as Item[]);
                }
                else{
                    setNoPinSet(true);
                    console.log('no pin is set for any xID');
                }
                
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
        });

        getTeam(0, 50).then(res => {
            if (res.data.status === 'ok') {
                setLoading(false);
                if(res.data.data.length>0){
                    console.log('teams are already created');
                    //setTeamExists(true);
                    setTeams(res.data.data);
                    setTeamsUI(true);
                    setDefaultTeamsUI(false);
                }
                else{
                    setDefaultTeamsUI(true);
                    console.log('teams are not already created');
                }
            }
        }).catch(error => {
            console.error('Error fetching teams:', error);
        });
    }, []);

    const handleTeamName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTeamName(e.target.value);
    }

    const handleTeamsCreateModal = () => {

        setTeamsModal(false);
        setLoading(true);
        setDefaultTeamsUI(false);
        setTeamsUI(false);
        let userObj = {
            TeamName: teamName,
            TeamList: selectedXIDs.toString(),
        };

        createTeam(userObj).then(res => {
            if (res.data.status === 'ok') {
                getTeam(0, 50).then(res => {
                    if (res.data.status === 'ok') {
                        setTeams(res.data.data);
                        setLoading(false);
                        setTeamsUI(true);
                    }
                }).catch(error => {
                    console.error('Error fetching teams:', error);
                });
            }
        }).catch(error => {
            console.error('Error creating team:', error);
        });
    }

    const handleCheckboxChange = (xid: string) => {
        setSelectedXIDs(prev => {
            if (prev.includes(xid)) {
                return prev.filter(item => item !== xid);
            } else {
                return [...prev, xid];
            }
        });
    };

    const handleEditCheckboxChange = (xid: string) => {
        setToBeRemovedXIDs(prev => {
            if (prev.includes(xid)) {
                return prev.filter(item => item !== xid);
            } else {
                return [...prev, xid];
            }
        });
    };


    const formatXID = (xid: string) => {
        return xid.replace(/(.{3})/g, '$1 ').trim();
    };

    const handleEditClick = (teamId: string,memberXIDs: string[]) => {
        setCurrentTeamId(teamId);
        setTeamsEditModal(true); // Open the modal
        setToBeRemovedXIDs([]); 
    };
    
    const handleTeamsEditSaveModal = () => {
        setTeamsEditModal(false);
        setLoadingTeamId(currentTeamId);
        let userObj = {
            TeamId: currentTeamId,
            TeamList: toBeRemovedXIDs.toString(),

          };
          updateTeam(userObj).then(res => {
            if (res.data.status === 'ok') {
                getTeam(0, 50).then(res => {
                    if (res.data.status === 'ok') {
                            setLoading(false);
                            setLoadingTeamId(null);
                            setTeams(res.data.data);
                            setTeamsUI(true);
                            setDefaultTeamsUI(false);
                    }
                }).catch(error => {
                    console.error('Error fetching teams:', error);
                });
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
        }); 

    }
    const handleNewTeamsModal = () => {
        setSelectedXIDs([]); 
        setTeamName(''); 
        
        if(NoPinSet){
            setDefaultTeamsUI(true);
        }
        else{
            setTeamsCreateModal(true);
        }
    }

    const handleOpenTeamNameModal = () =>{
        //setTeamsModal(true);
        setDefaultTeamsUI(false);
        setTeamsCreateModal(true);
    }

    const handleBackToDefaultUI = () =>{
        //setTeamsModal(true);
        if(teamsUI){
            setTeamsCreateModal(false);
            setTeamsModal(false);
        }
        else{
            setDefaultTeamsUI(true);
            setTeamsCreateModal(false);
            setTeamsModal(false);
        }

    }
    const handleSelectTeamMembers =()=>{
        if(teamName.length>=3){
            setTeamsCreateModal(false);
            setTeamsModal(true);
        }

    }
    return (
        <>
            <div className='col-md-10 mangeXIDs secondCol'>
                <div className='xidsTitle'>
                    <i className="fa-solid fa-arrow-right mr-2"></i>Team
                </div>
                <div className='manageText mt-4 mb-4'>
                    Manage Teams
                    {teamsUI && (<p style={{position:'absolute', marginBottom:'0',right:'0',bottom:'0', backgroundColor: '#0489fe',padding: '10px 17px',borderRadius: '8px',cursor: 'pointer',fontSize: '16px', pointerEvents: NoPinSet ? 'none' : 'unset'}} onClick={() => handleNewTeamsModal()}>Create New Team</p>)}
                </div>

                {loading && (
                    <div className="loading-spinner" style={{
                        display: 'flex',justifyContent: 'center',alignItems: 'center',height: '77vh',backgroundColor: '#141414',borderRadius: '35px'}}>
                            <Circle size={100} color="white" />
                     </div>
                )}

                {defaultTeamsUI && (
                    <div className='d-flex justify-content-center align-items-center teamWrapper'>
                        <div className='defaultUI teamsModal' style={{backgroundColor: NoPinSet ? '#3232324f' : '#323232'}}>
                        <div className="modal-header">
                            <div className="d-flex align-items-center justify-content-center teamsModalHeader">
                                <div style={{opacity: NoPinSet ? '0.5' : '1'}}>Create Teams</div>
                            </div>
                        </div>
                        <div className='modal-body d-flex flex-column justify-content-center align-items-center'>
                            <p style={{opacity: NoPinSet ? '0.5' : '1'}}>Create teams so that you can communicate with them e.g. Marketing, Development, Business</p>
                            <div className='d-flex justify-content-center align-items-center mt-4'>
                                <div className='teamDiv' onClick={() => handleOpenTeamNameModal()} style={{ pointerEvents: NoPinSet ? 'none' : 'auto',  opacity: NoPinSet ? '0.5' : '1',}}>Create Team</div>
                              {NoPinSet &&(<p style={{position: 'absolute',color: 'red',bottom: '0',transform: 'translateY(100px)'}}>Please set PINs of xIDs which you want to add in teams</p>)}  
                            </div>
                        </div>
                        </div>
                       
                    </div>

                    
/*                     <Modal show={defaultTeamsUI} className='teamsModal defaultsTeamsModal'>
                    <Modal.Header>
                        <div className='d-flex align-items-center justify-content-center teamsModalHeader'>
                            <div>Create Team</div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='teamsModalBody'>
                        <div className='d-flex justify-content-center align-items-center textWrapper'>
                            <p>Create teams so that you can communicate with them e.g. Marketing, Development, Business</p>
                        </div>
                            <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                            <div className='teamDiv' onClick={() => handleOpenTeamNameModal()}>Create Team</div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */
                
                )}

                <Modal show={teamsCreateModal} onHide={() => handleBackToDefaultUI()} className='teamsModal'>
                    <Modal.Header>
                        <div className='d-flex align-items-center justify-content-center teamsModalHeader'>
                            <div>Create Team</div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='teamsModalBody'>
                            <div className='d-flex flex-column justify-content-between align-items-center'>
                                <input
                                    placeholder='e.g. marketing'
                                    className='mt-3 mb-2 nameInput'
                                    onChange={handleTeamName}
                                    value={teamName} 
                                />
                                <p className='mb-4' style={{width: '90%',fontSize: '13px',opacity: '0.8'}}>Teams are where conversations happen around a topic. Use a name that is easy to find and understand.</p>
                                <div className='teamDiv'  style={{
                                    opacity: teamName.length < 3 ? 0.5 : 1,
                                    pointerEvents: teamName.length < 3 ? 'none' : 'auto',
                                }}   onClick={() => handleSelectTeamMembers() }>Next</div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={teamsModal} onHide={() => handleBackToDefaultUI()} className='teamsModal selectxids'>
                    <Modal.Header>
                        <div className='d-flex align-items-center justify-content-center teamsModalHeader'>
                            <div>Select Team Members</div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='teamsModalBody'>
                        <div className='selectedXIDsWrapper d-flex justify-content-between align-items-start'>
                                <div className='xIDsListWrapper'>
                                    {/* <div>xIDs List</div> */}
                                    {(APIData ?? []).filter(item => item.pset).map((item, index) => (
                                        <div key={index} className='d-flex justify-content-start align-items-center xIDsList custom-checkbox-wrapper'>
                                            <input
                                                type="checkbox"
                                                id={`item-${index}`}
                                                className="custom-checkbox"
                                                checked={selectedXIDs.includes(item.xid)}
                                                onChange={() => handleCheckboxChange(item.xid)}
                                            />
                                            <label htmlFor={`item-${index}`}>{item.userName ? item.userName : 'null'} - {formatXID(item.xid)}</label>
                                        </div>
                                    ))}
                                </div>

{/*                                 <div className='selectedxIDsListWrapper'>
                                    <div>Selected xIDs</div>
                                    {selectedXIDs.map((xid, index) => (
                                        <a key={index}>
                                            {formatXID(xid)}
                                        </a>
                                    ))}
                                </div> */}
                            </div>
                            <div className='d-flex flex-column justify-content-between align-items-center mt-4'>
                                <div className='teamDiv' onClick={handleTeamsCreateModal}   style={{
                                    opacity: selectedXIDs.length < 2 ? 0.5 : 1,
                                    pointerEvents: selectedXIDs.length < 2 ? 'none' : 'auto',
                                }}>Finish</div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {teamsUI && (
                <div className='teamsContainer'>
                    {teams.map((team, index) => {
                        const { teamId, teamName, members } = team;
                        const memberXIDs = members.split(',');

                        return (
                            <div className='teamItem' key={team.teamId}>
                                {loadingTeamId === teamId ? (
                                <div className="loading-spinner" style={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%'}}>
                                    <Circle size={30} color="white" />
                                </div>
                                ):
                                (
                                <div>

                                        <div className='d-flex justify-content-between align-items-center top-sec'>
                                            
                                            <div className='teamName'>Team{teamName}</div>
                                            <span className='editSpan' onClick={() => handleEditClick(teamId, memberXIDs)} >
                                                <img src="https://dz5tyoxl8h44g.cloudfront.net/biz-edit.png" alt="edit" style={{width: '18px'}}/>
                                            </span>
                                        </div>
                                        
                                    
{/*                                         <div className='teamMembers'>
                                            {memberXIDs.map((xid, index) => (
                                                <p key={index}>
                                                    {formatXID(xid)}
                                                </p>
                                            ))}
                                        </div> */}
                              </div>    
                              )}
  
                            </div>
                        );
                    })}
                </div>
                )}

                <Modal show={teamsEditModal} onHide={() => setTeamsEditModal(false)} className='teamsModal selectxids'>
                    <Modal.Header>
                        <div className='d-flex align-items-center justify-content-center teamsModalHeader'>
                            <div>Update xIDs</div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                    <div className='teamsModalBody'>
                        <div className='selectedXIDsWrapper d-flex flex-column'>
                        <div className='xIDsListWrapper'>
                            {(APIData ?? []).filter(item => item.pset).map((item, index) => (
                                <div key={index} className='d-flex justify-content-start align-items-center xIDsList custom-checkbox-wrapper'>
                                    <input
                                        type="checkbox"
                                        id={`edit-item-${index}`}
                                        className="custom-checkbox"
                                        checked={toBeRemovedXIDs.includes(item.xid)}
                                        onChange={() => handleEditCheckboxChange(item.xid)}
                                    />
                                    <label htmlFor={`edit-item-${index}`}>{item.userName ? item.userName : 'null'} - {formatXID(item.xid)}</label>
                                </div>
                            ))}
                            </div>
                        </div>

                        <div className='d-flex flex-column justify-content-between align-items-center mt-4'>
                            <div className='teamDiv' onClick={() => handleTeamsEditSaveModal()}   style={{
                                opacity: toBeRemovedXIDs.length < 2 ? 0.5 : 1,
                                pointerEvents: toBeRemovedXIDs.length < 2 ? 'none' : 'auto',
                            }}>Save</div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>



            </div>
        </>
    );
}

export default Teams;
