import { getSecureState } from "./securels.service";

let env = {
  iceServers: [],
};
export let constants = {
  currentUser: getSecureState("username").value,
  anonymousTrialLeft: 0,
};
let EScoketEvents = {
  REQUEST: "request",
  CALL: "call",
  ONCONFIGCHANGE: "onconfigchange",
  END: "end",
  CALLFAILED: "callFailed",
  INIT: "init",
  ONLINE: "online",
  OFFLINE: "offline",
  // Zeeshan Work
  TYPING: "typing",
  JOINFRIENDSROOM: "joinFriendsRoom",
  REALTIMEPRESENCE: "realTimePresence",
  LISTENREALTIMEUSERUPDATE: "listenRealTimeUserUpdate",
  UPDATEUSERINFO: "updateUserInfo",
  convoConfig: "convoConfig",
  chatConfig: "chatConfig",
  GET_TOKEN: "getToken",
  UPDATEFRIENDINFO: "updateFriendInfo",
  CHATRECREATECONFIG: "chatRecreateConfig",
  DEVICECHANGED: "deviceChanged",
  OFFLINEWEB: "offlineWeb",
  UPDATEUSERREALTIMEINFO: "updateUserRealTimeInfo",
  FRIEND_ACTION_CHANGED: "friendActionChanged",
  USER_ACTION_CHANGED: "userActionChanged",
  DELETE_SINGLE_MESSAGE: "deleteSingleMessageV2",
  EMIT_KEYCHANGE_TO_ALL_FRIENDS: "emitKeyChangeToAllFriends",
};

let EChatConfigType = {
  CREATE: "create",
  SPAM_MESSAGE: "spam_message",
  BUBBLE_MSG_SEEN: "bubbleMessageSeen",
  DELETE_MSG: "deleteMessages",
  DELETE_MESSAGE_ARRAY: "delete_message_arrayv2",
  ALL_MSGS_RECEIVED: "all_messagess_received",
  //MESSAGE_NOT_DELIVERED: "message_not_delivered",
  MESSAGE_NOT_DELIVERED: "messageNotDelivered",
};

let EUPDATEUSERINFO = {
  PROFILE_IMAGE: "PROFILE_IMAGE",
  PuBLIC_KEY: "PuBLIC_KEY",
  FRIEND_UPDATED: "FRIEND_UPDATED",
  changeBlockStatus: "CHANGE_BLOCK_STATUS",
  ALLOW_COMMUNICATION: "allow_communication",
  User_Name: "User_Name",
};

let EMsgSendTypes = {
  normal: "normal",
  bubble: "bubble",
  flash: "flash",
};

let AnonymousName = {
  "0": "Anonymous Ernesh",
  "1": "Anonymous Mercurius",
  "2": "Anonymous Cessair",
  "3": "Anonymous Spider-Man",
  "4": "Anonymous Thor",
  "5": "Anonymous Wolverine",
  "6": "Anonymous Apocalypse",
  "7": "Anonymous Belasco",
  "8": "Anonymous Knight",
  "9": "Anonymous Phoenix",
  "10": "Anonymous Voodoo",
  "11": "Anonymous Aquaman",
  "12": "Anonymous Asterix",
  "13": "Anonymous Defenders",
  "14": "Anonymous Elektra",
  "15": "Anonymous Ghost Rider",
  "16": "Anonymous Robin",
  "17": "Anonymous Guardian",
  "18": "Anonymous Zatanna",
  "19": "Anonymous Zatara",
  "20": "Anonymous Turtle",
};

let EMsgTypes = {
  text: "text",
  video: "video",
  image: "image",
  banner: "banner",
  voice: "voice",
  location: "location",
  share: "share",
  videoCall: "videoCall",
  missedCall: "missedCall",
  acceptedCall: "acceptedCall",
  voiceCall: "voiceCall",
  redEnvelope: "redEnvelope",
  file: "file",
  pat: "pat",
  custom: "custom",
  system: "system",
  quotaBanner: "quotaBanner",
  multiImages: "multiImages",
  timeBanner: "timeBanner",
  screenShot: "screenShot",
  screenRecording: "screenRecording",
  keyChanged: "keyChanged",
  shareContact: "shareContact",
  delivery: "delivery",
  friendAction: "friendAction",
  userAction: "userAction",
  userPrivacy: "userPrivacy",
  added: "added",
  removed: "removed",
  unblock: "unblock",
  block: "block",
  forward: "forward",
  reversePinTriggered: "reversePinTriggered",
  deleteMessages: "deleteMessages",
};

let apiUrls = {
  prod: {
    transporter: "https://xtransporter.xpal.com/",
    core: "https://api.734a0649-6f36-4983-94fb-f6e103ad9e44.com/api/",

    corev2: "https://web.734a0649-6f36-4983-94fb-f6e103ad9e44.com/api/",
    branchIO: "key_live_dg2ci4ZTGzKaeohvqaN5IklnyzdpEdVH",
    xpalLink: "https://web.xpal.com",
    stripeKey:
      "pk_test_51JhwZPDjUtWR0ZDvEyNhb5WaVqYEx7b2qkNDqpDNPznEEPyivxHquTHS3LPPnLNbbbFhCfBSWqCPnq7x1QjtHDWJ000gWvsbST",
  },
  dev: {
    transporter: "https://xt.xpaldev.com/",

    corev2:"https://bizapi.xpaldev.com/api/",
    branchIO: "key_test_pa8cj3XHPxVphcjytaXuebolzwfoAgU8",
    xpalLink: "https://xpal-dev.web.app",
    stripeKey:
      "pk_test_51JhwZPDjUtWR0ZDvEyNhb5WaVqYEx7b2qkNDqpDNPznEEPyivxHquTHS3LPPnLNbbbFhCfBSWqCPnq7x1QjtHDWJ000gWvsbST",
  },


  staging: {
    transporter: "https://staging.xtransporter.xpal.com/",
    core: "https://staging.api.xpal.com/api/",
    corev2: "https://staging.api.xpal.com/api/",
    branchIO: "key_live_dg2ci4ZTGzKaeohvqaN5IklnyzdpEdVH",
    xpalLink: "https://staging-xpal.web.app",
  },

  cf: {
    transporter: "https://xtransporter.xpal.com/",
    core: "https://734a0649-6f36-4983-94fb-f6e103ad9e44.com/api/",
    corev2: "https://734a0649-6f36-4983-94fb-f6e103ad9e44.com/v4/api/",
    branchIO: "key_live_dg2ci4ZTGzKaeohvqaN5IklnyzdpEdVH",
    xpalLink: "https://xpaldevcf.web.app",
  },
};

let apiXpal = apiUrls.dev;

let EMsgAccess = {
  FRIENDS: "FRIENDS",
  ALLOW_CONTACT_REQ: "ALLOW_CONTACT_REQ",
  ALLOW_EVERYONE: "ALLOW_EVERYONE",
};

let EMsgStatus = {
  sending: "Sending",
  sent: "Sent",
  delivered: "Delivered",
  failed: "Failed",
  missed: "missed",
  complete: "complete",
};


//Coinbase production
//uncommented by default
let coinbaseConfig = {
  apiUrl: "https://api.commerce.coinbase.com/",
  apiKey: "8cdbf646-05e5-47e7-8fe3-b70afe15acf4",
  monthlyCheckoutId: "7db8b7f8-d21d-4ff4-a157-f941f8638b15",
  yearlyCheckoutId: "8e84fb37-3c4b-4c75-b57c-7f56a047f470",
  premiumProCheckoutId: "",
  premiumCheckoutId: "",
  platinumCheckoutId: "",
  goldCheckoutId: "",
  silverCheckoutId: "",
  blueCheckoutId: "",
  /*new changings*/
  MonthlyProductId: "8e84fb37-3c4b-4c75-b57c-7f56a047f470",
  YearlyProductId: "8e84fb37-3c4b-4c75-b57c-7f56a047f470",

}; 

let stripeConfig = {
    Monthly: "L4RxBJsMmzYs37",
    Yearly: "L4S8059CB693N2",

}

let coinbasePurchaseType = {
  subscriptionPurchase: "subscription:purchased",
  numberPurchase: "number:purchased",
  /* xIDListSubscription:"purchased", */
  xIDListSubscription:"xIDListSubscription:purchased",

};

let EReportUserReason = [
  {
    type: "reason",
    reason: "This person is annoying me",
  },
  {
    type: "reason",
    reason: "They are pretending to be me or someone I know",
  },
  {
    type: "reason",
    reason: "This is a fake account",
  },
  {
    type: "reason",
    reason: "This person is spamming me",
  },
  {
    type: "other_reason",
    reason: "Any other reason",
  },
];

let SIGNUP_LINK_KEY = "XPAL_LINK";

export let loadedChats = {
  convo: [],
  spamConvo: [],
  allConvo: [],
};

export let userfriends = {
  allfriends: {},
  onlyfriends: [],
};

export let activeChat = {
  isActive: false,
  currentChatId: null,
};

export let allchatsbykeyvalue = {
  allchats: {},
};
//export let loadedChats:any = [];
export {
  env,
  EScoketEvents,
  EMsgSendTypes,
  EMsgTypes,
  apiXpal,
  EMsgAccess,
  EMsgStatus,
  EReportUserReason,
  EUPDATEUSERINFO,
  coinbaseConfig,
  stripeConfig,
  EChatConfigType,
  coinbasePurchaseType,
  SIGNUP_LINK_KEY,
  AnonymousName,
};
