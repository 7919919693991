import React from 'react'


/* import {Chart, ArcElement} from 'chart.js'; */
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
function DonutChart({dashAPIData}) {
  
  const androidUsers = dashAPIData.android;
  const iosUsers = dashAPIData.ios;
  //console.log(androidUsers);
  //console.log(iosUsers);
      // Dummy data
        const data = {
            labels: ['Android     ', 'iOS'],
            datasets: [
            {
              //data: [2, 6],
              //backgroundColor: ['#7AA7FA', 'white']
              data: androidUsers === 0 && iosUsers === 0 ? [1] : [androidUsers, iosUsers],
              backgroundColor: androidUsers === 0 && iosUsers === 0 ? ['#5B5B5B'] : ['#7AA7FA', 'white'],
              
              
            },
            ],
        };
        const options = {
          cutout: '65%',
          borderWidth: 0,
          plugins: {
              legend: {
                  labels: {
                      usePointStyle: true,
                  },
              },
              tooltip: {
                  enabled: androidUsers === 0 && iosUsers === 0 ? false : true, 
              }
          },
      };
        return (
        <>
        <div className='mt-5 DougnutCanvasContainer d-flex justify-content-end' style={{width:'13vw',height:'26vh'}}>
          <Doughnut data={data} options={options}/>
        </div>
        </>
        )
        }

export default DonutChart
