import React from 'react'
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

  
function BarChart({dashAPIData}) {
  const androidData = [dashAPIData.android, dashAPIData.android, dashAPIData.android, dashAPIData.android, dashAPIData.android, dashAPIData.android, dashAPIData.android];
  const iosData = [dashAPIData.ios, dashAPIData.ios, dashAPIData.ios, dashAPIData.ios, dashAPIData.ios, dashAPIData.ios, dashAPIData.ios];

  const isAndroidEmpty = androidData.every(value => value === 0);
  const isIOSEmpty = iosData.every(value => value === 0);
  const data = {
    labels: ['Mon', 'Tues', 'Wed', 'Thur', 'Fri','Sat','Sun'],
    datasets: [
      {
        label: 'Android     ',
        data: androidData,
       //data:[3,3,3,3,3,3,3],
        //data: isAndroidEmpty ? [0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01] : androidData,
        //backgroundColor: '#7AA7FA',
        backgroundColor: isAndroidEmpty ? ['#5B5B5B'] : ['#7AA7FA'],
        barThickness: 9, //decrease thickness of bars
        borderRadius:17,
        borderSkipped:false,
        //minBarLength:4,

/*         borderColor:'white',
        borderWidth:0,
        borderRadius:{
          topRight: 10,
          topLeft:10,
          bottomRight: 10,
          bottomLeft:10,
        },
        borderSkipped:true,
        barPercentage:0.25,
        categoryPercentage:0.5, */
        
      },
      {
        label: 'iOS ',
        //data: isIOSEmpty ? [0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01] : iosData,
        data: iosData,
        //data:[3,3,3,3,3,3,3],
        //backgroundColor: 'white', 
        backgroundColor: isAndroidEmpty ? ['#5B5B5B'] : ['white'],
        barThickness: 9, //decrease thickness of bars
        borderRadius: 17,
        
        ...(isAndroidEmpty && isIOSEmpty && { minBarLength: 4 }),
        //minBarLength:4,
        
/*         borderColor:'white',
        borderWidth:0,
        borderRadius:{
          topRight: 10,
          topLeft:10,
          bottomRight: 10,
          bottomLeft:10,

        },
        borderSkipped:true,
        barPercentage:0.25,
        categoryPercentage:0.5, */
      },

    ],
  };
  
  const options = {
    scales: {
      x: {
        stacked: true,
        grid: {
            display: true,
            color: (context) => {
              if (context.tick && context.tick.value % 1 === 0) {
                return '#ffffff30'; // Change the grid line color for every 5th tick
              }
              return 'rgba(0, 0, 0, 0)'; // Fallback for other grid lines
            },
          },
      },
      y: {
        stacked: true,
        ticks: {
            stepSize: 5, // Set the step size to 5 for y-axis values
          },

      },
    },

      plugins: {
        legend: {
          labels: {
            usePointStyle: true, // To use circle with labels
          },

        },
        tooltip: {
          enabled: !(isAndroidEmpty && isIOSEmpty),
        }
      },  

  };
  return (
    <>
     
    <Bar data={data} options={options} width={330}/>
    </>
  )
}

export default BarChart
