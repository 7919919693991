import React,{useState,useRef, useEffect} from 'react';
/* import { useNavigate } from 'react-router-dom'; */
import { setSecureState, getSecureState} from '../../services/securels.service';
import { useNavigate } from 'react-router-dom'; 
import Register from './Register';
import Header from '../Header';
import crypto from 'crypto';
import logoFooter from '../../assets/logoFooter.svg';
import AppStore from '../../assets/appstoreicon.svg';
import GooglePlay from '../../assets/playstoreicon.svg';

import { Circle } from 'better-react-spinkit';
import './login.css'


import { verifyNumber} from "../../services/user.service";
import {userLogin,confirmUserLogin} from '../../services/user.service';
function Login({ onLoginSuccess, handleCloseClick}) {



    const [xID, setxID] = useState('');

    const [validationMessage, setValidationMessage] = useState('');
    const [xIDValidationMessage, setxIDValidationMesage]=useState('');
    const [disableButton, setDisableButton] = useState(false); 
    const [loading, setLoading] = useState(false);
    const [codeValidationMessage, setCodeValidationMessage]=useState('');

    const [inputBorderColor, setinputBorderColor] = useState('#8080802e');
    const [apiResponse, setApiResponse] = useState({ status: 'initial' });

    const [isIdVerified, setIsIdVerified]=useState(false);

    const [isRegisterClicked, setIsRegisteredClicked]=useState(false);

    const [isPinVerified, setIsPinVerified]=useState(false);
    const [password, setPassword] = useState('');

    const[isXidValid, setIsXidValid]=useState(false);

    const[deviceNotChanged, setDeviceNotChanged]=useState(false);
    const[deviceChanged, setDeviceChanged]=useState(false);
    const[pinChecking, setPinChecking]=useState(false);

    const[codeUI, setCodeUI]=useState(false);
    const[formattedxID, setFormattedxID]=useState('');
    const[code, setCode]=useState('');
    const [codeValidationLoader, setCodeValidationLoader]=useState(false);
    
   
    const handleXIDChange =  (e: any) => {
      setinputBorderColor('#0489fe');
        const formattedValue = e.target.value.replace(/[^0-9]/g, ''); 
        const spacedValue = formattedValue.replace(/(\d{3})(?=\d)/g, '$1 '); 
        setxID(spacedValue);
        setFormattedxID(spacedValue);

         if (spacedValue.length === 11) {
            const cleanedUsername = spacedValue.replace(/\s/g, ''); 

            setLoading(true);
             verifyNumber(cleanedUsername).then((res) => {

                if (res) {
                    setApiResponse(res.data);
                    if (res.data.status === 'ok') {
                        
                        setxIDValidationMesage('');
                        setDisableButton(false); 
                        setLoading(false);
                        setinputBorderColor('#53d170');
                       // sessionStorage.setItem('xid_businessmember', cleanedUsername);
                        setIsXidValid(true);
                       // sessionStorage.setItem('xID', cleanedUsername);

                    } else {

                        setxIDValidationMesage('Please enter a valid xID');
                        setDisableButton(true); 
                        setLoading(false);
                        setinputBorderColor('red');
                    }
                }
            }).catch((err) => {
                if (err) {
                     console.log(err);
                     setxIDValidationMesage('Please enter a valid xID');
                     setDisableButton(true); 
                     setLoading(false);
                     setinputBorderColor('red');


                }
            }); 

        }

        else if(spacedValue.length===0){
          setinputBorderColor('#8080802e');
        }

    }

    const goToVerify=()=>{
      setxIDValidationMesage('');
        if (xID.length !== 11) {
          setxIDValidationMesage('Please enter a valid xID');
        } else if(isXidValid) {

            setIsIdVerified(true);
        }
    }

    const [pin1, setPin1] = useState('');
    const [pin2, setPin2] = useState('');
    const [pin3, setPin3] = useState('');
    const [pin4, setPin4] = useState('');


    const handlePinChange = (e, pinIndex) => {
        const formattedPinValue = e.target.value.replace(/[^0-9]/g, ''); //prevent non numeric characters
        //focus all input fields one by one  

        switch (pinIndex) {
          case 1:
            setPin1(formattedPinValue);
            if (formattedPinValue !== '' && pin2Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin2Ref.current.focus();
            }
            break;
          case 2:
            setPin2(formattedPinValue);
            if (formattedPinValue !== '' && pin3Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin3Ref.current.focus();
            }
            break;
          case 3:
            setPin3(formattedPinValue);
            if (formattedPinValue !== '' && pin4Ref.current) {
              // @ts-ignore: Object is possibly 'null'.
              pin4Ref.current.focus();
            }
            break;
          case 4:
            setPin4(formattedPinValue);
            break;
          default:
            break;
        }

        
      };

      const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, pinIndex: number) => {
        if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 4) {
          setPin4(''); // Clear the value of pin4
          // @ts-ignore: Object is possibly 'null'.
          pin3Ref.current?.focus(); // Shift focus to pin3
        } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 3) {
          setPin3(''); // Clear the value of pin3
          // @ts-ignore: Object is possibly 'null'.
          pin2Ref.current?.focus(); // Shift focus to pin2
        } else if (e.key === 'Backspace' && e.currentTarget.value === '' && pinIndex === 2) {
          setPin2(''); // Clear the value of pin2
          // @ts-ignore: Object is possibly 'null'.
          pin1Ref.current?.focus(); // Shift focus to pin1
        }
      };
      const pin = `${pin1}${pin2}${pin3}${pin4}`;

      const navigate= useNavigate();
      const goToConfirm=()=>{
        if (pin.length !== 4) {
          setValidationMessage('Please enter a 4-digit PIN');
          return;
        }
        else if(deviceNotChanged){
          //onLoginSuccess();
          setCodeUI(true);
          setIsPinVerified(true);
        }
        else if(deviceChanged){
          
          //onLoginSuccess();
          setCodeUI(true);
          setIsPinVerified(true);
        }

      }

      const pinRef = useRef('');

      const gotoRegister=()=>{
        setIsRegisteredClicked(true);
      }
  
      useEffect(() => {
          const pin = `${pin1}${pin2}${pin3}${pin4}`;
          if (pin.length === 4) {
              setPinChecking(true);
              setValidationMessage('Validating...');
              let uxid = getSecureState('uxid').value;
              
              const formattedValue = xID.replace(/[^0-9]/g, '');
              const spacedValue = formattedValue.replace(/(\d{3})(?=\d)/g, '$1 ');
              const cleanedUsername = spacedValue.replace(/\s/g, '');
              setxID(cleanedUsername);
  
              const pinHash = crypto.createHash('sha256').update(pin).digest('hex');
              const xidPin = `${cleanedUsername}_xpal_${pin}`;
              const xidPinHash = crypto.createHash('sha256').update(xidPin).digest('hex');
  
              setSecureState("loggedInUserNumber", cleanedUsername);
              setSecureState("loggedInPassword", xidPinHash);
              setSecureState("loggedInpin", pinHash);
              setSecureState("loggedInDeviceUdid", uxid);
              setSecureState("loggedInUdid", uxid);

              let userObj = {
                  xID: cleanedUsername,
                  pin: pinHash,
                  DeviceUdid: uxid,
                  Udid: uxid,
                  IsConfirm: false,
              };
  
                  userLogin (userObj)
                  .then((res) => {
                    if (res) {
                      if (res.data.status === 'ok') {
                        const secret = res.data.data.secret;
                        setSecureState("loggedInSecret", secret);
                        //const tempCode = res.data.data.tempCode;
                        //setCode(tempCode);
                        setDeviceNotChanged(true);
                        setPinChecking(false);
                        setIsPinVerified(true);
                        setCodeUI(true);

                        //sessionStorage.setItem('pin_businessmember', pinHash);
                        //const BM_Name = res.data.data.data.userName;
                        
                        //sessionStorage.setItem('name_businessmember', BM_Name);

                        //onLoginSuccess();
/*                         setValidationMessage('Press Next to continue');
                        setDeviceNotChanged(true);
                        setPinChecking(false);
                        setIsPinVerified(true);
                        setCodeUI(true); */
                        
                      }
                      else{
                        setValidationMessage('Please enter a valid PIN');
                        setDisableButton(true);

                      }
                    }
                  })
                  .catch((err) => {
                    setPinChecking(false);
                    setValidationMessage('Please enter a valid PIN');
                    console.log(err);
                  });
          }
      }, [pin1, pin2, pin3, pin4]);

    const pin1Ref = useRef(null);
    const pin2Ref = useRef(null);
    const pin3Ref = useRef(null);
    const pin4Ref = useRef(null);

    const goBackToLogin=()=>{
      setIsIdVerified(false);
      setPin1("");
      setPin2("");
      setPin3("");
      setPin4("");
      setValidationMessage("");
      setxID(xID.replace(/(\d{3})(?=\d)/g, '$1 '));
    }

    const goBackToPin=()=>{
      setCodeValidationMessage('');
      setIsPinVerified(false);
    }

    const goToMainLogin=()=>{
      setIsIdVerified(false);
      setIsPinVerified(false);
      setIsRegisteredClicked(false);
    }

    const handleCodeChange =  (e: any) => {
      setCode(e.target.value);
      setCodeValidationMessage('');
    }

    const HandleSuccessfulLogin=()=>{
      if(code.length!==0){
        setCodeValidationMessage('');
        setCodeValidationLoader(true);
        //onLoginSuccess();
        let uxid = getSecureState('uxid').value;
        let pinHash = getSecureState('loggedInpin').value;
        let cleanedUsername = getSecureState('loggedInUserNumber').value;
        let secret = getSecureState('loggedInSecret').value;
        let userObj = {
          xID: cleanedUsername,
          pin: pinHash,
          DeviceUdid: uxid,
          Udid: uxid,
          IsConfirm: true,
          Secret: secret,
          Key: code,
  
        };
        confirmUserLogin(userObj).then((res)=>{
          
          if (res) {
            if (res.data.status === 'ok') {
  
              //const BM_Name = res.data.data.userName
              //sessionStorage.setItem('name_businessmember', BM_Name);
              //onLoginSuccess();
  
  /*             setValidationMessage('Press Next to continue');
              setDeviceChanged(true);
              setPinChecking(false);
              setIsPinVerified(true);
              setCodeUI(true); */
              sessionStorage.setItem('pin_businessmember', pinHash);
              sessionStorage.setItem('xID', cleanedUsername);
              setCodeValidationLoader(false);
              onLoginSuccess();
            }
            else{
              setCodeValidationMessage('Please Enter Valid Code');
              setCodeValidationLoader(false);
            }
          }
        })
      }
      else{
        setCodeValidationMessage('Please Enter Valid Code');
      }

    }
  return (
    <>
    <Header/>
    <div className='webLogin d-flex justify-content-between align-items-center'>
    
        
        <div className='container'>
            <div className='d-flex justify-content-center align-items-center loginContainerWrapper'>

            {!isIdVerified && !isPinVerified && !isRegisterClicked &&(
                <div className='col2 container ltr-animation'>
                    <p className='closeBtn' onClick={handleCloseClick}>Close</p>
                    <div className="loginHeading mt-4">Login to your xPal ID</div>
                    <div className="loginText mt-2">Please enter your xID which you want to connect <br/>
                    with xPal Business this will be the Master xID <br/>to manage other xIDs.</div>
                    <div style={{position:'relative'}}>
                        <input className="inputField text-center mt-5 mb-5" autoFocus type="text" placeholder="Enter xID" maxLength={11} onChange={(e) => handleXIDChange(e)}
                                    value={xID} style={{width: '45%', border: `1px solid ${inputBorderColor}`}} autoComplete="off"/>
                        
                            {loading && (
                            <div className="icon-container" style={{position:'absolute',right: '30%', top: '44%'}}>
                                <span className='loader'></span>
                            </div>
                            )}   
 
                    </div>

                    <div className="mt-4 " style={{ position: 'relative' }}>
                    {xIDValidationMessage && (
                        <div className="danger" style={{ position: 'absolute', color: 'red', bottom: '20px', left: '0',right:'0',marginLeft:'auto',marginRight:'auto',width:'200px' }} >
                        {xIDValidationMessage}
                        </div>
                    )}
                    </div>

                    <div className='loginButton mb-1'>
                        <button onClick={()=>goToVerify()} disabled={disableButton} style={{opacity: isXidValid? 1 : 0.8,
                                    pointerEvents: isXidValid? 'auto' : 'none'}}>Next</button>
                    </div>

                    <div className='mt-5 registerDiv'>Don't have xID?
                    <p className='register mt-1' onClick={()=>gotoRegister()}>Register</p></div>

                </div>
                )}

                {!isIdVerified && !isPinVerified && isRegisterClicked &&(
                  <Register goToMainLogin={goToMainLogin} onLoginSuccess={onLoginSuccess}/>
                )}
                {isIdVerified && !isPinVerified && (
                  <>
                                  <div className='col2 container ltr-animation'>
                    <p className='closeBtn' onClick={()=>goBackToLogin()}><span className='mr-2'>&lt;</span>Back</p>
                    <div className="loginHeading mt-4">4-Digit PIN</div>
                    <div className="loginText mt-2">Enter your 4-Digit PIN you set for this account</div>
                    <div className='loginInput pincode-input-container d-flex align-items-center justify-content-center'>
                    <form method="post" action="/form" autoComplete="new-password">

                    <div className="pincode-input-container">
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*"  autoFocus value={pin1} onChange={(e) => handlePinChange(e, 1)} onKeyDown={(e) => handleBackspace(e, 1)} ref={pin1Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin2} onChange={(e) => handlePinChange(e, 2)} onKeyDown={(e) => handleBackspace(e, 2)} ref={pin2Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin3} onChange={(e) => handlePinChange(e, 3)} onKeyDown={(e) => handleBackspace(e, 3)} ref={pin3Ref}/>
                        <input className="pincode-input-text" maxLength={1} autoComplete="off" type="tel" inputMode="text" pattern="[0-9]*" value={pin4} onChange={(e) => handlePinChange(e, 4)} onKeyDown={(e) => handleBackspace(e, 4)} ref={pin4Ref}/>
                        </div>

                    </form>
                          
                    </div>

                    <div className="mt-4 " style={{ position: 'relative' }}>
                    {validationMessage && (
                        <div className="danger" style={{ position: 'absolute', color: (validationMessage === 'Validating...' ? 'blue' : (validationMessage === 'Press Next to continue' ? 'green' : 'red')), bottom: '20px', left: '0',right:'0',marginLeft:'auto',marginRight:'auto',width:'200px' }} >
                        {validationMessage}
                        </div>
                    )}
                    </div>

                    <div className='loginButton mb-4 mt-5'>
                        <button className="mt-5" disabled={disableButton} onClick={()=>goToConfirm()} style={{opacity: isPinVerified? 1 : 0.8,
                                    pointerEvents: isPinVerified? 'auto' : 'none'}}>Next</button>
                    </div>
                </div>
                  </>
                )}

                {isPinVerified && codeUI &&(
                  <>
                    <div className='col2 container ltr-animation'>
                      <p className='closeBtn' onClick={()=>goBackToPin()}><span className='mr-2'>&lt;</span>Back</p>
                      <div className="loginHeading mt-4">Verification Code</div>
                      <div className="loginText mt-2">Enter Verification Code you received at <span>{formattedxID}</span></div>
                      <div style={{position:'relative'}}>
                          <input className="inputField text-center mt-5 mb-5" autoFocus type="text" placeholder="Code"
                                      value={code} style={{width: '45%'}} autoComplete="off" onChange={(e) => handleCodeChange(e)}/>
                          
                              {loading && (
                              <div className="icon-container" style={{position:'absolute',right: '30%', top: '44%'}}>
                                  <span className='loader'></span>
                              </div>
                              )}   
                      </div>
                      

                      <div className="mt-4 " style={{ position: 'relative' }}>
                      {codeValidationMessage && (
                          <div className="danger" style={{ position: 'absolute', color: 'red', bottom: '20px', left: '0',right:'0',marginLeft:'auto',marginRight:'auto',width:'200px' }} >
                          {codeValidationMessage}
                          </div>
                      )}
                      </div> 

                      <div className='loginButton mb-4 mt-5'>
                          <button className="mt-5" disabled={disableButton} onClick={()=>HandleSuccessfulLogin()} style={{
                                opacity: code.length < 4 ? 0.8 : 1,
                                pointerEvents: code.length < 4 ? 'none' : 'auto',
                            }} >Next{codeValidationLoader && (<span className="stripeBtnLoader" id="stripeBtnLoader" style={{right: '27%', bottom: '44%', transform: 'translateY(-2px)'}}></span>)}</button>
                      </div>
                   </div>
                  </>
                )}


            </div>
        </div>
     </div>



    </>
  )
}

export default Login
