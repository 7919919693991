import React,{useState,useEffect} from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";


  function PaymentFormDashboard() {
    const xID = sessionStorage.getItem('xID');
    //console.log('textcolor', textColor);
    
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState("");
    const [showStripeBtnLoader, setShowStripeBtnLoader]=useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false);

    useEffect(() => {
      // Simulate form loading time
      const timer = setTimeout(() => {
        if (elements) {
          setFormLoaded(true);
        }
      }, 500); // Adjust this delay if needed
  
      return () => clearTimeout(timer);
    }, [elements]);

    const paymentElementOptions = {
      layout: "tabs"
    };

  
    const handleSubmit = async (e) => {
      e.preventDefault();

      //setShowStripeBtnLoader(true);
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/dashboard/checkout/`
        },
      });
  

        if (error.type === "card_error" || error.type === "validation_error") {
            //setMessage(error.message);
            setIsLoading(false);
        } else {
            //setMessage("An unexpected error occurred.");
            setIsLoading(false);
        }

  
      setIsLoading(false);
    };
      

    return(
/*         <div className="payment-form">
        <div className="input-field">
            <div className="custom-label">Credit Card Number</div>
            <div className='CardNumberElementDiv'>
            <CardNumberElement options={{
                        placeholder: "Credit Card Number",
                        style: {
                          base: {
                            fontSize: "18px",
                            color: textColor === 'white' ? 'white' : 'black',
                            "::placeholder": {
                            }
                          },
                        },
                      }}/>
            </div>

            </div>
            <div className="input-fields-group">
                <div className="input-field">
                <div className="custom-label">Expiry</div>
                <div className='CardExpiryElementDiv'>
                <CardExpiryElement options={{
                            placeholder: "Expiry",
                            style: {
                            base: {
                                fontSize: "18px",
                                color: textColor === 'white' ? 'white' : 'black',
                                "::placeholder": {
                                }
                            },
                            },
                        }} 
                        />
                </div>

                </div>
                <div className="input-field">
                <div className="custom-label">Security Code</div>
                <div className='SecurityCodeDiv'>
                  <CardCvcElement options={{
                              placeholder: "Security Code",
                              style: {
                              base: {
                                  fontSize: "18px",
                                  color: textColor === 'white' ? 'white' : 'black',
                                  "::placeholder": {
                                  }
                                },
                              },
                          }}/>
                </div>
                </div>
            </div>

         <div className='pay' onClick={validateAndPay}>Pay</div>
         {errorMessage && (
                        <div className="danger" style={{ position: 'absolute', color: 'red', bottom: '0px', left: '0',right:'0',marginLeft:'auto',marginRight:'auto',width:'200px',textAlign: 'center',transform: 'translateY(15px)', fontWeight:'600' }} >
                        {errorMessage}
                        </div>
          )}
      </div> */
      <>

    {!formLoaded && (
            <div className="form-loader-container">
              <span className="formloader"></span>
            </div>
          )}

     {formLoaded && (
            <form id="payment-form" onSubmit={handleSubmit} className='stripe-payment-form'>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">Pay now</span>{isLoading &&(<span class="stripeBtnLoader" id="stripeBtnLoader" style={{bottom: '45%', transform: 'translateY(0px)'}}></span>)}
          </button>

          {message && <div id="payment-message">{message}</div>}
        </form>
      )}      

      </>
    )
  }
  export default PaymentFormDashboard;