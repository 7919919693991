import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import './checkout.css';

function PaymentForm( ) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [showStripeBtnLoader, setShowStripeBtnLoader]=useState(false);

  useEffect(() => {
    // Simulate form loading time
    const timer = setTimeout(() => {
      if (elements) {
        setFormLoaded(true);
      }
    }, 500); // Adjust this delay if needed

    return () => clearTimeout(timer);
  }, [elements]);

  const handleSubmit = async (e) => {
    setShowStripeBtnLoader(true);
    e.preventDefault();
    
    
    if (!stripe || !elements) {

      return;
    }

    setIsLoading(true);
    
    const { error } = await stripe.confirmPayment({
      
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/checkout/`
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      setShowStripeBtnLoader(false);
    } 

    else {
      setMessage("An unexpected error occurred.");
      setShowStripeBtnLoader(false);
    }
    
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };


  return (
    <>
      {!formLoaded && (
        <div className="form-loader-container">
          <span className="formloader"></span>
        </div>
      )}

      {formLoaded && (
        <form id="payment-form" onSubmit={handleSubmit} className='stripe-payment-form'>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">Pay now</span>{showStripeBtnLoader &&(<span class="stripeBtnLoader" id="stripeBtnLoader" style={{right:"37%", bottom:"41%"}}></span>)}
          </button>

          {message && <div id="payment-message">{message}</div>}
        </form>
      )}
    </>
  );
}

export default PaymentForm;
