import React from 'react';
import logoheader from '../assets/logoheader.png';
import Logo from '../assets/Logo.png'
import './components.css';
import xPalBusinessLogo from '../assets/xPalBusinessLogo.png';
import xPalBusinessWhiteLogo from '../assets/xPal-Biz-logo-white.png';

function Header(){

return(
    <>
    <div className="header">
    <header>
        <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark p-0 navbar navbar-expand-lg navbar-light align-items-center">
  <a className="navbar-brand" href="/">
    <img className="logoHeader" alt='xPal Business' src="https://dz5tyoxl8h44g.cloudfront.net/xbiz-logo-white.png"></img></a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="justify-content-end collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav">
   
                            <li className="navItem">
                                <a className="navLink" href='https://xpal.com/#features' target="_blank" rel='noreferrer'>FEATURES</a>
                            </li>

                            <p className="navItem-separator">|</p>
                            <li className="navItem">
                                <a className="navLink" href='https://xpal.com/about' target="_blank" rel='noreferrer'>ABOUT XPAL</a>
                            </li>
{/*                             <p className="navItem-separator">|</p>
                            <li className="navItem">
                                <a className="navLink" href='https://xpal.com/press-release' target="_blank">PRESS RELEASE</a>
                            </li> */}
{/*                             <p className="navItem-separator">|</p>
                            <li className="navItem">
                                <a className="navLink" href='#' target="_blank">BUSINESS</a>
                            </li>
                            <p className="navItem-separator">|</p>
                            <li className="navItem">
                                <a className="navLink" href='https://blog.xpal.com' target="_blank">BLOG</a>
                            </li> */}
                            <p className="navItem-separator">|</p>
                            <li className="navItem">
                                <a className="navLink" href='https://xpal.com/faq' target="_blank" rel='noreferrer'>FAQ</a>
                            </li>
                            <p className="navItem-separator">|</p>
                            <li className="navItem">
                                <a className="navLink" href='https://xpal.com/contact' rel='noreferrer' target="_blank">CONTACT</a>
                            </li>
    </ul>

  </div>
</nav>


        </div>
    </header>
    </div>
    </>
)
}
export default Header;
