import axios from "axios";
import {
  getAuthHeaders,getHeaders,getAuthHeaders2} from "./authenticate.service";
import { apiXpal } from "./constants.service";
import {setSecureState } from "./securels.service";
const apiUrlXpal = apiXpal.corev2;

const userLogin = function (userObject: any) {
  let config = {};
  config = getAuthHeaders();
  let result = axios.post(`${apiUrlXpal}user/login`, userObject, config).then(res => {
    //setSecureState("loginToken", res.data.data.data.authToken);
    return res;
  });
 
  return result;
};

const authenticate = function (udid: any) {
    let result = axios.post(
    `${apiUrlXpal}Auth/generate`,
    { Udid: udid },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  return result;
};

const logout = async function (userObject: any) {
  let config = {};
  config = getAuthHeaders2();
  let result = axios.post(`${apiUrlXpal}user/logout`, userObject, config);
  return result;
};

const createAuthToken = function (userObject) {
  let result = axios.post(`${apiUrlXpal}auth/create`, userObject);
  return result;
};

const confirmUserLogin = function (userObject: any) {

  let config = {};
  config = getAuthHeaders();
  let result = axios.post(`${apiUrlXpal}user/confirmlogin`, userObject, config).then(res => {
  
    setSecureState("loginToken", res.data.data.authToken);
    return res;
  });
 
  return result;
};

const verifyNumber = function (userNumber: any) {
  let config = {};
  config = getAuthHeaders();
  let result = axios.get(
    `${apiUrlXpal}number/verify?xid=${userNumber}`,
    config
  );
  return result;
};

const confirmationUserLogin = async function (userObject: any) {
  let config = {};
  config = await getAuthHeaders();
  let result = axios.post(
    `${apiUrlXpal}Users/Confirmation`,
    userObject,
    config
  );
  return result;
};

/*28-2-2024*/
const refXIDs = function (page:any, size:any) {
  let config = {};
  config = getAuthHeaders2();
 
  let result = axios.get(

    `${apiUrlXpal}user/refxids`,
    config
  );
  return result;
};

const dash = function () {
  let config = {};
  config = getAuthHeaders2();
  
  let result = axios.get(

    `${apiUrlXpal}user/dash`,
    config
  );
  return result;
};

const update = function (userObject: any) {
  let config = {};
  config = getAuthHeaders2();
  let result = axios.put(

    `${apiUrlXpal}user`,
    userObject,
    config
  );
  return result;
};

const release = function (userObject: any) {
  let config = {};
  config = getAuthHeaders2();

  let result = axios.post(

    `${apiUrlXpal}user/release`,
    userObject,
    config
  );
  return result;
};

const cbdemo = function (userObject: any) {
  let config = {};
  config = getAuthHeaders2();
  let result = axios.post(

    `${apiUrlXpal}payment/cbdemo`,
    userObject,
    config
  );
  return result;
};

const userAllocate = function (userObject: any){
  let config = {};
  config = getAuthHeaders();
  let result = axios.post(`${apiUrlXpal}number/allocate`, userObject, config);
  return result;
}

const userRegister = function (userObject: any) {
  let config = {};
  config = getAuthHeaders();
  let result = axios.post(`${apiUrlXpal}user/register`, userObject, config).then(res => {
    setSecureState("loginToken", res.data.data.authToken);
    return res;
  });
 
  return result;
};

const stripePayment = function (data: any) {
  let config = {}; 
  config = getAuthHeaders2(); 
  let result = axios.post(  
    `${apiUrlXpal}payment/intent`,
    data,
    config
  );
  return result;
};

const bitpayPayment = function (data: any) {
  let config = {}; 
  config = getAuthHeaders2(); 
  let result = axios.post(  
    `${apiUrlXpal}payment/bitpay/invoice`,
    data,
    config
  );
  return result;
};

const bitpayStatus = function (data: any) {
  let config = {}; 
  config = getAuthHeaders2(); 
  let result = axios.post(  
    `${apiUrlXpal}payment/bitpay/invoice/status?invoiceId=${data}`,
    data,
    config
  );
  return result;
};

const createTeam = function (data: any) {
  let config = {}; 
  config = getAuthHeaders2(); 
  let result = axios.post(  
    `${apiUrlXpal}user/team`,
    data,
    config
  );
  return result;
};

const getTeam = function (skip:any, pagesize:any) {
  let config = {};
  config = getAuthHeaders2();
  let result = axios.get(
    `${apiUrlXpal}user/myteams`,
    config
  );
  return result;
};

const updateTeam = function (data: any) {
  let config = {}; 
  config = getAuthHeaders2(); 
  let result = axios.post(  
    `${apiUrlXpal}user/uteam`,
    data,
    config
  );
  return result;
};

const createLink = function (data: any) {
  let config = {}; 
  config = getAuthHeaders2(); 
  let result = axios.post(  
    `${apiUrlXpal}user/reflink`,
    data,
    config
  );
  return result;
};

export {
  userLogin,
  authenticate,
  createAuthToken, 
  logout,
  confirmUserLogin,
  verifyNumber,
  confirmationUserLogin,
  refXIDs,
  dash,
  update,
  release,
  cbdemo,
  userAllocate,
  userRegister,
  stripePayment,
  bitpayPayment,
  bitpayStatus,
  createTeam,
  getTeam,
  updateTeam,
  createLink
};
