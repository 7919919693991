import React,{useState, useEffect} from 'react';
import { Circle } from 'better-react-spinkit';
import { dash } from "../../services/user.service";
import './components.css';
import exp from 'constants';
import DonutChart from './DonutChart';
import BarChart from './BarChart';
const getDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}.${date}.${year}`;
  };
function Dashboard({ onTotalChange }){
    const [loading, setLoading] = useState(true);
    const [dashAPIData, setDashAPIData] = useState([]);
    
    const [Android, setAndroid]=useState();
    const [IOs, setIOs]=useState();
    const [total, setTotal]=useState();
    const [active, setActive]=useState();
    const [assigned, setAssigned]=useState();
    const [unAssigned, setUnAssigned]=useState();
    useEffect(() => {
        dash().then(res => {
          if (res.data.status==='ok'){
            setLoading(false);
            //console.log('ok from dash');
            setDashAPIData(res.data.data);
            onTotalChange(res.data.data.total);
            setAndroid(res.data.data.android);
            setIOs(res.data.data.ios);
            setTotal(res.data.data.total);
            setActive(res.data.data.active);
            setAssigned(res.data.data.assigned);
            setUnAssigned(res.data.data.unAssigned);
          }
        })
        }, []);  

      const [currentDate, setCurrentDate] = useState(getDate());

    return(
        <>
                <div className='col-md-10 mangeXIDs secondCol dashboard'>
                    <div className='xidsTitle'>
                    <i className="fa-solid fa-arrow-right mr-2"></i>Dashboard
                    </div>

                    <div className='manageText'>
                        Welcome to xPal Business
                    </div>
                    <div className='container mainParentDiv'>
                        {total === 0 && (
                        <div className='teamCommunication mb-4 wrapperDiv'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <h3>Ensure Private and Secure Team<br/>
                                    Communication under your Control.</h3>
                                    <p>Effortlessly control your team with a simple dashboard for tasks<br/>
                                    and data management.</p>
                                </div>
                                <div>
                                <div className="communicateBtn mt-5 mb-5">Purchase Business xIDs &gt;</div>
                                </div>
                            </div>
                        </div>
                        )}
                        <div className='dataDiv d-flex align-items-center mb-4 wrapperDiv justify-content-between'>
                            <div className='xIDsDataMain smallDiv'>
                                <div className='dataContainer'>
                                    <div className='heading' style={{ color: total === 0 ? '#5B5B5B' : '#FCFCFC;' }}>Total xIDs</div>
                                    {loading ? (
                                        
                                        <div className="loading-spinner value" style={{display:'flex',justifyContent:'end'}}>
                                            <Circle size={60} color="#1E90FF" />
                                        </div>
                                        
                                    ) : (
                                        <>
                                        {total===0 && (<i className="fa-solid fa-lock"></i>)}
                                        <div className='value' style={{ color: total === 0 ? '#5B5B5B' : '#FFFFFF' }}>{total}</div></>
                                    )}
                                    
                                </div>
                            </div>
                            <div className='xIDsDataMain smallDiv'>
                            <div className='dataContainer'>
                                    <div className='heading' style={{ color: total === 0 ? '#5B5B5B' : '#FCFCFC;' }}>Active</div>
                                    {loading ? (
                                        
                                        <div className="loading-spinner value" style={{display:'flex',justifyContent:'end'}}>
                                            <Circle size={60} color="#1E90FF" />
                                        </div>
                                        
                                    ) : (
                                        <>
                                        {total===0 && (<i className="fa-solid fa-lock"></i>)}
                                        <div className='value' style={{ color: total === 0 ? '#5B5B5B' : '#FFFFFF' }}>{active}</div></>
                                    )}
                                    
                                </div>
                            </div>
                            <div className='xIDsDataMain smallDiv'>
                            <div className='dataContainer'>
                                    <div className='heading' style={{ color: total === 0 ? '#5B5B5B' : '#FCFCFC;' }}>Assigned</div>
                                    {loading ? (
                                        
                                        <div className="loading-spinner value" style={{display:'flex',justifyContent:'end'}}>
                                            <Circle size={60} color="#1E90FF" />
                                        </div>
                                        
                                    ) : (
                                        <>
                                        {total===0 && (<i className="fa-solid fa-lock"></i>)}
                                        <div className='value' style={{ color: total === 0 ? '#5B5B5B' : '#FFFFFF' }}>{assigned}</div>
                                        </>
                                    )}
                                    
                                </div>
                            </div>
                            <div className='xIDsDataMain smallDiv'>
                            <div className='dataContainer'>
                                    <div className='heading' style={{ color: total === 0 ? '#5B5B5B' : '#FCFCFC;' }}>Remaining xIDs</div>
                                    {loading ? (
                                        
                                        <div className="loading-spinner value" style={{display:'flex',justifyContent:'end'}}>
                                            <Circle size={60} color="#1E90FF" />
                                        </div>
                                        
                                    ) : (
                                        <>
                                        {total===0 && (<i className="fa-solid fa-lock"></i>)}
                                        <div className='value' style={{ color: total === 0 ? '#5B5B5B' : '#FFFFFF' }}>{unAssigned}</div></>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='chartsDiv d-flex align-items-center wrapperDiv justify-content-between'>
                            <div className='donutChartDiv smallDiv'>
                                <div className='d-flex justify-content-between'>
                                    <div className="col-md-6" style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                        <div>
                                            <h3 className='os' style={{ color: total === 0 ? '#5B5B5B' : '#FFFFFF' }}>Operating Systems </h3>{total===0 && (<i className="fa-solid fa-lock"></i>)}
                                            <p className='cDate' style={{ color: total === 0 ? '#5B5B5B' : '#ffffff6b' }}>Effective: {currentDate}</p>
                                        </div>
                                        <div>
                                            <div className='type'>ios</div>
                                            
                                            {loading ? (
                                                <div className="number mb-5" style={{display:'flex',justifyContent:'start'}}>
                                                    <Circle size={50} color="#1E90FF" />
                                                </div>
                                            ) : (
                                                <div className='number mb-5' style={{ color: total === 0 ? '#5B5B5B' : '#FFFFFF' }}>{IOs}</div>
                                            )}
                                        </div>
                                        <div>

                                        <div className='type'>Android</div>
                                            {loading ? (
                                                <div className="number mb-4" style={{display:'flex',justifyContent:'start'}}>
                                                    <Circle size={50} color="#1E90FF" />
                                                </div>
                                            ) : (
                                                <div className='number mb-4' style={{ color: total === 0 ? '#5B5B5B' : '#FFFFFF' }}>{Android}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <DonutChart dashAPIData={dashAPIData}/>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='barChartDiv smallDiv'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='activeUsers' style={{ color: total === 0 ? '#5B5B5B' : '#FFFFFF' }}>Active Users</div>
                                    <div className='cDate' style={{ color: total === 0 ? '#5B5B5B' : '#ffffff6b' }}>Effective: {currentDate}</div>
                                </div>
                            <BarChart dashAPIData={dashAPIData}/>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
               
        </>
    )
}
export default Dashboard