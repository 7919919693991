import React,{useRef,useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticateUser } from "../services/token-authentication.service";
import Login from '../Components/Dashboard/Login';
import { logout } from '../services/user.service';
import { getSecureState } from '../services/securels.service';

import './components.css';
import './landingpage.css';
import Header from './Header';
import Footer from './Footer';

function LandingPage({ onInputValueChange,  onInputChange, setRecursionChange, setSequenceChange, onAmountChange, onCostPerUserChange}){

    const pin_businessmember= sessionStorage.getItem('pin_businessmember');
    const [defaultHome, setDefaultHome]=useState(true);
    const [loginUI, setLoginUI]=useState(false);

    const [value, setValue] = useState("10");
    const [calculatedAmount, setCalculatedAmount] = useState('');
    const [costPerUser, setCostPerUser]=useState('');
    const [costPerMonth, setCostPerMonth]=useState('');
    const [costPerYear, setCostPerYear]=useState('');

    const [selectUI, setSelectUI] = useState(true);
    const [purchaseUI, setPurchaseUI2] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);
  
    const inputRef = useRef<HTMLInputElement>(null);
    const purchaseRef = useRef<HTMLInputElement>(null);

    useEffect(() => {

        const timer = setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 150); 
    
        return () => clearTimeout(timer);
      }, [selectUI]);

      useEffect(() => {
        const timer = setTimeout(() => {
          if (purchaseRef.current) {
            purchaseRef.current.focus();
          }
        }, 150); 
    
        return () => clearTimeout(timer);
      }, [purchaseUI]);

    const toggleUI = () => {

        if( value === '0' || value === '00' || value === '000' || value === ''){
            setErrorMessage(true);
        }
        else{
            setErrorMessage(false);
            setSelectUI(!selectUI);
            setPurchaseUI2(!purchaseUI);
        }

      };

      const showSelectUI = () => {
        setSelectUI(true);
        setPurchaseUI2(false);
        
      }
    const handleChange = (e:any) => {
        setErrorMessage(false);
        let newValue = e.target.value;
        newValue =e.target.value.replace(/[^0-9]/g, '')
    
        if (!newValue) {
          newValue = '';
        } 
        onInputChange(newValue);
        setValue(newValue);
        
      }

      const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          console.log('Enter pressed');
          toggleUI();
        }
      };

      const [isSequenceOpen, setIsSequenceOpen] = useState(false);
      const [selectedSequenceLink, setSelectedSequenceLink] = useState('Random');
  

      const[isRecursionOpen, setIsRecursionOpen]=useState(false);
      const[selectedRecursionLink, setSelectedRecursionLink]=useState('Yearly');
    
      const toggleSequenceDropdown = () => {
        setIsSequenceOpen(!isSequenceOpen);
      };
    
      const handleSequenceItemClick = (linkText) => {
        setSelectedSequenceLink(linkText);
        setIsSequenceOpen(false); // Close dropdown when an item is clicked
        setSequenceChange(linkText);
      };

      const toggleRecursionDropdown = () => {
        setIsRecursionOpen(!isRecursionOpen);
      };

      const handleRecursionItemClick = (linkText1) => {
        setSelectedRecursionLink(linkText1);
        setIsRecursionOpen(false); // Close dropdown when an item is clicked
        setRecursionChange(linkText1);
        //console.log('Selected xID Recursion:', linkText1);
      };



    const handleInputChange = (newValue) => {

        onInputValueChange(newValue);
        //console.log('response from landingpage',newValue);
      };
        /*aythenticate*/
          useEffect(() => {
            authenticateUser().then((res:any) => {
            })
          }, []);   

          

    

    const navigate= useNavigate();
    const goToDashboard=()=>{
        navigate('/dashboard');
        const uniqueString = 'BMBtn';
        sessionStorage.setItem('buttonPressed', uniqueString);
    }
    const goToLoginV2=()=>{
        if( value === '0' || value === '00' || value === '000' || value===''){
            setErrorMessage(true);
        }
        else{
            if (pin_businessmember){
                navigate('/purchase');
            }
            else{
                setDefaultHome(false);
                setLoginUI(true);
            } 
        }
        
        
        //console.log('selectedSequenceLink', selectedSequenceLink);
        localStorage.setItem('selectedSequenceLink', selectedSequenceLink);
        //console.log('selectedRecursionLink', selectedRecursionLink);
        localStorage.setItem('selectedRecursionLink',selectedRecursionLink);
        //console.log('value', value);
        localStorage.setItem('value',value);
        
        
       
    }

    const handlePurchaseKeyDown = (e) => {
        if (e.key === 'Enter') {
          console.log('Enter pressed');
          goToLoginV2();
        }
      };

    useEffect(() => {
        let amount;
        let costPerUser;
        if (Number(value) <= 10) {
          amount = selectedRecursionLink === 'Monthly'
            ? ((Number(value)) * 3.9).toFixed(2)
            : ((Number(value)) * 29).toFixed(2);

            costPerUser = selectedRecursionLink === 'Monthly'
            ? '$3.9'
            : '$29';
        } else {
          amount = selectedRecursionLink === 'Monthly'
            ? ((Number(value)) * 2.9).toFixed(2)
            : ((Number(value)) * 19).toFixed(2);

            costPerUser = selectedRecursionLink === 'Monthly'
            ? '$2.9'
            : '$19';
        }
    
        onCostPerUserChange(costPerUser); 
        setCostPerUser(costPerUser);
        setCalculatedAmount(amount);
        onAmountChange(amount);
        localStorage.setItem("totalAmount", amount);
        localStorage.setItem("costPerUser", costPerUser);

        
        if(selectedRecursionLink === 'Monthly'){
            setCostPerMonth(costPerUser);
            if (Number(value) <= 10) {
                setCostPerYear('$29'); 
            }
            else{
                setCostPerYear('$19');  
            }
        }
        else if(selectedRecursionLink === 'Yearly'){
            setCostPerYear(costPerUser);
            
            if (Number(value) <= 10) {
                setCostPerMonth('$3.9');
            }
            else{
                setCostPerMonth('$2.9');
            }
        }
      }, [value, selectedRecursionLink]);


    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        if( value === '0'){
            setErrorMessage(true);
        }
        else{
              setPurchaseUI2(true);
              setSelectUI(false);
        }
    };

    const handleLoginSuccess = () => {
        // Check if required data exists in Session Storage
        //console.log('checking')
        navigate('/purchase');
        
    };
    const handleCloseClick = () => {
        setDefaultHome(true);
        setSelectUI(true);
        setPurchaseUI2(false);
    };


   
    return(
        <>
        {defaultHome && (<section className='ultraSecure'>
        <Header/>
            <div className='d-flex justify-content-between align-items-center container topSecContainer'>
                <div className='textSec d-flex justify-content-center flex-column col-md-6'>
                        <h2>Ultra-Secure <span>BUSINESS<br/>
                        Messenger Solution</span></h2>
                        <p className='mt-2'>Security, Privacy, and Control over your Communication<br/> with your team.</p>
                        <div className='businessMember mt-5'>Already a Business Member? {pin_businessmember ? (
                            <a onClick={() => goToDashboard()}>Logged in</a>
                            ) : (
                            <a onClick={() => goToDashboard()}>Login</a>
                        )}</div>
                </div>
                
                {selectUI && (
                <>      
                 <div className='selectSec d-flex justify-content-center flex-column align-items-center col-md-7 rtl-animation'>
                    <div>
                        <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_logoblue.png" style={{width:'21%'}} className='mb-3' alt="business logo blue" />
                        
                        <h3 className=''>How many xIDs do you want?</h3>
                        <div>
                        <input className="mt-3 selectUIInput" type="text" value={value} maxLength={3} onChange={handleChange}  onKeyDown={handleKeyDown} ref={inputRef}/>        
                        </div>                     
                        {errorMessage && (<p className="errorMessage" style={{position:'absolute', bottom: '100px', color: 'red', fontWeight: '600', left: '0',
    right: '0'}}>Please enter any number other than 0 to proceed</p>)}
                        
                    </div>
                    <div className="nextBtn" onClick={toggleUI}>Next</div>
                 </div>
                 
                 </>
                )}
                    {purchaseUI && (
                        <>
                        <div className='selectSec purchase d-flex justify-content-center flex-column align-items-center col-md-7  rtl-animation'>
                        <div className='backDiv borderBottom d-flex justify-content-between align-items-center w-100 puchaseBox'>
                            <p className="back" onClick={showSelectUI} >&lt; Back</p>
                            <p>Purchase Cost</p>
                            <p className='hidden'>Cost</p>
                        </div>    
                        <div className='lrPadding selectxIDs borderBottom d-flex justify-content-between align-items-center w-100 puchaseBox'>
                            <p>Total Number of xIDs:</p>
                            {/* <p className='largeTxt'>{value}</p> */}
                            <input className="largeTxt purchaseUIInput mb-1" type="text" value={value} maxLength={3} onChange={handleChange} onKeyDown={handlePurchaseKeyDown} ref={purchaseRef}/>      
                        </div>        
                        <div className='lrPadding borderBottom mt-5 d-flex justify-content-between align-items-center w-100 puchaseBox'>
                            <p>Cost per user:</p>
                            <p className='largeTxt'>{costPerUser}</p>
                        </div>   
                        <div className='lrPadding borderBottom mt-5 d-flex justify-content-between align-items-center w-100 puchaseBox'>
                            <p>xID Format:</p>
                            <div className="dropdown">
                                <p className="dropbtn largeTxt" onClick={toggleSequenceDropdown}>
                                {selectedSequenceLink? selectedSequenceLink : 'Random'}
                                <i className="fa-solid fa-chevron-down"></i>
                                </p>
                                {isSequenceOpen && (
                                <div className="dropdown-content">
                                    <a onClick={() => handleSequenceItemClick('Random')}>Random</a>
                                    <a onClick={() => handleSequenceItemClick('Sequential')}>Sequential</a>
                                </div>
                                )}
                            </div> 
                        </div>    
                        <div className='lrPadding borderBottom mt-5 d-flex justify-content-between align-items-center w-100 puchaseBox'>
                            <p>Payment Schedule:</p>
                            <div className="dropdown">
                                <p className="dropbtn largeTxt" onClick={toggleRecursionDropdown}>
                                {selectedRecursionLink? selectedRecursionLink : ''}
                                <i className="fa-solid fa-chevron-down"></i>
                                </p>
                                {isRecursionOpen && (
                                <div className="dropdown-content">
                                    <a onClick={() => handleRecursionItemClick('Monthly')}>Monthly: <span className="amount">{costPerMonth}</span>{selectedRecursionLink === 'Monthly' && <i className="fa-solid fa-check"></i>}</a>
                                    <a onClick={() => handleRecursionItemClick('Yearly')}>Yearly: <span className='amount'>{costPerYear}</span><span className='ml-3 discountSpan'>Save 30%</span>{selectedRecursionLink === 'Yearly' && <i className="fa-solid fa-check"></i>}</a>
                                </div>
                                )}
                            </div> 
                        </div>     
                        <div className='totalDiv mt-4 d-flex justify-content-between align-items-center w-100 puchaseBox'>
                            <p>Total: </p>
                            <div>
                            <p className="amount">${calculatedAmount}</p>
                            <span>{selectedRecursionLink === 'Monthly' ? 'PER MONTH' : 'PER YEAR'}</span>
                            </div>

                        </div>       
                        {errorMessage && (<p className="errorMessage" style={{position:'absolute', bottom: '90px', color: 'red', fontWeight: '600'}}>Please enter any number other than 0 to proceed</p>)}
                        <div className="nextBtn" onClick={()=>goToLoginV2()}>Next</div>
                        </div>
                        </>
                    )}

                    
                
            </div>
        </section>
        )}

        {!defaultHome &&(
            <Login onLoginSuccess={handleLoginSuccess} handleCloseClick={handleCloseClick}/>
        )}

        <section className='control'>
            <div className='container'>
                <h2 className=''>Get your team to communicate together <span> privately
                and securely</span>  while you are maintaining total control.</h2>
                <div className='d-flex align-items-center justify-content-between mt-5'>
                    <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_bnr1.png" style={{width:'33%'}} alt="biz banner 1"/>
                    <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_bnr2.png" style={{width:'33%'}} alt="biz banner 2"/>
                    <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_bnr3.png" style={{width:'33%'}} alt="biz banner 3"/>
                </div>
                <hr className="midLineSep"></hr>

                <div className='encryption' >
                    <h3 className='' data-aos='slide-right'>All Texts, Audio Calls and Video Calls<br/>
                        are <span> End-to-End Encrypted</span> </h3>
                    <p className='mt-3' data-aos='slide-right'>No communications data is ever collected or stored on <span>xPal servers.</span></p>
                    <div className="communicateBtn mt-5 mb-5" data-aos='slide-right' onClick={handleScrollToTop}>Start Communicating Now &gt;</div>
                    
                </div>
                <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_bnr4.png" className="img-fluid" alt="biz banner 4"/>

                <div className='manageNetwork d-flex justify-content-between align-items-center'>
                    
                    <div className='col-md-5' data-aos='slide-right'>
                        
                        <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_bnr5.png" style={{width:'9%'}} alt='unparallel security'/>
                        <h3 className='homeHeading textWhite mt-5'>Unparalleled Security</h3>
                        <p className='textGrey mt-3'>Easy to get your entire set up without the need<br/>
                        for anybody’s personal information.</p>
                    </div>
                    <div className='col-md-5' data-aos='slide-left'>
                        <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_usrs.png" style={{width:'12%'}} alt='manage network'/>
                        <h3 className='homeHeading textWhite mt-5'>Manage Your Network, Your Way</h3>
                        <p className='textGrey mt-3'>Text or call any member or a group in your team <br/>with ease and total security and privacy.</p>
                    </div>
                </div>

                <div className='dashboardTop d-flex justify-content-between align-items-center mt-5'>
                    <h3 className='col-md-8'>Easily manage the team by Adding, Deleting, Assigning, Monitoring, or Wiping out data permanently, at any time,
                    with an easy-to-use Dashboard.</h3>
                    <div className="communicateBtn col-md-5" onClick={handleScrollToTop}>Start Communicating Now &gt;</div>
                
                </div>
                <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_dsh.png" className="img-fluid mb-5 dashboardImg" alt='dashboard'/>

                <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_meeting.png" className="img-fluid mt-5" alt='meeting' />
                <div className='featuesDiv'>
               
                    <div className='d-md-flex align-items-center justify-content-between'>
                    
                        <div><h3>Ability to delete chat history with any member 
                        of the team including their chat with you on 
                        their own device<span className='terminate'> (Terminate<sup>TM</sup> Mode).</span></h3>
                        <p className='mt-2 more'>Read More</p>
                        </div>
                        <div className='textRight'>
                            <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_terminate.png" style={{width:'40%'}} alt='terminate'/>
                        </div>
                    </div>
                 
                </div>

                <div className='featuesDiv two'>
                
                    <div className='d-md-flex align-items-center justify-content-between'>
                        <div><h3><span className='wipeOut'>Total Wipeout<sup>TM</sup> </span>will erase all message history
                            from your phone and recipients’, including their conversations with you on their phone.
                            </h3>
                            <p className='mt-2 more'>Read More</p>
                        </div>
                        <div className='textRight'>
                        <img src="https://dz5tyoxl8h44g.cloudfront.net/biz__wipeout.png" style={{width:'40%'}} alt='wipeout'/>
                        </div>
                    </div>
                
                </div>

                <div className='featuesDiv three'>
                
                <div className='d-md-flex align-items-center justify-content-between'>
                        <div><h3><span className='flicker'>Flicker Mode<sup>TM</sup> </span>automatically deletes messages
                            from both sender and receiver devices after
                            a preset length of time after they are seen.</h3>
                            <p className='mt-2 more'>Read More</p></div>
                        <div className='textRight'>
                        <img src="https://dz5tyoxl8h44g.cloudfront.net/biz_flicker.png" style={{width:'40%'}} alt='flicker'/>
                        </div>
                    </div>
               

                </div>

                
                <div className='d-flex justify-content-center align-items-center'>
                <div className="communicateBtn mt-5 col-md-5" onClick={handleScrollToTop}>Start Communicating Now &gt;</div>
                </div>
               

                
                <div className='certification'>
                    <h3 className='textWhite'>Third-Party Security Audited and Certified</h3>
                    <p className='textWhite main mt-2'>Certification by DEKRA and CASA</p>
                    <div className='d-md-flex justify-content-center align-items-center mt-5'>
                        <div className='col-md-4 img'>
                            <a href="https://appdefensealliance.dev/reports/com.mess.engerx_1680520227165973.pdf" target="_blank" rel="noreferrer"><img src="https://dz5tyoxl8h44g.cloudfront.net/Dekra-CertSeal.png" style={{width:'50%'}} alt='dekra seal'/></a>
                        </div>
                        <div className='col-md-8'>
                            <div className='d-flex justify-content start align-items-start'>
                                <img src="https://dz5tyoxl8h44g.cloudfront.net/tick-icn.png" style={{width:'20px',marginRight:'15px'}} alt='tick'/>
                                <div>
                                <p className='textWhite'>App Defense Alliance/CASA Certification with PASS on every area of testing.</p>
                                <a className='more' href="https://xpal.com/files/xPal_Certification.pdf" target="_blank" rel="noreferrer">Read More</a>
                                </div>
                                
                            </div>
                            <div className='d-flex justify-content start align-items-start mt-5'>
                                <img src="https://dz5tyoxl8h44g.cloudfront.net/tick-icn.png" style={{width:'20px',marginRight:'15px'}} alt='tick'/>
                                <div>
                                <p className='textWhite'>DEKRA Testing and Certification, S.A.U Security Evaluation framework</p>
                                <a href="https://appdefensealliance.dev/reports/com.mess.engerx_1680520227165973.pdf" target="_blank" rel="noreferrer" className='more'>Read More</a>
                                </div>
                                
                            </div>
                            <div className='d-flex justify-content start align-items-start mt-5'>
                                <img src="https://dz5tyoxl8h44g.cloudfront.net/tick-icn.png" style={{width:'20px',marginRight:'15px'}} alt='tick'/>
                                <div>
                                <p className='textWhite'>List of certified MASA Certificates and OWASP Security Standards</p>
                                <a href="https://appdefensealliance.dev/directory?hl=en" target="_blank" className='more' rel="noreferrer">Read More</a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>

        </section>

        <Footer/>
        </>
    )
}
export default LandingPage;