import React,{useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route,Routes} from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { apiXpal} from "./services/constants.service";
import LandingPage from './Components/LandingPage';
import Main from './Components/Dashboard/Main';
import Purchase2 from './Components/Purchase2';
import Checkout from './Components/Checkout';
import PurchaseDashboard from './Components/Dashboard/PurchaseDashboard';
import CheckoutDashboard from './Components/Dashboard/CheckoutDashboard';
import Confirmation from './Components/Confirmation';

function App() {
  const [cleanedUsername, setCleanedUsername] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [appInputValue, setAppInputValue] = useState('');
    const handleInputValueChange = (newValue) => {
      setInputValue(newValue);
  };

  const [value, setValue] = useState('');

  const handleValueChange = (newValue) => {
        setValue(newValue);
  };

  const[recursionValue, setRecursionValue]=useState('');

  const handleRecursionChange = (linkText1) => {
  setRecursionValue(linkText1);
  };

  const[sequenceValue, setSequenceValue]=useState('');

  const handleSequenceChange = (linkText) => {
    setSequenceValue(linkText);
};
const [amount, setAmount] = useState('');
const handleAmountChange = (amount) => {
  setAmount(amount);
};

const [costPerUser, setCostPerUser] = useState('');
const handleCostPerUserChange = (costPerUser) => {
  setCostPerUser(costPerUser);
};

const stripePromise = loadStripe(apiXpal.stripeKey);

const [bitPayInvoiceId, setBitPayInvoiceId] = useState('');
const [bitpayUrl, setBitpayUrl] = useState('');

  return (
    <>
    <Router>
    <Routes>
       <Route path='/' element={<LandingPage onInputValueChange={handleInputValueChange} onInputChange={handleValueChange} setRecursionChange={handleRecursionChange} setSequenceChange={handleSequenceChange} onAmountChange={handleAmountChange} onCostPerUserChange={handleCostPerUserChange}/>} />   

       <Route path='/dashboard' element={<Main onInputValueChange={handleInputValueChange}/> } />
       <Route path="/purchase" element={<Purchase2 value={value} recursionValue={recursionValue} sequenceValue={sequenceValue} amount={amount} costPerUser={costPerUser} setBitpayInvoice={setBitPayInvoiceId} setBitpayUrl={setBitpayUrl}/> }/>

      <Route path="/checkout" element={<Elements stripe={stripePromise}>
        <Checkout />
      </Elements>}/>

      <Route path="/dashboard/checkout" element={<Elements stripe={stripePromise}>
        <CheckoutDashboard />
      </Elements>}/>


      <Route path='/confirmation' element={<Confirmation bitPayInvoiceId={bitPayInvoiceId} bitpayUrl={bitpayUrl}/> } />
      
        
    </Routes>
    </Router>
    </>
  );
}

export default App;
