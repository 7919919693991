import axios from "axios";
import { apiXpal } from "./constants.service";
import { getSecureState } from "./securels.service";

const apiUrlXpal = apiXpal.corev2;

const authToken = function (udid: any) {
  
  //let result = axios.post(`${apiUrlXpal}users/authenticate`, { Udid: udid });
  //let result = axios.post(`${apiUrlXpal}Auth/authi`, { Udid: udid });
  let result = axios.post(`${apiUrlXpal}Auth/generate`, { Udid: udid });
  return result;
};

//authi token 
//in login api & authv2 api
//accesss reuired login
const getHeaders = function(){
 
  if(getSecureState("utid")){
    
    let config = {
      headers: {
        "utoken": getSecureState("utid").value,
        "Authorization" : `Bearer ${getSecureState("utid").value}`,
        
        "Content-Type":"application/json"
      },
    };
    return config;
  }

  return "";
};

//unique user device id token
//accesss without login
const getAuthHeaders = function(){
  
  
  if(getSecureState("uaid")){
    let config = {
      headers: {
        "utoken": getSecureState("uxid").value,
        "Authorization" : `Bearer ${getSecureState("uaid").value}`,
        
        "Content-Type":"application/json"
      },
    };
    return config;
  }

  return "";
};

const getAuthHeaders2 = function(){
  //const loginToken = sessionStorage.getItem('loginToken');
  
  if(getSecureState("uaid")){
    let config = {
      headers: {
        "utoken": getSecureState("loginToken").value,
        "Authorization" : `Bearer ${getSecureState("loginToken").value}`,
        "Content-Type":"application/json"
      },
    };
    return config;
  }

  return "";
};

 
const getMultiPartHeaders = function(){
 
  if(getSecureState("utid")){
    let config = {
      headers: {
        "utoken": getSecureState("utid").value,
        "Authorization" : `Bearer ${getSecureState("utid").value}`,
        "Content-Type" :  "multipart/form-data"
      },
    };
    return config;
  }
 
  return "";
};

export {
  authToken,
  getHeaders,
  getMultiPartHeaders,
  getAuthHeaders,
  getAuthHeaders2
};
