import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Group7382 from '../assets/Group7382.png';

import { Button, Modal } from 'react-bootstrap'; 

function Calculator({ onInputChange, goToPurchaseSec, newValue, setNewValue, setNewTotalCost }){

    const navigate= useNavigate();
    const [errorMessage, setErrorMessage] = useState(false);
    const goToPurchase=()=>{
        if (value === '0' || value ==='00' || value ==='000' || value ===''){
          setErrorMessage(true);
        }
        else{
          goToPurchaseSec();
          setErrorMessage(false);
        }
        
    }
    const [value, setValue] = useState("10");
    useEffect(() => {
      // If the newValue prop is not set or empty, set it to '10'
      if (value==='10') {
        //console.log('setting new value');
          setNewValue('10');
      }
  }, [value, setNewValue]);
    const [totalCost, setTotalCost] = useState("99.8");
    const [isAnnually, setIsAnnually] = useState(false);
    const [costPerUser, setCostPerUser] =useState('');
    const handleChange = (e:any) => {
      setErrorMessage(false);
        let newValue = e.target.value;
        newValue =e.target.value.replace(/[^0-9]/g, '');
        
          setValue(newValue);
          setNewValue(newValue);
            // Pass the new value to the parent component
            onInputChange(newValue);
            //console.log('response from calculator',newValue);
          // Automatically calculate the total cost when the value changes
          calculateTotalCost(newValue, isAnnually);
      }

      const toggleBillingCycle = (annually:any) => {
        setIsAnnually(annually);
        calculateTotalCost(value, annually);
      }
      useEffect(() => {
        // Store the billing cycle in sessionStorage when isAnnually changes
        sessionStorage.setItem('billingCycle', isAnnually ? 'Yearly' : 'Monthly');
        //sessionStorage.setItem('costPerUser', isAnnually ? '59.88' : '4.99');
    }, [isAnnually]);


      const calculateTotalCost = (inputValue:any, isAnnually:any) => {
        const userInput = parseInt(inputValue, 10);
        if (!isNaN(userInput)) {
          if(userInput<=10){
            const costPerUser = isAnnually ? 29 : 3.9;
            localStorage.setItem('costPerUser',costPerUser.toString());
            const cost = userInput * costPerUser;
            setTotalCost(cost.toFixed(2));
            const totalCost= cost.toFixed(2);
          }
          else{
            const costPerUser = isAnnually ? 19 : 2.9;
            localStorage.setItem('costPerUser',costPerUser.toString());
            const cost = userInput * costPerUser;
            setTotalCost(cost.toFixed(2));
            const totalCost= cost.toFixed(2);
          }

        }
      }

      useEffect(() => {
        const userInput = parseInt(value, 10);
        if (!isNaN(userInput)) {

        if(userInput<=10){
          const costPerUser = isAnnually ? 29 : 3.9;
          const cost = userInput * costPerUser;
          setTotalCost(cost.toFixed(2));
          setNewTotalCost(cost.toFixed(2));
          if(isAnnually){
            setCostPerUser('29');
          }
          else{
            setCostPerUser('3.9');
          }
        }
        else{
          const costPerUser = isAnnually ? 19 : 2.9;
          const cost = userInput * costPerUser;
          setTotalCost(cost.toFixed(2));
          setNewTotalCost(cost.toFixed(2));
          if(isAnnually){
            setCostPerUser('19');
          }
          else{
            setCostPerUser('2.9');
          }
        }
      }
        
    }, [value, isAnnually]);

      

      const [activeDiv, setActiveDiv] = useState(1);
        // useEffect to set format in session storage based on activeDiv change
      useEffect(() => {
        const format = activeDiv === 1 ? 'Random' : 'Sequential';
        sessionStorage.setItem('xIDFormat', format);
      }, [activeDiv]);
    

      const handleDivClick = (divIndex) => {
          setActiveDiv(divIndex);
      };

      const [showRandomModal, setShowRandomModal] = useState(false);
      const handleCloseRandomModal = () => setShowRandomModal(false);
      const handleShowRandomModal = () => setShowRandomModal(true);

      const [showSequentialModal, setShowSequentialModal] = useState(false);
      const handleCloseSequentialModal = () => setShowSequentialModal(false);
      const handleShowSequentialModal = () => setShowSequentialModal(true);

    return(
        <>

        <div className='container d-flex justify-content-center'>
            <div className='xIDs col-md-5 txtCenter mr-1 d-flex justify-content-center flex-column'>

                <h3 className='txtWhite mt-1 select' style={{textAlign:'left'}}>How many xIDs do you want?</h3>
                <div className='mb-2'>
                <input className="mb-4 mt-4" type="text" value={value} maxLength={3} onChange={handleChange}  /> 
                <p className='formatTxt large mb-2'>Select xID Format</p>
                <p className='formatTxt'>Users choose between random and sequential ID formats for unique or ordered identification.</p>
                </div>
                <div className='type'>
                            <div className={`format random mb-3 d-flex align-items-center ${activeDiv === 1 ? 'active' : ''}`} onClick={() => handleDivClick(1)}>
                              <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='circle mr-3 ml-2'>
                                  {activeDiv === 1 ? (
                                    <>
                                    {/* <img src={Group7382} style={{width:'18px'}}/>  */}
                                                                                                                
                                   <div className='activeCircleBorder'>
                                   <div className='activeCircle'></div></div></>
                                  ) : (
                                      <i className="far fa-circle"></i>
                                  )}
                                  </div>
                                  <div>
                                  <h3 className='textWhite'>Random</h3>
                                      <p className='textWhite'>These xIDs will be totally random</p>
                                  </div>
                                </div>
                                <div className='mr-2' onClick={handleShowRandomModal}>
                                <i className="fa fa-question" aria-hidden="true" style={{color:'white'}}>

                                </i>

                                </div>
                              </div>
                            </div>


                        <div className={`format sequential mt-3 d-flex align-items-center ${activeDiv === 2 ? 'active' : ''}`} onClick={() => handleDivClick(2)}>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='circle mr-3 ml-2'>
                                  {activeDiv === 2 ? (
                                    <>
{/*                                                                           <img src={Group7382} style={{width:'18px'}}/>  */}
                                      
                                    <div className='activeCircleBorder'>
                                   <div className='activeCircle'></div></div></>

                                      
                                  ) : (
                                      <i className="far fa-circle"></i>
                                  )}
                                  </div>
                                  <div>
                                  <h3 className='textWhite'>Sequential</h3>
                                      <p className='textWhite col-md-8 seqTxt'>These xIDs will be sequential, last 3 digits will be in sequence</p>
                                  </div>
                                </div>
                                <div className='mr-2' onClick={handleShowSequentialModal}>
                                <i className="fa fa-question" aria-hidden="true" style={{color:'white'}}>

                                </i>

                                </div>
                              </div>
                        </div>
                    </div>
{/*                 <div>
                <button className="mb-3" onClick={() => calculateTotalCost(value, isAnnually)}>Calculate</button>   
                </div> */}
     
                </div>
            <div className='cost col-md-5 txtCenter ml-1 d-flex justify-content-center flex-column'>
                <div>
                    <h3 className='txtWhite purchaseCost'>Purchase Cost</h3>
                </div>
            <div className='container mt-4 mb-4 switchContainer' style={{position: 'relative'}}>
                <p className='discount' style={{position: 'absolute', right: '31%', bottom: '55%', color: 'white', fontSize: '12px', backgroundColor: 'green',
    borderRadius: '12px', padding: '1px 10px'}}>Save 30%</p>
                <label
                className={`monthly ${isAnnually ? '' : 'active'}`}
                onClick={() => toggleBillingCycle(false)}
                >
                Monthly
                </label>
                <label
                className={isAnnually ? 'active' : ''}
                onClick={() => toggleBillingCycle(true)}
                >
                Annually
                </label>
                
                </div>
                <div className='d-flex justify-content-between align-items-center singleDiv'>
                <div className='text'>Total Number of xIDs:</div>
                <div className='values'>{value || '0'}</div>
                </div>
                <div  className='d-flex justify-content-between align-items-center singleDiv mt-4'>
                <div className='text'>Cost Per User:</div>
                    <div className='values'>${costPerUser}</div>
                </div>
                <div  className='d-flex justify-content-between align-items-center singleDiv mt-4'>
                <div className='text'>Format:</div>
                    <div className='values'>{activeDiv === 1 ? 'Random':'Sequential' }</div>
                </div>
                <div  className='d-flex justify-content-between mt-4 totalDiv'>
                <div className='totalTxt'>Total</div>
                <div className='values'><p className='complAmount'>$<span>{totalCost}</span><span className="per">/</span><br/><span className='type'>{isAnnually ? 'PER YEAR' : 'PER MONTH'}</span></p></div>
                </div>
                <div className='d-flex justify-content-center align-items-center' style={{position: 'relative'}}>
                { errorMessage && (<div className="errorMessage" style={{position:'absolute', color: 'red', top: '100%'}}>Please enter any number other than 0 to proceed</div>
                )}
                <div className='getStartedBtn mt-5 mb-3' onClick={()=>goToPurchase()}>
                    Proceed
                 </div>   
                </div>

            </div>
        </div>

        <Modal show={showRandomModal} onHide={handleCloseRandomModal} className='formatModal'>
        <Modal.Header >
        <Button variant="secondary" onClick={handleCloseRandomModal}>
        <i className="fa fa-times mr-1" aria-hidden="true"></i> Close
          </Button>
        </Modal.Header>
        <Modal.Body>
                <div className='container d-flex justify-content-center align-items-center flex-column'>
                  <h3>Random xIDs Examples</h3>
                  <p className='formatDesc col-md-10'>Random IDs have no specific order, providing unique
                    and unpredictable identification numbers without any
                    discernible pattern.</p>
                </div>
                <div className='container d-flex align-items-center justify-content-between exampleContainer mt-4'>
                  <div className='text-white'>
                    <p>127 776 779</p>
                    <p>713 823 999</p>
                    <p>198 987 556</p>
                    <p>456 666 667</p>
                    <p>455 987 999</p>
                    
                  </div>
                  <div className='text-white'>
                    <p>667 777 777</p>
                    <p>091 762 887</p>
                    <p>813 333 383</p>
                    <p>100 099 912</p>
                    <p>257 777 888</p>
                  </div>
                </div>
        </Modal.Body>

      </Modal>

      <Modal show={showSequentialModal} onHide={handleCloseSequentialModal} className='formatModal'>
        <Modal.Header >
        <Button variant="secondary" onClick={handleCloseSequentialModal}>
        <i className="fa fa-times mr-2" aria-hidden="true"></i>Close
          </Button>
        </Modal.Header>
        <Modal.Body>
                <div className='container d-flex justify-content-center align-items-center flex-column'>
                  <h3>Sequential xIDs Examples</h3>
                  <p className='formatDesc col-md-10'>Sequential IDs follow a clear order, making it easy to organize
                    and track identification in a systematic manner.</p>
                </div>
                <div className='container d-flex align-items-center justify-content-between exampleContainer mt-4'>
                  <div className='text-white'>
                    <p>127 776 770</p>
                    <p>127 776 771</p>
                    <p>127 776 772</p>
                    <p>127 776 773</p>
                    <p>127 776 774</p>
                    
                  </div>
                  <div className='text-white'>
                  <p>127 776 775</p>
                  <p>127 776 776</p>
                  <p>127 776 777</p>
                  <p>127 776 778</p>
                  <p>127 776 779</p>
                  </div>
                </div>
        </Modal.Body>

      </Modal>
    </>
    )


}
export default Calculator