import React, { useState, useRef, useEffect } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {useStripe} from "@stripe/react-stripe-js";
import '../components.css';

import '../landingpage.css';
import '../purchaseV2.css';
import '../checkout.css';
import biz_logo from '../../assets/xbiz-logo.png';
function Checkout() {
    const xIDFormat= sessionStorage.getItem('xIDFormat');
    const billlingCycle = sessionStorage.getItem('billingCycle');

    const contentRef = useRef(); // Reference for the content to be included in PDF
    //const [isGenerating, setIsGenerating] = useState(false);

    const stripe = useStripe();

    const [loading, setLoading]= useState(true);
    const [successUI, setSuccessUI] =useState(false);
    const [unsuccessfulUI, SetUnsuccessfulUI] =useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const checkPaymentStatus = async () => {
          const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
            
          );
    
          if (!clientSecret) {
            console.error("Payment intent client secret missing.");
            return;
          }
    
          try {
            const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    setSuccessUI(true);
                    setLoading(false);
                    break;
                  case "processing":
                    setMessage("Your payment is processing.");
                    break;
                  case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    setLoading(false);
                    break;
                  default:
                    setMessage("Something went wrong.");
                    setLoading(false);
                    break;
            }
          } catch (error) {
            console.error("Error retrieving payment status:", error);
            setLoading(false);
            
          }
          
        };
    
        if (stripe) {
            checkPaymentStatus();
        }
        
        
      }, [stripe]);


    const handleDownload = async () => {
        // Increase scale to improve the resolution
        const scale = 5;
        const canvas = await html2canvas(contentRef.current, {
            useCORS: true,
            scale: scale, // Increase scale for higher resolution
            width: contentRef.current.scrollWidth,
            height: contentRef.current.scrollHeight,
        });
    
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
    
        // Calculate image dimensions
        const imgWidth = 190; // Width of the PDF page in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
    
        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
        pdf.save('invoice.pdf');
    };

    const total_Amount = localStorage.getItem('totalAmount');
    const xID = sessionStorage.getItem('xID');

    const addSpacesToXID = (xid) => {
        return xid.replace(/\d{3}(?=\d)/g, '$& ');
    };
    const currentDate = new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });

    return (
        <>
            
            <div className='status-wrapper'>
                {/* Visible content */}
                <div className="xpal-invoice h-100 d-flex align-items-center justify-content-center">

                {loading && ( <span class="stripeBtnLoader checkoutPageLoader" id="stripeBtnLoader"></span> )}

                {successUI && ( 
                    <div className="container col-md-5">
                        <div className="d-flex justify-content-between align-items-center success-wrapper">
                            <div className="success-div">
                                <h3>Payment Successful!</h3>
                                <p>Your payment has been successfully done. </p>
                            </div>
                            <div>
                                <h3 className="invoice-heading">INVOICE</h3>
                            </div>
                        </div>

                        <div className="html-content">
                            <div className="container">
                            
                                <div className="invoice_details">
                                    <table className="table table-striped top-table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p className="top-text">DATE</p>
                                                    <p className="bottom-text currentdate">{currentDate}</p>
                                                </td>
                                                <td className="payment-schedule" rowSpan="3">
                                                    <p className="top-text">PLAN:</p>
                                                    <p className="bottom-text">{billlingCycle}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="top-text">Sequence:</p>
                                                    <p className="bottom-text">{xIDFormat}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="top-text">Master XID</p>
                                                    <p className="bottom-text">{addSpacesToXID(xID)}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="">
                                    <div className="d-flex justify-content-between align-items-center total-amount-div">
                                        <div>
                                            <p><span>PAYMENT METHOD:</span> CREDIT CARD</p>
                                        </div>
                                        <div>
                                            <p><span>TOTAL:</span> {total_Amount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center buttons-div mt-5 mb-5">
                            <div className="buttons-div-text">Thank You!</div>
                            <div className="d-flex justify-content-end align-items-center">
                                <a className="downloadBtn" id="gpdf" onClick={handleDownload}>Download Invoice</a>
                                <a className="continueBtn" href="/dashboard/">Dashboard</a>
                            </div>
                        </div>
                    </div>
                )}    

                    {unsuccessfulUI && ( <div className="container col-md-5">
                        <div className="d-flex justify-content-center align-items-center success-wrapper">
                        <div className="success-div">
                                
                                <h3>Payment <span>UnSuccessful!</span></h3>
                                <p className='mb-4' style={{textAlign:'center'}}>Your payment was not successful, please try again.</p>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='d-flex justify-content-center align-items-center backBtn'>Back</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>

            {/* Hidden content for PDF generation */}
            <div className="hidden-content xpal-invoice">
                <div className="container" ref={contentRef}>
                <div class="d-flex justify-content-between align-items-center info-container mt-5 mb-5">
                    <div>
                            <img src={biz_logo} />
                            <p>6475 CAMDEN AVE SUITE 200, SAN JOSE, CA 95120, USA</p>
                            <p>xID: {addSpacesToXID(xID)}</p>
                    </div>
                    <div>
                            <h2>INVOICE</h2>
                    </div>
                    </div>
                    <div className="invoice_details">
                        <table className="table table-striped top-table">
                            <tbody>
                                <tr>
                                    <td>
                                        <p className="top-text">DATE</p>
                                        <p className="bottom-text currentdate">{currentDate}</p>
                                    </td>
                                    <td className="payment-schedule" rowSpan="3">
                                        <p className="top-text">PLAN:</p>
                                        <p className="bottom-text">{billlingCycle}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="top-text">Sequence:</p>
                                        <p className="bottom-text">{xIDFormat}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="top-text">Master XID</p>
                                        <p className="bottom-text">{addSpacesToXID(xID)}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="">
                        <div className="d-flex justify-content-between align-items-center total-amount-div">
                            <div>
                                <p><span>PAYMENT METHOD:</span> CREDIT CARD</p>
                            </div>
                            <div>
                                <p><span>TOTAL:</span> {total_Amount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Checkout;