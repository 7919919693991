import SecureLS from "secure-ls";

var ls = new SecureLS({ encodingType: "des", isCompression: false }); //encodingType: "des"

const setSecureState =(key: any, value: any) => {
  try {
     ls.set(key, { value }); // set key1
  }
  catch (err) {
    console.log("Setting Secure Ls Error", err)
  }
};

const getSecureState = (key) => {
  try {
    const state = ls.get(key);
    return state
  }
  catch (err: any) {
    console.log("Getting Secure Ls Error", err)
  }
};

const removeSecureState = (key) => {
  try {
    ls.remove(key)
  }
  catch (err) {
    console.log("removing Secure Ls Error", err)
  }
}

const clearLocalStates = () => {
  try {
    ls.removeAll()
  }
  catch (err) {
    console.log("Clear Secure Ls Error", err)
  }
}

export {
  setSecureState,
  getSecureState,
  removeSecureState,
  clearLocalStates
}